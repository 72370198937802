import React, { SVGProps } from "react";

export const CarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#4e433c"
      d="M26.37 112.17c0 .87-.23 6.82-.29 8.22s-1.11 3.5-2.39 3.56c-1.28.06-7.05-.12-7.87-.17s-3.35-1.06-3.67-4.9c-.34-4.07-.5-8.61-.47-10.32.03-1.75-.12-4.2-.12-4.2l14.81 7.81zM102.99 112.57s.06 6.59.06 7.81c0 1.22 1.05 3.44 4.31 3.56s4.84.05 6.12 0c1.63-.06 2.97-1.98 3.26-5.71.3-3.9.35-7.99.41-9.5.06-1.52.18-3.93.18-3.93s-13.58 3.4-13.64 3.69c-.06.29-.7 4.08-.7 4.08z"
    />
    <path
      fill="#d70617"
      d="m18.76 55.47-4.42-.08-13.18.58c-.05 1.63.43 3.36 3.31 4.27s12.85.62 12.85.62l-1.35.84s-3.34 8.74-4.49 13.19c-1.67 6.46-.71 22.51-.71 22.51l1.37 9.21s2.1 5.54 7 6.53 28.16 1.65 44.22 2.15 44.51-1.72 47.57-2.49c3.05-.76 5.8-4.73 6.56-7.17.76-2.44 1.07-10.68 1.07-10.68s.82-11.73-.29-18.6c-.65-4.03-4.05-12.41-4.69-13.78-.64-1.37-1.57-3.87-1.57-3.87s10.85.14 12.61-.92c2.03-1.23 2.18-2.42 2.21-3.65.02-1.02-.36-1.37-.64-1.53-.68.14-13.65.95-13.65.95h-3.81l-89.97 1.92z"
    />
    <path
      fill="#ff2a23"
      d="m17.99 99.1-7.71-2.64s.28 9.3 2.62 11.22 5.69 3.34 10.28 3.76 27.26.75 40.88.75 42.89-1.25 47.15-2.51c4.26-1.25 5.47-2.61 6.27-4.26 1.17-2.42 1.52-9.61 1.52-9.61s-4.19 3.67-5.36 4.17-53.09 2.34-53.34 2.34c-.25.01-42.31-3.22-42.31-3.22zM15.48 62.95c.27 1.7.77 4.59 5.6 5.77 4.83 1.18 19.56 7.07 43.71 6.6 24.15-.47 39.83-8.01 41.59-8.72 1.77-.71 5.86-2.83 6.01-5.42.1-1.75-1.3-5.07-1.3-5.07s-3.53-12.61-5.42-17.91-4.36-11.9-6.01-14.73-4.36-3.53-7.66-3.89c-3.3-.35-13.9-.7-27.92-.59-15.55.12-25.8.59-28.4.71-2.59.12-4.95 1.65-6.48 4.48-1.53 2.83-4.06 8.5-7.07 17.67-2.71 8.25-4.71 16.26-4.71 16.26s-1.94 4.81-1.94 4.84zM112.81 53.66c.29.33 2.2 1.82 6.64 1.76 5.02-.07 7.19-2.38 7.19-2.38s-.85-2.59-4.48-4.27c-3.94-1.83-7.51-1.65-8.98-.75-1.19.73-.37 5.64-.37 5.64zM15.55 55.72s1.36-4.29-1.25-5.61-9.19-.24-11.8 2.09c-1.41 1.25-1.35 3.78-1.35 3.78s2.7 1.47 7.76 1.43 6.64-1.69 6.64-1.69z"
    />
    <path
      fill="#474c4f"
      d="M46.87 93.79c1.59.9 33.27.48 34.31.07 1.04-.42 5.05-8.99 5.67-10.86.33-.98.21-2.97-2.28-3.87-2.49-.9-11.34-1.73-20.68-1.8s-18.4.69-21.17 1.73c-1.62.61-1.86 1.98-1.38 3.32 1.83 5.03 3.94 10.51 5.53 11.41z"
    />
    <path
      fill="#ff6b5d"
      d="M10.65 95.03c-.82.2-.49 7.06 3.98 8.45 5.47 1.7 38.72 1.72 49.22 1.6 10.5-.12 45.57.23 48.8-.87 4.95-1.69 7.46-8.98 5.9-9.26-1.97-.35-3.28 2.91-12.78 3.02-10.38.11-31.87.81-41.89.69-10.02-.12-41.89 0-46.84-1.45s-5.56-2.38-6.39-2.18z"
    />
    <path
      fill="#feeccb"
      d="M91.4 75.96c.09 1.04 1.15 2.27 2.77 2.15 1.63-.13 11.76-.62 13.45-1.12 1.17-.35 3.72-2.22 3.89-4.63.18-2.53-.03-3.81-.07-4.7-.06-1.23-.5-2.2-3.41-2.12-2.91.09-11.69.4-13.38.48-1.88.09-3.22 1.04-3.26 3.37-.04 1.92-.11 5.19.01 6.57z"
    />
    <path
      fill="#fffeff"
      d="M95.42 72.03c.02 3.54 2.31 5.96 6.74 5.6 5.49-.45 6.32-3.39 6.12-6.57-.21-3.33-2.75-5.41-6.18-5.3-3.53.12-6.71 1.87-6.68 6.27z"
    />
    <path
      fill="#feeccb"
      d="M36.75 77.67c.81-.81.5-5.48.37-8.22-.12-2.74-2.74-3.11-4.73-3.11s-12.95-.12-14.76.06c-1.18.12-.7 5.84-.37 7.16.31 1.25 2.18 4.23 5.54 4.42s12.98.65 13.95-.31z"
    />
    <path
      fill="#fffeff"
      d="M21.06 72.15c0 3.13 1.88 6.15 6.55 6.06 5.01-.1 6.16-3.85 6.21-6.06.05-2.21-1.32-5.97-6.6-5.87-5.16.1-6.16 3.95-6.16 5.87z"
    />
    <path
      fill="#547078"
      d="M37.39 23.3c-1.76.22-4.1.75-5.5 3.54-1.4 2.8-3.75 8.7-5.13 12.59-2.05 5.78-4.57 14.08-4.94 15.76-.37 1.68 0 3.17 2.8 3.36s20.51 1.2 39.07 1.03c19.86-.19 39.35-1.31 41.12-1.49 1.77-.19 2.61-1.96 2.24-4.01-.37-2.05-3.73-12.12-5.31-16.6-1.59-4.48-3.75-9.37-4.56-10.81-.7-1.24-1.78-2.43-5.23-2.99-2.97-.48-18.65-1.21-28.16-1.12-9.52.09-23.42.37-26.4.74z"
    />
    <path
      fill="#afe3fb"
      d="m35.9 51.93-8.67-2.24s2-6.88 3.82-11.66c1.21-3.17 2.16-5.92 2.98-7.74.5-1.1 1.49-2.61 4.29-2.8 2.8-.19 8.49-.56 26.57-.65 18.09-.09 24.07.59 25.64.84 1.77.28 3.82.37 5.22 3.73s3.17 9.14 4.48 13.05c.73 2.18 1.77 6.15 1.77 6.15l-66.1 1.32z"
    />
    <path
      fill="#96c8eb"
      d="M38.51 48.11s.37-5.22.37-7.65c0-2.24 2.05-4.2 3.82-4.38 1.77-.19 7.27-.09 8.58 0 1.31.09 3.36 1.49 3.45 3.82s.28 7.37.28 7.37h19.86s0-5.59.09-7.65 1.4-3.08 3.45-3.17c2.05-.09 7.46-.19 9.04-.09 1.59.09 3.08 1.4 3.26 3.73.19 2.33.19 8.39.19 8.39s2.67.08 5.19.38c2.65.32 5.65.78 5.65.78s1.56 3.96-1.05 4.15c-2.33.17-23.59.37-37.67.56-14.08.19-33.66-.06-34.69-.09-1.4-.05-1.77-1.4-1.68-2.33.09-.93.56-2.24.56-2.24s3.36-.65 5.59-.93c2.26-.28 5.71-.65 5.71-.65z"
    />
  </svg>
);
