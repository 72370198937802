import React, { SVGProps } from "react";

export const GameBoyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      d="M153.32 512h-35.848c-13.576 0-24.584-11.008-24.584-24.584V24.584C92.888 11.007 103.896 0 117.472 0h277.057c13.576 0 24.584 11.007 24.584 24.584v462.832c0 13.576-11.008 24.584-24.584 24.584H177.803"
      style={{
        fill: "#547475",
      }}
    />
    <path
      d="M109.737 487.416V24.584C109.737 11.007 120.744 0 134.321 0h-16.849c-13.576 0-24.584 11.007-24.584 24.584v462.832c0 13.576 11.008 24.584 24.584 24.584h16.849c-13.577 0-24.584-11.008-24.584-24.584z"
      style={{
        opacity: 0.15,
        fill: "#231f20",
      }}
    />
    <path
      d="M381.312 275.021c0 7.376-5.98 13.357-13.357 13.357h-223.91c-7.376 0-13.357-5.98-13.357-13.357V51.311c0-7.376 5.98-13.357 13.357-13.357h223.911c7.376 0 13.357 5.98 13.357 13.357v223.71z"
      style={{
        fill: "#53caf9",
      }}
    />
    <circle
      cx={360.481}
      cy={361.561}
      r={20.822}
      style={{
        fill: "#efba00",
      }}
    />
    <circle
      cx={320.957}
      cy={422.244}
      r={20.822}
      style={{
        fill: "#efba00",
      }}
    />
    <path
      d="M206.182 378.753h-18.92v-18.919a7.79 7.79 0 0 0-7.791-7.791H165.19a7.79 7.79 0 0 0-7.791 7.791v18.919h-18.92a7.79 7.79 0 0 0-7.791 7.791v14.281a7.79 7.79 0 0 0 7.791 7.791h18.92v18.919a7.79 7.79 0 0 0 7.791 7.791h14.281a7.79 7.79 0 0 0 7.791-7.791v-18.919h18.92a7.79 7.79 0 0 0 7.791-7.791v-14.281a7.79 7.79 0 0 0-7.791-7.791z"
      style={{
        fill: "#f74848",
      }}
    />
    <path
      d="M210.463 488.909c-4.61 0-8.348-3.725-8.348-8.336v-.025a8.349 8.349 0 0 1 16.696 0c0 4.612-3.739 8.361-8.348 8.361zM301.537 488.909c-4.61 0-8.348-3.725-8.348-8.336v-.025a8.349 8.349 0 0 1 16.696 0c0 4.612-3.738 8.361-8.348 8.361zM271.179 488.909c-4.61 0-8.348-3.725-8.348-8.336v-.025a8.349 8.349 0 0 1 16.696 0c-.001 4.612-3.739 8.361-8.348 8.361zM240.819 488.909c-4.61 0-8.348-3.725-8.348-8.336v-.025a8.349 8.349 0 0 1 16.696 0c0 4.612-3.738 8.361-8.348 8.361zM302.376 325.195h-92.752a8.349 8.349 0 0 1 0-16.696h92.752a8.349 8.349 0 0 1 0 16.696z"
      style={{
        fill: "#8aacad",
      }}
    />
    <path
      d="M219.085 316.847a8.348 8.348 0 0 1 8.348-8.348h-17.809a8.349 8.349 0 0 0 0 16.696h17.809a8.348 8.348 0 0 1-8.348-8.348z"
      style={{
        opacity: 0.1,
        fill: "#231f20",
      }}
    />
    <path
      d="M260.093 128.394c0 .542-.44.982-.982.982H230.33a.983.983 0 0 1-.983-.982V99.613c0-.542.44-.983.983-.983h28.781c.542 0 .982.441.982.983v28.781zM351.186 287.4c0 .542-.44.982-.983.982h-28.781a.982.982 0 0 1-.982-.982v-28.781c0-.542.44-.983.982-.983h28.781c.543 0 .983.441.983.983V287.4zM290.838 194.613c0 .728-.44 1.318-.981 1.318h-28.782c-.543 0-.983-.589-.983-1.318v-38.615c0-.728.44-1.319.983-1.319h28.782c.541 0 .981.59.981 1.319v38.615z"
      style={{
        fill: "#e5e541",
      }}
    />
    <path
      d="M381.312 257.86c0 .569-.44 1.028-.982 1.028h-28.781c-.543 0-.983-.46-.983-1.028v-30.152c0-.568.44-1.03.983-1.03h28.781c.542 0 .982.462.982 1.03v30.152zM130.688 275.021c0 7.376 5.98 13.357 13.357 13.357h17.389v-30.954h-30.746v17.597z"
      style={{
        fill: "#e5e541",
      }}
    />
    <path
      d="M161.436 257.425h34.04v30.953h-34.04z"
      style={{
        fill: "#ebf25f",
      }}
    />
    <path
      d="M161.436 224.801h30.746v32.623h-30.746z"
      style={{
        fill: "#e5e541",
      }}
    />
    <path
      d="M290.838 159.14c0 .542-.44.982-.981.982h-28.782a.983.983 0 0 1-.983-.982v-28.781c0-.542.44-.983.983-.983h28.782c.541 0 .981.441.981.983v28.781zM290.838 220.631c0 .542-.44.982-.981.982h-28.782a.983.983 0 0 1-.983-.982V191.85c0-.542.44-.983.983-.983h28.782c.541 0 .981.44.981.983v28.781zM350.566 288.377h17.389c7.376 0 13.357-5.98 13.357-13.357v-17.596h-30.746v30.953zM192.179 225.695c0 .542-.44.982-.982.982h-28.781a.983.983 0 0 1-.983-.982v-28.781c0-.542.439-.983.983-.983h28.781c.542 0 .982.44.982.983v28.781z"
      style={{
        fill: "#ebf25f",
      }}
    />
    <path
      d="M222.925 287.189c0 .541-.44.981-.982.981h-28.781a.982.982 0 0 1-.983-.981v-28.781c0-.542.44-.983.983-.983h28.781c.542 0 .982.44.982.983v28.781z"
      style={{
        fill: "#e5e541",
      }}
    />
    <path
      d="M148.497 275.021V51.311c0-7.376 5.98-13.357 13.357-13.357h-17.809c-7.376 0-13.357 5.98-13.357 13.357V275.02c0 7.376 5.98 13.357 13.357 13.357h17.809c-7.377 0-13.357-5.98-13.357-13.356z"
      style={{
        opacity: 0.15,
        fill: "#231f20",
      }}
    />
  </svg>
);
