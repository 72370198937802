import React, { SVGProps } from "react";
export const BalloonIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="none"
      stroke="#64b5f6"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M75.87 102.1c3 4.9-2.38 10.12 1.85 13.91 7.84 7 17.44-12.05 29.49 7.49"
    />
    <path
      fill="#f44336"
      d="m74.5 106.88 5.63-1a2.86 2.86 0 0 0 2.07-4.1 34.64 34.64 0 0 0-7.2-9.62c-.44 2.89-2.16 7.38-3.62 10.8a2.85 2.85 0 0 0 3.12 3.92z"
    />
    <path
      fill="#f44336"
      d="M25.56 51.54C33.14 83.3 64.22 97.75 75.68 95s32.1-32 25.08-61.43C95.79 12.8 74.93 0 54.16 4.97s-33.57 25.83-28.6 46.6v-.03z"
    />
    <path
      fill="#c62828"
      d="m73.43 98.11 5.16-1.23c1.63-.39 2.64-2.02 2.25-3.65s-2.02-2.64-3.65-2.25L72 92.21c-1.63.39-2.64 2.02-2.25 3.65s2.02 2.64 3.65 2.25h.03z"
    />
    <path
      fill="#ff847a"
      d="M78.28 13.44c-4.07-2.48-9.9-4.13-13.2.55-1.76 2.49-.1 7.15 3.53 8.2 6.14 1.79 7.21 4 8.46 5.79 1.51 2.1 2.94 4.73 5.49 5.14s4-1.51 3.89-5.21c-.02-5.92-3.11-11.4-8.17-14.47z"
    />
  </svg>
);
