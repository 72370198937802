import React, { SVGProps } from "react";

export const BowlingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#d8cec9"
      d="M99.05 3.71c-9.68 0-10.04 10.11-9.54 14.57.58 5.14 2.57 12.35 2.57 19.56s-1.03 11.67-2.75 15.44c-1.72 3.77-6.01 10.15-6.6 22.84-.72 15.41 6.63 36.21 8.78 38 1.77 1.48 14.39 1.59 15.77.36 1.54-1.37 9.67-17.33 9.5-36.21-.17-18.87-11.29-24.91-10.93-40.87.2-8.78 1.91-13.42 2.69-20.43.73-6.45-1.16-13.26-9.49-13.26zM44.49 4.18c-10.32.06-10.04 10.11-9.54 14.57.58 5.14 2.57 12.35 2.57 19.56s-1.03 11.67-2.75 15.44c-1.72 3.77-6.01 10.15-6.6 22.84-.72 15.41 6.63 36.21 8.78 38 1.77 1.48 14.39 1.59 15.77.36 1.54-1.37 7.77-17.09 7.6-35.96-.17-18.87-9.24-25.29-8.42-41.23.36-6.99 2.7-11.86 2.76-20.22.05-6.48-1.84-13.41-10.17-13.36z"
    />
    <path
      fill="#f1eded"
      d="M99.22 7.08c-4.57 0-7.07 4.99-6.37 11.5.69 6.51 2.31 14.13 2.58 22.45.29 8.67-3.55 13.31-5.77 18.57s-3.46 9.98-3.46 9.98l2.36 30.35s1.66 4.85 3.74 4.99c2.08.14 12.19-2.08 18.71-12.75s.97-26.33-2.36-33.12c-2.82-5.76-5.7-9.93-6.1-16.77-.66-11.44 2.22-18.15 2.49-24.39.23-5.13-.42-10.81-5.82-10.81z"
    />
    <path
      fill="#efeeec"
      d="M44.35 8.32c-4.16 0-7.07 3.19-5.96 12.06s2.33 11.97 2.47 19.87-5.1 19.35-5.1 19.35l10.67 8.45 6.1-10.12S49.2 51 48.93 41.58c-.2-6.94 1.94-17.32 2.08-22.17.13-4.85-.57-11.09-6.66-11.09z"
    />
    <path
      fill="#d8cec9"
      d="M71.8 4.96c-9.5 0-9.67 10.37-9.5 15.06.18 4.84 2.69 12.91 2.69 20.43s-1.08 12.19-2.87 16.13c-1.79 3.94-6.63 10.58-7.17 23.84s8.6 38.32 10.04 39.61c1.79 1.61 14.51 1.1 15.95-.18 1.61-1.43 9.86-18.46 9.68-38.18-.18-19.72-12.33-25.64-11.83-41.41.36-11.29 2.37-13.79 2.69-21.15.36-8.23-2.86-14.15-9.68-14.15z"
    />
    <path
      fill="#f1edec"
      d="M72.06 8.46c-4.28-.31-7.34 3.88-5.82 13.86s2.63 16.35 2.49 20.79c-.14 4.43-1.66 9.28-2.63 11.92-.97 2.63-4.43 11.22-4.43 11.22s4.71-1.25 7.9 1.25c3.19 2.49 9.01 8.87 10.67 15.38 1.66 6.51 2.08 16.49 2.08 16.49s5.13-6.79 4.16-19.54-6.79-21.34-8.59-25.63c-1.37-3.26-2.22-6.93-2.22-10.67 0-8.87 2.63-17.18 2.63-22.72s-.56-11.93-6.24-12.35z"
    />
    <path
      fill="#fff"
      d="M97.77 8.29c-1.13-.64-3.02.31-3.97 2.52-.94 2.2-.88 5.29.88 5.79s3.34-1.7 3.84-4.03.57-3.53-.75-4.28zM70.69 8.79c-1.08-.3-3.02.57-3.84 3.02s-.5 4.79.69 5.35c1.78.84 3.65-1.26 4.28-3.65.63-2.39.7-4.22-1.13-4.72zM44.17 9.36c1.2 1.14.63 3.59-.76 5.1-1.39 1.51-3.28 2.08-4.47 1.26-1.2-.82-.81-3.47.63-5.23 1.71-2.08 3.34-2.33 4.6-1.13zM41.97 50.92c-.77-.43-2.14-.38-3.15 1.83-1.01 2.2-1.95 4.35-1.95 4.35l3.4 1.76s1.57-2.96 2.14-4.47.69-2.84-.44-3.47zM63.95 63.77s1.83-5.23 2.58-7.31 2.14-5.29 3.78-4.41c1.64.88.44 4.66-.25 6.55-.69 1.89-2.58 7.24-2.58 7.24l-3.53-2.07zM97.39 49.29c-1.35-.51-2.77 1.32-4.53 5.42s-1.89 4.72-2.27 5.73c-.38 1.01-.76 3.15.88 3.84 1.64.69 2.69-1.51 3.21-2.58.94-1.95 1.89-4.47 2.83-6.8s1.71-4.92-.12-5.61z"
    />
    <path
      fill="#fc5027"
      d="m91.41 29.87 15.05.02s-.2 1.42-.29 2.51c-.08.94-.21 2.65-.21 2.65l-13.98.01s-.07-1.26-.26-2.77c-.11-.93-.31-2.42-.31-2.42zM92.02 40.81l13.96.01s.17 1.5.33 2.5.51 2.58.51 2.58l-15.29-.04s.21-1.4.32-2.56c.11-1.18.17-2.49.17-2.49zM64.3 32.54l15.16.05s-.24 1.56-.35 2.84c-.12 1.32-.21 2.66-.21 2.66H64.91s-.14-1.44-.25-2.72c-.11-1.28-.36-2.83-.36-2.83zM64.91 44.11l14.02.02s.17 1.64.48 3.01c.2.89.62 2.35.62 2.35H64.31s.25-1.7.36-2.59.24-2.79.24-2.79zM36.65 29.04l16.59-.01s-.4 2.1-.57 2.92c-.23 1.12-.49 2.62-.49 2.62l-14.81-.02s-.14-1.62-.28-2.68c-.19-1.27-.44-2.83-.44-2.83zM37.51 39.93l14.35.02s.06 1.91.11 2.63c.06.72.33 2.93.33 2.93H37.09s.2-1.99.28-3.06c.07-.96.14-2.52.14-2.52z"
    />
    <path
      fill="#474c4f"
      d="M16.55 109.93c11.42 17.31 39.44 19.54 53.33 3.77s10.71-41.86-6.59-52.51C46.45 50.83 5.16 92.67 16.55 109.93z"
    />
    <path
      fill="#5e6367"
      d="M22.67 62.95c-11.02 8.08-16.66 26.71-8.35 43.14.65 1.29 1.33 2.62 2.23 3.83 2 2.71 28.14 7.06 41.44-7.42 14.22-15.48 9.89-37.09 5.3-41.32-1.2-1.1-2.76-1.59-4.12-2.24-15.19-7.14-28.73-1.68-36.5 4.01z"
    />
    <path
      fill="#191919"
      d="M41.47 87.01c-.19 3.27 2.16 4.93 4.38 5.1 2.22.17 5.26-.94 5.43-4.38.17-3.33-1.94-5.27-4.82-5.32-2.68-.05-4.82 1.78-4.99 4.6zM31.98 74.25c-2.82.22-4.21 2.21-4.32 4.71-.11 2.38 1.39 4.99 5.1 4.93 3.33-.05 4.95-2.55 4.59-5.24-.41-3.07-2.49-4.62-5.37-4.4zM28.81 94.18c-2.99.11-4.21 2.22-4.27 4.38-.06 2.16 1.61 4.33 4.44 4.27 2.94-.06 4.27-2 4.32-4.27s-1.44-4.49-4.49-4.38z"
    />
  </svg>
);
