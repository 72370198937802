import React, { SVGProps, useId } from "react";

export const GrandpaIcon = (props: SVGProps<SVGSVGElement>) => {
  const a = useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      viewBox="0 0 128 128"
      {...props}
    >
      <path
        fill="#e59600"
        d="M99.92 64.57H28.08C20.34 64.57 14 71.51 14 79.99s6.34 15.42 14.08 15.42h71.84c7.75 0 14.08-6.94 14.08-15.42s-6.34-15.42-14.08-15.42z"
      />
      <linearGradient
        id={a}
        x1={64}
        x2={64}
        y1={97.388}
        y2={34.579}
        gradientTransform="matrix(1 0 0 -1 0 128)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#e0e0e0" />
        <stop offset={1} stopColor="#9e9e9e" />
      </linearGradient>
      <path
        fill={`url(#${a})`}
        d="M117.14 50.27c-.52-8.56-4.29-13.6-9.56-16.38-4.18-2.21-9.3-3-14.5-3H34.92c-5.2 0-10.34.79-14.52 3-5.26 2.79-9.02 7.83-9.54 16.39-.7 11.48 3.7 21.66 10.59 26.5 2.64 1.87 5.66 2.94 8.89 2.99.09.01.17.01.26.01.12 0 .25 0 .37-.01H97.4c3.32 0 6.42-1.08 9.13-2.99 6.9-4.83 11.31-15.02 10.61-26.51z"
      />
      <path
        fill="#ffca28"
        d="M64 12.21c-34.67 0-44.06 24.4-44.06 59.52 0 34.93 17.06 52.2 44.06 52.2s44.06-17.27 44.06-52.2c0-35.12-9.39-59.52-44.06-59.52z"
      />
      <path
        fill="#eda600"
        d="M40.63 105.31c-.38 0-.77-.15-1.06-.44a1.49 1.49 0 0 1 0-2.12l9.53-9.53c.59-.59 1.53-.59 2.12 0s.59 1.54 0 2.12l-9.53 9.53c-.29.29-.68.44-1.06.44zM87.37 105.31c-.38 0-.77-.15-1.06-.44l-9.53-9.53c-.59-.59-.59-1.54 0-2.12s1.54-.59 2.12 0l9.53 9.53c.59.59.59 1.54 0 2.12-.29.29-.68.44-1.06.44zM42.91 90.5c-2.63 0-5.27-.85-7.28-2.54-.63-.53-.71-1.48-.18-2.11.53-.63 1.48-.71 2.11-.18 2.95 2.49 7.74 2.49 10.69 0 .63-.54 1.58-.46 2.11.18.53.63.46 1.58-.18 2.11-2 1.69-4.63 2.54-7.27 2.54zM85.09 90.5c-2.64 0-5.27-.85-7.28-2.54-.63-.53-.71-1.48-.18-2.11.53-.63 1.48-.71 2.11-.18 2.95 2.49 7.74 2.49 10.69 0 .63-.54 1.58-.46 2.11.18.54.63.46 1.58-.18 2.11-2 1.69-4.63 2.54-7.27 2.54zM42.98 54.37c-.6 0-1.16-.36-1.4-.95a1.5 1.5 0 0 1 .84-1.95c.37-.15 9.1-3.54 21.24-3.54 12.13 0 21.49 3.39 21.88 3.53.78.29 1.18 1.15.89 1.92a1.5 1.5 0 0 1-1.93.89c-.09-.03-9.28-3.35-20.84-3.35-11.58 0-20.05 3.3-20.14 3.33-.17.09-.36.12-.54.12zM78.97 45.96c-.15 0-.3-.02-.45-.07-.07-.02-6.86-2.14-14.52-2.14s-14.45 2.12-14.52 2.14a1.498 1.498 0 1 1-.9-2.86c.29-.09 7.28-2.28 15.42-2.28s15.13 2.19 15.42 2.28a1.498 1.498 0 0 1-.45 2.93z"
      />
      <g fill="#404040">
        <ellipse cx={42.44} cy={77.34} rx={6.48} ry={6.71} />
        <ellipse cx={85.69} cy={77.34} rx={6.48} ry={6.71} />
      </g>
      <path
        fill="#e59600"
        d="M69.07 89.56a1.63 1.63 0 0 0-.42-.11h-9.3c-.14.02-.28.05-.42.11-.84.34-1.31 1.22-.91 2.14.4.93 2.25 3.54 5.98 3.54s5.58-2.61 5.98-3.54-.06-1.8-.91-2.14z"
      />
      <path
        fill="#795548"
        d="M75.01 99.97c-4.19 2.49-17.91 2.49-22.1 0-2.4-1.43-4.86.76-3.86 2.94.98 2.15 8.47 7.13 14.95 7.13 6.47 0 13.87-4.98 14.85-7.13.99-2.19-1.43-4.37-3.84-2.94z"
      />
      <g fill="#9e9e9e">
        <path d="M52.53 64.89c-1.27-1.91-4.23-4.69-9.95-4.69s-8.68 2.78-9.96 4.69a2.21 2.21 0 0 0-.03 2.41c.36.55 1.43 1.06 2.62.6s3.5-1.81 7.37-1.84c3.87.03 6.19 1.38 7.37 1.84 1.18.46 2.25-.05 2.62-.6.39-.59.53-1.57-.04-2.41zM95.47 64.89c-1.27-1.91-4.23-4.69-9.96-4.69s-8.68 2.78-9.96 4.69a2.21 2.21 0 0 0-.03 2.41c.36.55 1.43 1.06 2.62.6s3.5-1.81 7.37-1.84c3.87.03 6.18 1.38 7.37 1.84 1.18.46 2.25-.05 2.62-.6.39-.59.53-1.57-.03-2.41z" />
      </g>
    </svg>
  );
};
