import React, { SVGProps, useId } from "react";
export const ConstructionWorkerIcon = (props: SVGProps<SVGSVGElement>) => {
  const a = useId();
  const b = useId();
  const c = useId();
  const d = useId();
  const e = useId();
  const f = useId();
  const g = useId();
  const h = useId();
  const i = useId();
  const j = useId();
  const k = useId();
  const l = useId();
  const m = useId();
  const n = useId();
  const o = useId();
  const p = useId();
  const q = useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      viewBox="0 0 128 128"
      {...props}
    >
      <defs>
        <radialGradient
          id={a}
          cx={99.719}
          cy={45.167}
          r={23.635}
          gradientTransform="matrix(1 0 0 .4912 -21.218 59.61)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.728} stopColor="#6d4c41" stopOpacity={0} />
          <stop offset={1} stopColor="#6d4c41" />
        </radialGradient>
        <radialGradient
          id={b}
          cx={78.394}
          cy={52.487}
          r={6.935}
          gradientTransform="matrix(-.9057 .4238 -.3144 -.6719 188.815 80.345)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.663} stopColor="#6d4c41" />
          <stop offset={1} stopColor="#6d4c41" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          id={c}
          cx={97.159}
          cy={68.993}
          r={30.046}
          gradientTransform="matrix(-.0746 -.9972 .8311 -.0622 33.045 160.08)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.725} stopColor="#6d4c41" stopOpacity={0} />
          <stop offset={1} stopColor="#6d4c41" />
        </radialGradient>
        <radialGradient
          id={d}
          cx={46.811}
          cy={71.386}
          r={30.046}
          gradientTransform="matrix(.0746 -.9972 -.8311 -.0622 100.723 110.021)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.725} stopColor="#6d4c41" stopOpacity={0} />
          <stop offset={1} stopColor="#6d4c41" />
        </radialGradient>
        <radialGradient
          id={e}
          cx={51.52}
          cy={45.167}
          r={23.635}
          gradientTransform="matrix(-1 0 0 .4912 101.041 59.61)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.728} stopColor="#6d4c41" stopOpacity={0} />
          <stop offset={1} stopColor="#6d4c41" />
        </radialGradient>
        <radialGradient
          id={f}
          cx={26.384}
          cy={51.344}
          r={6.934}
          gradientTransform="matrix(.9057 .4238 .3144 -.6719 -13.326 101.62)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.663} stopColor="#6d4c41" />
          <stop offset={1} stopColor="#6d4c41" stopOpacity={0} />
        </radialGradient>
        <linearGradient
          id={g}
          x1={64}
          x2={64}
          y1={34.219}
          y2={-7.598}
          gradientTransform="matrix(1 0 0 -1 0 130)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.003} stopColor="#448aff" />
          <stop offset={0.404} stopColor="#3b7dff" />
          <stop offset={1} stopColor="#2962ff" />
        </linearGradient>
        <linearGradient
          id={h}
          x1={44.025}
          x2={44.025}
          y1={31.848}
          y2={-5.871}
          gradientTransform="matrix(1 0 0 -1 0 130)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.003} stopColor="#ff9100" />
          <stop offset={0.44} stopColor="#ff8400" />
          <stop offset={1} stopColor="#ff6d00" />
        </linearGradient>
        <linearGradient
          id={i}
          x1={43.075}
          x2={43.075}
          y1={32.056}
          y2={-6.655}
          gradientTransform="matrix(1 0 0 -1 0 130)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.003} stopColor="#ff0" />
          <stop offset={0.678} stopColor="#fff200" />
          <stop offset={1} stopColor="#ffea00" />
        </linearGradient>
        <linearGradient
          id={j}
          x1={83.975}
          x2={83.975}
          y1={31.811}
          y2={-5.853}
          gradientTransform="matrix(1 0 0 -1 0 130)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.003} stopColor="#ff9100" />
          <stop offset={0.44} stopColor="#ff8400" />
          <stop offset={1} stopColor="#ff6d00" />
        </linearGradient>
        <linearGradient
          id={k}
          x1={84.92}
          x2={84.92}
          y1={31.958}
          y2={-6.607}
          gradientTransform="matrix(1 0 0 -1 0 130)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.003} stopColor="#ff0" />
          <stop offset={0.678} stopColor="#fff200" />
          <stop offset={1} stopColor="#ffea00" />
        </linearGradient>
        <radialGradient
          id={l}
          cx={64}
          cy={98.344}
          r={34.638}
          gradientTransform="matrix(1 0 0 -.9806 0 127.575)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#ff0" />
          <stop offset={0.325} stopColor="#fff600" />
          <stop offset={0.854} stopColor="#ffde00" />
          <stop offset={0.998} stopColor="#ffd600" />
        </radialGradient>
        <linearGradient
          id={m}
          x1={64.322}
          x2={64.322}
          y1={125.265}
          y2={88.227}
          gradientTransform="matrix(1 0 0 -1 0 130)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#ff0" />
          <stop offset={0.446} stopColor="#fff900" />
          <stop offset={1} stopColor="#ffea00" />
        </linearGradient>
        <path
          id={n}
          d="M64.41 7.92c-46.76 0-43.24 46.43-43.24 46.43s12.21-10.8 43.5-10.8 42.1 9.8 42.1 9.8 4.4-45.43-42.36-45.43z"
        />
        <clipPath id={o}>
          <use xlinkHref="#n" overflow="visible" />
        </clipPath>
        <path
          id={p}
          d="M64.41 7.92c-46.76 0-43.24 46.43-43.24 46.43s12.21-10.8 43.5-10.8 42.1 9.8 42.1 9.8 4.4-45.43-42.36-45.43z"
        />
        <clipPath id={q}>
          <use xlinkHref="#p" overflow="visible" />
        </clipPath>
      </defs>
      <path
        fill="#543930"
        d="M98.54 79.48c-1.28-2.3.24-4.63 2.84-7.5 4.12-4.54 8.41-14.96 3.88-25.4.02-.06-.88-1.8-.86-1.86l-1.74-.09c-.56-.08-19.6-.12-38.67-.12s-38.11.04-38.67.12c0 0-2.62 1.89-2.59 1.95-4.53 10.44-.24 20.86 3.88 25.4 2.61 2.87 4.12 5.2 2.84 7.5-1.25 2.24-4.83 2.57-4.83 2.57s.77 2.3 2.73 3.54c1.82 1.15 4.07 1.42 5.64 1.46 0 0 5.98 8.75 22.05 8.75h17.9c16.08 0 22.05-8.75 22.05-8.75 1.57-.04 3.82-.31 5.64-1.46 1.96-1.24 2.73-3.54 2.73-3.54s-3.58-.33-4.82-2.57z"
      />
      <path
        fill={`url(#${a})`}
        d="M63.99 95.79v-9.7l28.35-2.31 2.64 3.26S89 95.79 72.93 95.79h-8.94z"
      />
      <path
        fill={`url(#${b})`}
        d="M94.84 82.84c-4.36-6.58 4.96-9.02 4.96-9.02l.01.01c-1.58 2.07-2.28 3.87-1.29 5.66 1.25 2.24 4.83 2.57 4.83 2.57s-4.76 4.43-8.51.78z"
      />
      <path
        fill={`url(#${c})`}
        d="M105.28 46.58c4.42 10.11.34 20.66-3.66 25.15-.55.62-2.9 3.02-3.4 4.95 0 0-9.64-13.31-12.54-21.02-.58-1.55-1.11-3.14-1.2-4.78-.07-1.23.1-2.68.79-3.72.84-1.27 19.49-1.65 19.49-1.65 0 .01.52 1.07.52 1.07z"
      />
      <path
        fill={`url(#${d})`}
        d="M22.74 46.58c-4.42 10.11-.34 20.66 3.66 25.15.55.62 2.9 3.02 3.4 4.95 0 0 9.64-13.31 12.54-21.02.58-1.55 1.11-3.14 1.2-4.78.07-1.23-.1-2.68-.79-3.72-.84-1.27-1.85-.93-3.32-.93-2.79 0-14.98-.71-15.93-.71.01 0-.76 1.06-.76 1.06z"
      />
      <path
        fill={`url(#${e})`}
        d="M64.03 95.79v-9.7l-28.35-2.31-2.64 3.26s5.98 8.75 22.05 8.75h8.94z"
      />
      <path
        fill={`url(#${f})`}
        d="M33.18 82.84c4.36-6.58-4.96-9.02-4.96-9.02l-.01.01c1.58 2.07 2.28 3.87 1.29 5.66-1.25 2.24-4.83 2.57-4.83 2.57s4.76 4.43 8.51.78z"
      />
      <path
        fill={`url(#${g})`}
        d="M116 123.96v-3.12c0-16.78-26.35-25.09-52-25.06-25.49.03-52 7.28-52 25.06v3.12h104z"
      />
      <path
        fill="#e59600"
        d="M64 90.11h-9.08v9.98c0 4.51 3.7 8.17 8.26 8.17h1.65c4.56 0 8.26-3.66 8.26-8.17v-9.98H64z"
      />
      <path
        fill={`url(#${h})`}
        d="M58.5 123.96H29.55v-22.74c1.79-.7 3.68-1.32 5.64-1.86 2.89-.82 5.94-1.49 9.1-2.01 2.14-.36 4.33-.65 6.55-.88l7.66 27.49z"
      />
      <path
        fill={`url(#${i})`}
        d="M50.96 123.96H36.29l-1.1-24.6c2.89-.82 5.94-1.49 9.1-2.01l6.67 26.61z"
      />
      <path
        fill={`url(#${j})`}
        d="M98.45 101.52v22.44H69.5l7.65-27.45c2.21.24 4.4.56 6.53.94 3.17.57 6.23 1.27 9.12 2.13 1.97.58 3.86 1.23 5.65 1.94z"
      />
      <path
        fill={`url(#${k})`}
        d="m92.8 99.58-1.09 24.38H77.04l6.64-26.51c3.17.57 6.23 1.27 9.12 2.13z"
      />
      <path
        fill="#e59600"
        d="M91.33 50.43H36.67c-5.89 0-10.71 5.14-10.71 11.41s4.82 11.41 10.71 11.41h54.65c5.89 0 10.71-5.14 10.71-11.41s-4.81-11.41-10.7-11.41z"
      />
      <path
        fill="#ffca28"
        d="M64 11.11c-17.4 0-33.52 18.61-33.52 45.39 0 26.64 16.61 39.81 33.52 39.81S97.52 83.14 97.52 56.5c0-26.78-16.12-45.39-33.52-45.39z"
      />
      <g fill="#404040">
        <ellipse cx={48.4} cy={58.81} rx={4.93} ry={5.1} />
        <ellipse cx={79.6} cy={58.81} rx={4.93} ry={5.1} />
      </g>
      <g fill="#6d4c41">
        <path d="M55.64 49.82c-.93-1.23-3.07-3.01-7.23-3.01s-6.31 1.79-7.23 3.01c-.41.54-.31 1.17-.02 1.55.26.35 1.04.68 1.9.39s2.54-1.16 5.35-1.18c2.81.02 4.49.89 5.35 1.18.86.29 1.64-.03 1.9-.39.28-.38.39-1.01-.02-1.55zM86.83 49.82c-.93-1.23-3.07-3.01-7.23-3.01s-6.31 1.79-7.23 3.01c-.41.54-.31 1.17-.02 1.55.26.35 1.04.68 1.9.39s2.54-1.16 5.35-1.18c2.81.02 4.49.89 5.35 1.18.86.29 1.64-.03 1.9-.39.28-.38.39-1.01-.02-1.55z" />
      </g>
      <path
        fill="#e59600"
        d="M67.86 68.06c-.11-.04-.21-.07-.32-.08h-7.08c-.11.01-.22.04-.32.08-.64.26-.99.92-.69 1.63.3.71 1.71 2.69 4.55 2.69s4.25-1.99 4.55-2.69c.3-.71-.05-1.37-.69-1.63z"
      />
      <path
        fill="#795548"
        d="M72.42 76.14c-3.18 1.89-13.63 1.89-16.81 0-1.83-1.09-3.7.58-2.94 2.24.75 1.63 6.44 5.42 11.37 5.42s10.55-3.79 11.3-5.42c.75-1.66-1.09-3.33-2.92-2.24z"
      />
      <path
        fill="#543930"
        d="M64 11.07c-37.26 0-34.74 41.41-34.74 41.41-.46 8.01 1.03 12.94 1.82 14.93.14.35.63.32.72-.04.99-3.97 4.36-17.8 4.03-20.21 0 0 11.35-2.25 22.17-10.22 2.2-1.62 4.59-3 7.13-4.01 13.59-5.41 16.43 3.82 16.43 3.82s9.42-1.81 12.26 11.27c1.07 4.9 1.79 12.75 2.4 18.24.04.39.57.47.72.11.95-2.18 3.1-6.48 3.3-10.91.69-14.78-8.06-44.39-36.24-44.39z"
      />
      <path
        fill={`url(#${l})`}
        d="M64.41 7.92c-46.76 0-43.24 46.43-43.24 46.43s12.21-10.8 43.5-10.8 42.1 9.8 42.1 9.8 4.4-45.43-42.36-45.43z"
      />
      <path
        fill={`url(#${m})`}
        d="M74.72 42.15h-20.8V9.05c0-2.79 2.26-5.05 5.05-5.05h10.7c2.79 0 5.05 2.26 5.05 5.05v33.1z"
      />
      <path
        fill="#edbf00"
        d="m47.12 10.23 6.95-2.3v23.29c-1.41-10.07-3.43-17.35-6.95-20.99z"
        clipPath={`url(#${o})`}
        opacity={0.5}
      />
      <path
        fill="#edbf00"
        d="m81.71 10.23-6.95-2.3v23.29c1.41-10.07 3.43-17.35 6.95-20.99z"
        clipPath={`url(#${q})`}
        opacity={0.5}
      />
      <path
        fill="#ffea00"
        d="M109.53 50.99c-.25-.42-.64-.74-1.07-.99C90.36 39.58 64 39.93 64 39.93S37.64 39.58 19.54 50c-.44.25-.82.57-1.07.99-.53.9-.97 2.61.75 5.08 0 0 10.95-10.85 44.78-11.53 33.82.69 44.78 11.53 44.78 11.53 1.72-2.47 1.28-4.18.75-5.08z"
      />
    </svg>
  );
};
