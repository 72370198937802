import React, { SVGProps } from "react";
export const LionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#865c50"
      d="M62.59 13.59s1.83-4.22 5.98-6.9c2.02-1.3 5.55-3.31 6.19-2.18.28.49-.63 1.76-1.55 3.73-.37.81-1.2 3.45-1.2 3.45s3.66-4.79 10.07-6.05c5.04-1 10.49-.35 11.12.77.68 1.21-2.89 3.87-4.01 6.19s-1.97 5.28-1.97 5.28 6.75-3.19 8.45-3.87c2.11-.84 6.69-2.6 7.32-1.83s-2.02 5.24-2.6 6.05c-.7.99-1.76 2.82-1.76 2.82s3.24-1.48 4.5-2.25c1.27-.77 4.08-2.96 4.72-2.53.63.42.63 4.7.21 6.55-.56 2.46-3.17 5.14-3.17 5.14s3.73.84 5.77 3.45 3.23 4.64 4.08 7.25c1.27 3.87 1.83 9.15 1.55 10.14-.28.99-1.83-.35-4.72-.56-1.71-.13-2.11-.14-2.11-.14s1.63 1.94 2.39 3.73c.99 2.32 2.07 5.98 2.67 7.53.63 1.62 1.13 2.89 2.04 4.72.27.54-1.9 1.06-4.08-.99-.73-.68-1.62-1.34-1.62-1.34s1.29 7.67 1.2 12.81c-.07 3.8-.92 10.7-2.04 11.33-1.13.63-3.38-4.22-3.38-4.22s-.98 5.52-2.11 9.22c-1.34 4.36-2.61 6.4-3.38 9.01-.92 3.1-1.83 5.35-2.46 4.36-.55-.85-1.13-2.18-1.69-3.17-.43-.75-1.41-2.75-1.41-2.75s-.99 5.98-5.77 10-7.88 7.25-10.14 7.53c-1.33.17.21-5.84.21-5.84s-7.46 5.86-11.05 8.52c-1.62 1.2-3.1 5.14-4.22 4.86-1.13-.28-4.01-4.65-5.84-5.98s-5.28-2.89-5.77-3.17c-.49-.28-2.82-1.76-2.82-1.76s.58 3.27-.07 3.59c-1.41.7-6.83-3.17-8.38-5.56-1.55-2.39-4.29-7.32-4.29-7.32s-.35 4.72-1.48 3.94c-1.2-.82-7.04-5.14-9.08-8.38-1.44-2.29-3.94-8.73-3.94-8.73s-2.18 2.25-2.82 2.53-2.25.92-1.97-1.55c.28-2.46-1.65-8.04-1.97-11.4-.42-4.36.42-8.87.42-8.87s-3.59 4.79-5.28 4.72-.7-9.29-.28-12.11c.42-2.82 1.83-7.74 2.39-9.5.56-1.76 2.67-4.36 2.67-4.36s-5.21 1.76-5.98 1.06.42-6.97 1.13-10.77c.36-1.95 1.9-5 3.87-7.25s5-1.83 5.28-2.82c.28-.99-1.12-1.77-2.11-3.38-1.48-2.39-2.32-7.32-1.55-8.17.48-.52 3.17 1.55 6.41 1.9s6.12.14 6.12.14-1.87-2.2-2.67-4.01c-1.13-2.53-1.2-5.56-.49-6.34s3.17 1.62 6.48 2.46c3.31.84 8.24.92 8.24.92s-2.11-2.25-2.67-4.86c-.56-2.6-.42-4.22.49-4.5.92-.28 3.6 2.23 5.49 2.82 2.04.63 8.94.21 12.04 1.13s6.4 5.76 6.4 5.76z"
    />
    <path
      fill="#fec000"
      d="M84.83 35.56s12.11-11.54 19.15-2.96c8.35 10.19-13.8 21.4-13.8 21.4l-5.35-18.44zM42.6 38.51s-11.29-14.8-19.59-6.78 8.89 20.29 8.89 20.29l10.7-13.51z"
    />
    <path
      fill="#ff8e06"
      d="M95.25 45.41s8.87-6.19 5.21-10.14c-3.91-4.21-11.12 3.1-11.12 3.1l5.91 7.04zM37.44 38.87s-7.35-7.28-11.15-2.78c-3.42 4.05 6.34 11.83 6.34 11.83l4.81-9.05z"
    />
    <path
      fill="#fec000"
      d="M64.7 89.47s7.18-1.97 11.68-4.22S97.64 73.57 98.9 62.02c1.27-11.54 0-14.64-5.35-21.68s-14.73-8.66-19.57-7.6c-5.77 1.27-6.76 5.48-9.29 5.63-2.39.14-1.69-2.53-8.59-5.07-8.83-3.25-33 5.45-28.72 28.72 2.25 12.25 20.1 22.7 22.38 23.93 5.51 2.96 14.94 3.52 14.94 3.52z"
    />
    <ellipse
      cx={79.1}
      cy={49.5}
      fill="#2e2f2f"
      rx={4.23}
      ry={4.15}
      transform="rotate(-68.297 79.098 49.498)"
    />
    <ellipse
      cx={48.78}
      cy={48.96}
      fill="#2e2f2f"
      rx={4.27}
      ry={4.35}
      transform="rotate(-22.13 48.782 48.96)"
    />
    <path
      fill="#ffd899"
      d="M64.51 57c-8.54-.26-16.05 9.39-16.05 25.43 0 15.58 8.26 19.24 14.92 19.24 8.45 0 16.42-5.54 15.49-20.65C78 67.04 73.61 57.28 64.51 57z"
    />
    <path
      fill="#2e2f2f"
      d="M54.56 65.82c-.84.84.75 5.44 2.16 7.79s5.44 6.19 7.7 6.1c2.25-.09 6.48-4.79 7.79-8.26 1.31-3.47 1.69-5.63 1.13-6.48s-7.23.19-9.76.19-8.17-.18-9.02.66z"
    />
    <path
      fill="#2e2f2f"
      d="M64.23 76.48s-3.63 3.95-5.21 4.55c-2.21.84-5.63.12-5.73 1.48-.09 1.41 2.11 2.89 5.63 2.42 3.89-.52 5.35-2.91 5.35-2.91s2.21 3.14 6.48 3.33c4.27.19 5.26-2.53 4.08-2.91-1.6-.51-2.6-.41-5.02-1.17-1.92-.62-5.58-4.79-5.58-4.79z"
    />
  </svg>
);
