import React, { SVGProps } from "react";

export const ChampagneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#485020"
      d="m76.11 60.1-4.5-1.39-12.8-2.37s-8.89-11.01-9.23-11.42c-.34-.41-1.57-4.31-1.57-4.31s-.67.1-1.12-.29c-.52-.44-1.25-2.32-1.25-2.32l-10.49 9.39s1.24.67 1.57 1.5c.33.82.29 1.28.29 1.28l10.28 4.95 8.09 16.18s-1.5 2.19-1.66 4.01c-.58 6.65 3.49 13.91 6.86 18.72 1.63 2.33 10.89 13.87 14.07 17.05s11.17 12.33 13.01 12.91c1.83.58 5.39-.19 8.09-.96s6.26-2.31 8.48-3.85c2.22-1.54 2.79-5.88 0-9.34S76.11 60.1 76.11 60.1z"
    />
    <path
      fill="#f6af41"
      d="m44 50.41-3.61 2.43s3.22 3.15 7.75 11.17 5.52 13.27 5.52 13.27 1.77-.59 3.22-1.12c1.45-.53 5.32-1.91 5.32-1.91s-6.11-10.58-7.82-13.53-7.16-9.46-7.36-9.59c-.19-.13-3.02-.72-3.02-.72z"
    />
    <path
      fill="#f7c876"
      d="m49.52 43.38-4.4 7.56s4.66 6.04 7.49 11.1 6.04 13.49 6.04 13.49 6.31-2.44 10.4-3.37c5.15-1.17 8.7-1.28 8.93-1.6.39-.55-2.96-7.54-3.62-8.85-.66-1.31-1.51-2.63-1.51-2.63s-7.16-2.23-13.47-7.16-9.86-8.54-9.86-8.54z"
    />
    <path
      fill="#317c31"
      d="M59.51 77.74s5.91-2.83 12.68-4.01c6.77-1.18 9-.79 9.4-1.38.39-.59-5.52-12.29-5.52-12.29s3.68 1.05 9.59 5.06 11.6 10.11 11.6 10.11l17.31 19.45s2.43 2.5 2.5 5.39c.07 2.89-1.37 6.08-3.66 9.91-5.56 9.27-16.89 13.11-17.29 12.91-.39-.2-8.75-8.63-15.06-15.59-6.31-6.96-14.31-15.76-17.34-20.89-4.01-6.76-4.21-8.67-4.21-8.67z"
    />
    <path
      fill="#f5b03e"
      d="M77.84 92.4c-.26 1.38 17.5 21.23 18.92 21.29 1.71.06 9.52-3.64 13.78-11.27 3.34-5.98 2.62-9.33 2.5-9.48-.24-.31-13.8-15.69-14.63-16.63-1.45-1.64-5.25-5.11-5.25-5.11s-.68 4.24-4.14 9.38c-5.47 8.07-11.01 10.94-11.18 11.82z"
    />
    <path
      fill="#ffebca"
      d="M90.82 81.81c-4.15 4.98-6.11 12.55.26 19.45 6.37 6.9 14.52 2.84 17.02-1.97 2.69-5.19 1.56-10.24 1.56-10.24s-3.53-4.21-5.3-6.18c-1.77-1.97-4.04-4.4-4.04-4.4s-5.89-.99-9.5 3.34z"
    />
    <path
      fill="#317c31"
      d="M36.38 37.54c-3.19 2.73-4.73 7.16-2.37 9.4 2.37 2.23 6.6.36 9-1.84 3.28-3 4.4-5.98 2.04-8.48-2.36-2.5-5.91-1.45-8.67.92zM36.98 49.93s4.07.81 7.75-2.94c3.68-3.75 3.28-6.38 3.28-6.38s2.75-.19 2.63 3.16c-.13 3.61-5.19 9.92-11.3 9.72-2.74-.08-2.36-3.56-2.36-3.56z"
    />
    <path
      fill="#fff1c2"
      d="M39.59 27.15s.45-3.87-1.8-4.09c-2.75-.26-3.58 2.58-3.58 4.56s-.13 5.32-.13 5.32l-6.7 2.43s-3.61-.57-4.69 1.52c-.94 1.83.49 3.01.49 3.01l11.3-1.7s4.01-4.86 4.01-5.26 1.1-5.79 1.1-5.79z"
    />
    <path
      fill="#fee465"
      d="M36.12 30.9s-.35-7.09 1.77-7.42c1.67-.26 1.77 1.71 1.84 2.63.07.92.61 10.69.59 11.17-.07 1.97-2.56 4.47-5.39 4.53-.99.02-10.58-.53-11.76-1.84-.68-.76.33-1.64 1.12-2.04s2.63-.39 2.63-.39-1.58-3.02.85-5.12c2.44-2.11 8.35-1.52 8.35-1.52zM34.35 13.36c1.71-.07 2.04-2.63 1.77-5.32-.26-2.69-.98-4.72-2.56-4.6-1.77.13-2.17 2.76-1.91 5.12s.99 4.86 2.7 4.8zM39.73 19.53c1.44.56 2.76-1.64 3.28-3.22.53-1.58.99-3.74-.66-4.53-1.64-.79-3.02 1.71-3.42 3.22s-.71 3.94.8 4.53zM18.12 35.43c0 1.12-1.58 2.23-4.53 2.3-2.96.07-4.4-1.31-4.34-3.02.07-1.71 2.83-2.23 4.6-1.97 1.77.26 4.27 1.05 4.27 2.69z"
    />
    <path
      fill="#f2a259"
      d="M26.87 15.7s-2.21-3.43-4.01-3.55c-1.32-.09-4.67 1.18-7.1 3.49-2.89 2.76-4.03 6.37-3.74 7.36.38 1.32 2.5 3.48 3.09 3.68.59.2 10.71-2.5 10.71-2.76s1.05-8.22 1.05-8.22z"
    />
    <path
      fill="#cd844f"
      d="M21.94 22.34c-4.46 3.84-7.84 3.57-7.84 3.57s1.08 1.55 2.06 1.62c.99.07 1.91-.13 1.91-.13s2.96 4.07 4.99 4.07 3.84-.96 5.58-2.56c1.58-1.45 2.83-3.22 2.63-5.12-.11-1.05-3.48-4.8-3.48-4.8s-.02-1.21-.22-1.93-.9-1.61-.9-1.61-.83 3.53-4.73 6.89z"
    />
  </svg>
);
