import React, { HTMLProps } from "react";

export const ShuffleIcon: React.FC<HTMLProps<SVGSVGElement>> = ({
  ...props
}) => (
  <svg fill="currentColor" version="1.1" viewBox="0 0 512 512" {...props}>
    <path
      d="M512,387.67L405.406,281.024v84.694h-10.435c-48.766,0-80.203-28.752-108.698-68.925
			c-7.856,12.825-16.14,26.159-25.113,39.387c32.329,41.052,73.081,73.424,133.81,73.424h10.435v84.686L512,387.67z"
    />
    <path
      d="M512,124.355L405.406,17.71v84.695h-10.435c-92.763,0-138.917,75.524-179.637,142.158
			c-39.73,65.009-74.04,121.155-142.191,121.155H0v43.886h73.143c92.763,0,138.917-75.524,179.637-142.158
			c39.73-65.011,74.04-121.157,142.191-121.157h10.435v84.686L512,124.355z"
    />
    <path
      d="M73.143,102.404H0v43.886h73.143c48.766,0,80.203,28.752,108.698,68.925c7.856-12.825,16.14-26.159,25.113-39.387
			C174.624,134.774,133.872,102.404,73.143,102.404z"
    />
  </svg>
);
