import React, { SVGProps } from "react";

export const PaintIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 491.52 491.52"
    {...props}
  >
    <path
      d="M406.759 87.803c100.547 100.548 100.547 263.568 0 364.116-114.268 114.268-117.922-45.098-218.47-145.646C87.741 205.724-73.464 203.909 42.643 87.803c100.548-100.548 263.568-100.548 364.116 0z"
      style={{
        fill: "#f6c358",
      }}
    />
    <path
      d="M413.839 75.411c100.548 100.548 100.548 263.569 0 364.116-114.268 114.268-117.922-45.098-218.47-145.646S-66.383 191.518 49.724 75.411c100.547-100.548 263.567-100.548 364.115 0z"
      style={{
        fill: "#fcd462",
      }}
    />
    <circle
      cx={97.587}
      cy={121.544}
      r={39.854}
      style={{
        fill: "#dc8744",
      }}
    />
    <circle
      cx={209.449}
      cy={75.049}
      r={39.854}
      style={{
        fill: "#fff",
      }}
    />
    <circle
      cx={333.158}
      cy={96.635}
      r={39.854}
      style={{
        fill: "#e56353",
      }}
    />
    <circle
      cx={407.04}
      cy={190.566}
      r={39.854}
      style={{
        fill: "#27a2db",
      }}
    />
    <circle
      cx={407.04}
      cy={310.118}
      r={39.854}
      style={{
        fill: "#64798a",
      }}
    />
    <circle
      cx={349.747}
      cy={399.821}
      r={39.854}
      style={{
        fill: "#ce412d",
      }}
    />
    <path
      d="M221.082 134.491 103.008 481.009a7.946 7.946 0 0 0 14.895 5.523L254.276 146.8l-33.194-12.309z"
      style={{
        fill: "#dc8744",
      }}
    />
    <path
      d="M225.536 109.778h35.405v31.867h-35.405z"
      style={{
        fill: "#ebf0f3",
      }}
      transform="rotate(-159.652 243.243 125.706)"
    />
    <path
      d="M270.875 95.548c-2.495-2.563-5.256-5.651-8.827-5.448-1.82.103-3.477 1.087-5.276 1.389-3.453.579-7.151-1.795-8.061-5.176-.449-1.661-.286-3.451-.826-5.086-.081-.243-.23-.465-.358-.697-8.036 7.483-14.373 12.394-16.568 18.315-1.419 3.828-1.59 7.794-.807 11.513l32.979 12.228c3.021-2.309 5.476-5.428 6.895-9.257 1.055-2.844 1.948-8.969 2.59-16.224-.57-.533-1.199-.998-1.741-1.557z"
      style={{
        fill: "#64798a",
      }}
    />
    <path
      d="M248.712 86.312c.91 3.382 4.608 5.755 8.061 5.176 1.799-.301 3.456-1.286 5.276-1.389 3.57-.203 6.331 2.885 8.827 5.448.542.559 1.171 1.025 1.741 1.556 1.791-20.264 1.559-49.556-3.054-42.448-7.698 11.865-15.43 19.721-22.035 25.872.127.232.277.454.358.697.539 1.637.377 3.427.826 5.088z"
      style={{
        fill: "#e56353",
      }}
    />
  </svg>
);
