import React, { SVGProps } from "react";
export const SquidIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#a363af"
      d="M49.57 28.22c-.39-.45-3.1 2.53-5.35 4.32-1.36 1.07-12.2 8.92-15.96 13.51s-5.06 12.94-1.69 17.93c5.26 7.79 12.11 9.85 12.11 9.85l15.49 13.89s4.6 9.06 8.82 12.29c2.8 2.15 6.3 4.93 12.29 4.13 7.7-1.03 13.24-8.93 15.3-12.01 2.63-3.94 5.16-9.01 8.82-10.7 3.66-1.69.38-6.95-2.72-4.32S80.82 94.75 78.1 95.41c-2.72.66-7.41.66-9.85-1.03-2.44-1.69-5.11-6.05-5.11-8.4 0-2.35.69-5.08 3.33-6.15 3.94-1.6 6.19-.75 8.54-2.16 2.11-1.26 24.03-26 24.03-26s4.22 7.04 9.39 6.76c3.62-.2 4.69-19.52 4.69-19.52s-.07-7.63-1.6-10.7c-1.36-2.75-5.27-10.1-11.26-12.29-7.41-2.72-20.27-.56-20.27-.56s-9.39 3.38-9.57 5.07c-.19 1.69.09 3.57 2.82 6.48 1.5 1.61 4.32 2.91 4.32 2.91l-22.9 22.9s-4.97 1.13-5.07 4.5c-.05 1.75.09 3.75-.84 6.19-.69 1.8-6.57 4.46-10.89 2.02-4.46-2.52-7.41-5.3-6.76-11.03s25.98-17.55 18.47-26.18z"
    />
    <path
      fill="#ee7fad"
      d="M111.33 16.58c-1.5-1.5-12.86 1.5-31.72 10.89s-29.46 23-29.94 29.09c-.47 6.01 4.04 11.36 7.51 14.36 3.47 3 10.42 8.63 16.71 7.23 6.29-1.41 22.61-16.98 29.47-32.57 6.18-14.08 9.84-27.12 7.97-29z"
    />
    <path
      fill="#ee7fac"
      d="M70.41 20.8c-.17 3.84 5.44 4.56 7.88 3.66 2.82-1.03 8.45-4.22 11.36-5.35s10.15-3.27 9.48-3.66c-1.57-.92-12.48-3.57-21.87-1.13-5.67 1.48-6.76 4.33-6.85 6.48zM112.08 29.72s-1.97 7.79-3.1 10.79c-1.13 3-4.75 11.16-4.6 13.89.19 3.38 3.38 5.73 7.41 2.63 4.04-3.1 3.66-13.14 3.38-15.3-.28-2.16-1.31-10.23-3.09-12.01zM52.39 76.18c-2.72-2.91-8.82-8.07-8.82-8.07s-4.6 7.04-9.01 7.13c-4.41.09-7.79-8.54-11.07-12.86s-8.26-5.35-11.45-4.69c-3.19.66-7.6 3.94-6.01 6.66 1.85 3.16 6.1-1.31 9.1-.75s5.26 4.41 7.04 7.51c1.78 3.1 4.66 6.29 6.38 7.7 3.28 2.67 7.7 2.06 7.7 2.06s-4.9 7.83-12.15 7.23c-7.93-.66-8.68-5.82-8.12-8.17s4.88-3.19 4.22-5.54c-.66-2.35-6.19-3.24-9.1 1.83-2.91 5.07-.14 10.65 3.61 13.75 3.75 3.1 10.46 3.94 14.69 3.38 3.96-.53 7.91-2.47 8.26-2.16.42.38-1.78 5.91-1.97 10.89-.15 3.95.67 12.28 7.04 15.11 5.82 2.58 10.84.52 12.58-1.03 1.42-1.26 2.72-5.35.19-6.48-2.42-1.07-2.67 3.66-8.35 2.91-3.69-.49-5.95-6.33-5.91-9.15.09-6.66 6.38-10 6.38-10s-.23 3.47 1.74 6.71c2.36 3.88 7.74 6.34 10.09 7.84 1.6 1.02 5.96 3.1 6.29 6.76.26 2.91-2.92 4.84-1.03 7.04 1.69 1.97 6.62-2.25 6.99-6.29s-2.58-8.07-6.99-11.17c-4.41-3.1-10.51-3.75-11.07-9.85-.32-3.45 7.79-9.01 7.79-9.01s-6.32-6.38-9.04-9.29zM67.31 93.63s3.88 2.57 7.41 1.69c6.76-1.69 10.66-9.1 12.39-11.54 2.96-4.18 8.3-10.79 13.98-13.05 6.38-2.53 12.2-1.22 15.67.47 3.47 1.69 7.41 5.54 6.01 6.85-1.41 1.31-5.94 1.66-9.81 3.1-4.18 1.55-5.48 4.1-8.49 2.91-1.78-.7-2.06-2.35-4.79-3.1-2.06-.56-5.73 1.78-8.63 5.26-3.21 3.83-7.91 9.9-13.7 10.98-5.54 1.03-9.29-1.5-10.04-3.57zM48.44 15.17c-.74 2.8-3.75 6.57-3.57 8.63.19 2.06 4.1 4.04 2.25 6.29-4.41 5.35-10.28 10.47-12.58 12.67-4.4 4.23-5.71 8.64-5.24 13.24.54 5.34 6.48 8.18 6.29 7.98-1.5-1.6-4.22-4.41-2.91-7.98 1.46-3.96 5.35-8.07 13.42-14.27s14.17-10.04 13.7-21.02-4.97-15.95-7.79-15.02c-3.24 1.08-2.16 4.13-3.57 9.48z"
    />
    <ellipse cx={69.68} cy={47.55} fill="#fea7a5" rx={2.47} ry={2.44} />
    <ellipse cx={85.52} cy={49.66} fill="#fea7a5" rx={2.16} ry={2.21} />
    <ellipse cx={80.35} cy={38.03} fill="#fea7a5" rx={1.97} ry={2.02} />
    <ellipse cx={90.68} cy={27.47} fill="#fea7a5" rx={1.97} ry={2.06} />
    <circle cx={94.53} cy={35.16} r={1.97} fill="#fea7a5" />
    <circle cx={104.52} cy={29.58} r={1.83} fill="#fea7a5" />
    <ellipse
      cx={96.5}
      cy={44.92}
      fill="#fea7a5"
      rx={1.92}
      ry={1.88}
      transform="rotate(-65.511 96.502 44.926)"
    />
    <circle cx={66.56} cy={62.19} r={2.06} fill="#fea7a5" />
    <ellipse cx={78.52} cy={61.63} fill="#fea7a5" rx={2.02} ry={2.16} />
    <path
      fill="#2d2e31"
      d="M50.32 68.29c.47 3.38-2.63 6.19-5.44 5.73-2.69-.45-4.04-2.39-4.04-5.16s1.95-4.69 4.69-4.69c2.75-.01 4.43 1.49 4.79 4.12zM65.15 82.37c.47 3.38-2.14 6.01-5.63 5.44-2.3-.38-4.13-2.39-4.13-5.16s2.04-4.5 4.79-4.5c2.74 0 4.6 1.59 4.97 4.22z"
    />
    <path
      fill="#a363af"
      d="M52.53 15.08s2.11-.56 2.21-2.53c.09-1.83-1.08-2.96-2.58-2.91-1.5.05-2.44 1.5-2.35 2.86.08 1.17 1.03 2.02 1.03 2.02s-1.59-.28-2.25 1.5c-.75 2.02.89 2.82.89 2.82s-1.92-.33-2.77 1.17c-.77 1.37-.7 2.77.99 3.89 1.17.78 3.11-.33 3.57-1.64.52-1.5-.7-3-.7-3s1.89.49 2.53-1.55c.55-1.79-.57-2.63-.57-2.63zM114.84 76.93s.09-1.78 2.35-1.97c1.37-.11 2.67.56 2.77 2.06.09 1.5-.56 2.44-1.97 2.72-1.63.33-2.72-.47-2.72-.47s-.14 1.09-1.69 1.69c-1.83.7-3-.47-3-.47s.12 1.69-1.13 2.35c-1.69.89-3.33.47-3.99-.99-.76-1.68-.09-3.1 1.41-3.71 1.97-.8 3.05.66 3.05.66s-.17-2.04 1.92-2.72c1.88-.61 3 .85 3 .85z"
    />
  </svg>
);
