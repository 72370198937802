import React, { SVGProps } from "react";

export const SoccerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#c8c8c8"
      d="M63.95 4.06c-35.52-.5-61.9 29.92-60.13 62.45 2.09 38.31 29.48 57.56 61.52 57.34 33.66-.23 59.2-24.09 58.5-60.13-.69-35.98-27.15-59.19-59.89-59.66z"
    />
    <path
      fill="#fff"
      d="M35.86 15.21C11 29.46-4.78 69.85 21.7 100.41c3.24 3.74 8.39 6.35 12.3 8.13 26.7 12.07 59.01-6.49 71.51-20.64 9.92-11.23 13.57-18.68 12.39-34.01-.91-11.92-6.01-23.43-16.58-31.94-23.9-19.28-49.67-15.8-65.46-6.74z"
    />
    <path
      fill="#171717"
      d="M60.9 39.79c-.09-.05-9.34-5.36-16.52-8.02-7.48-2.77-13-4.26-13.06-4.27l1.03-3.86c.23.06 5.75 1.54 13.42 4.39 7.5 2.78 16.74 8.08 17.13 8.31l-2 3.45zM77.167 29.354 82.61 9.932l3.852 1.08-5.442 19.421zM117.6 54.52c-.05-.02-5.37-2.04-11.31-3.65-4.47-1.21-10.23-2.44-10.29-2.45l.83-3.91c.24.05 5.92 1.26 10.5 2.5 6.15 1.66 11.47 3.69 11.7 3.77l-1.43 3.74zM86.13 93.76c0-.06-.5-6.16-1.7-12.4-1.19-6.16-3.46-11.96-3.48-12.01l3.72-1.47c.1.25 2.43 6.19 3.69 12.73 1.24 6.47 1.74 12.58 1.76 12.84l-3.99.31zM106.02 99.32l-2.64-3.01c.04-.03 3.88-3.43 8.62-9.83 4.38-5.92 6.4-11.1 6.42-11.16l3.73 1.43c-.09.23-2.24 5.75-6.94 12.1-5.01 6.78-9.02 10.32-9.19 10.47zM32.846 78.878l23.141-20.656 2.664 2.984-23.14 20.656zM12.91 73.35c-.06-.39-1.38-9.64-1.38-18.1 0-8.44.96-16.25 1.01-16.58l3.97.5c-.01.08-.97 7.9-.97 16.08 0 8.16 1.33 17.44 1.34 17.53l-3.97.57zM71.3 113.65c-.25-.03-6.35-.8-16.9-3.9-6.72-1.98-14.4-6.04-14.73-6.21l1.88-3.53c.08.04 7.65 4.05 13.98 5.91 10.2 3 16.2 3.76 16.26 3.77l-.49 3.96z"
    />
    <path
      fill="#272c2f"
      d="M53.37 32.72c-.31.78.1 28.63.73 29.46s28.63 10.34 29.88 10.03c1.25-.31 16.19-23.82 16.19-24.76S81.79 23.84 80.85 23.63c-.94-.21-27.06 8.04-27.48 9.09zM117.36 36.14s-4.12 13.71-4.12 14.76c0 1.04 2.51 6.79 3.66 13.89 1.15 7.1-.52 17.76.21 18.18.73.42 4.35.21 4.35.21s3.49-10.97 2.02-25.07c-1.47-14.11-6.12-21.97-6.12-21.97zM87.64 90.7c-.84.08-22.88 19.01-22.78 19.54.1.52 8.63 13.09 8.63 13.09s10.72-.85 20.54-6.17 13.55-10.05 13.55-10.05 1.36-12.64.84-12.74c-.51-.12-19.73-3.77-20.78-3.67zM14.09 67.09c-.57.47-3.87 7.73-4.81 11.81-.94 4.07-1.46 7.94-1.46 7.94s2.51 7.26 6.27 12.64c4.66 6.66 10.72 11.36 10.72 11.36s19.78-6.03 19.89-6.87-2.3-8.15-3.97-14.21c-1.67-6.06-1.98-12.85-2.61-13.27-.63-.42-7-2.4-12.01-4.39s-11.39-5.53-12.02-5.01zM7.89 41.88s5.47 4.73 6.31 4.73c.84 0 6.14-7.02 11.49-11.28 7.21-5.75 10.76-7.63 10.87-8.36.1-.73 3.24-18.18 3.24-18.18s-11.6 4.39-20.06 14.1S7.89 41.88 7.89 41.88zM67.05 4.13s6.67 3.43 9.1 5.12c2.43 1.69 6.01 5.27 7.07 5.38 1.05.11 15.56.12 15.56.12s-6.58-4.5-13.87-7.08c-8.77-3.11-17.86-3.54-17.86-3.54z"
    />
  </svg>
);
