import React, { SVGProps } from "react";

export const GroupIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-3 0 32 32"
    {...props}
    fill="currentColor"
  >
    <title>{"group"}</title>
    <path d="M20.906 20.75c1.313.719 2.063 2 1.969 3.281-.063.781-.094.813-1.094.938-.625.094-4.563.125-8.625.125-4.594 0-9.406-.094-9.75-.188-1.375-.344-.625-2.844 1.188-4.031 1.406-.906 4.281-2.281 5.063-2.438 1.063-.219 1.188-.875 0-3-.281-.469-.594-1.906-.625-3.406-.031-2.438.438-4.094 2.563-4.906a3.818 3.818 0 0 1 1.281-.219c1.406 0 2.719.781 3.25 1.938.781 1.531.469 5.625-.344 7.094-.938 1.656-.844 2.188.188 2.469.688.188 2.813 1.188 4.938 2.344zm-17-.937c-.5.344-.969.781-1.344 1.219-1.188 0-2.094-.031-2.188-.063-.781-.188-.344-1.625.688-2.25.781-.5 2.375-1.281 2.813-1.375.563-.125.688-.469 0-1.656-.156-.25-.344-1.063-.344-1.906-.031-1.375.25-2.313 1.438-2.719 1-.375 2.125.094 2.531.938.406.875.188 3.125-.25 3.938-.5.969-.406 1.219.156 1.375.125.031.375.156.719.313-1.375.563-3.25 1.594-4.219 2.188zm20.563-1.188c.75.406 1.156 1.094 1.094 1.813-.031.438-.031.469-.594.531-.156.031-.875.063-1.813.063a4.83 4.83 0 0 0-1.656-1.375c-1.281-.75-2.844-1.563-4-2.063.313-.125.594-.219.719-.25.594-.125.688-.469 0-1.656-.125-.25-.344-1.063-.344-1.906-.031-1.375.219-2.313 1.406-2.719 1.031-.375 2.156.094 2.531.938.406.875.25 3.125-.188 3.938-.5.969-.438 1.219.094 1.375.375.125 1.563.688 2.75 1.313z" />
  </svg>
);
