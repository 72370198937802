import React, { SVGProps } from "react";

export const BooksIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#01579b"
      d="M118.03 102.32 72.29 123c-2.82 1.33-5.76 1.2-8.46-.36L6.09 93.32c-1.65-1.06-2.14-2.61-2.04-3.69.1-1.08.35-2.25 3.25-3.09l4.28-1.58 57.92 31.57 41.16-16.82 7.37 2.61z"
    />
    <path
      fill="#f5f5f5"
      d="M71.74 119.69a7.951 7.951 0 0 1-7.26-.26L8.11 91.03c-.8-.44-1.04-1.45-.56-2.23 1.24-2.05 3.52-8.53-.24-13.91l63.66 30.65.77 14.15z"
    />
    <path
      fill="#94c6d6"
      d="m115.59 99.98-43.85 19.71c-1.45.63-4.34 1.75-7.67-.49 2.63.19 4.48-.9 5.43-2.67.93-1.72.65-4.54-.48-6.13-.69-.96-2.54-2.49-3.35-3.35L113.1 88.5c4.2-1.73 8.14.86 8.77 4.01.7 3.56-3.84 6.47-6.28 7.47z"
    />
    <path
      fill="#01579b"
      d="m117.78 86.96-45.27 20.2c-2.85 1.13-6.04.98-8.77-.4L5.9 77.38c-.56-.28-1.39-1.05-1.72-2.1-.54-1.75.14-3.95 2.19-4.65l62.68 31.95 42.92-18.37 5.81 2.75z"
    />
    <path
      fill="#0091ea"
      d="m121.19 89.89-4.93-1.79-10.16.59-33.58 14.99c-2.85 1.13-6.04.98-8.77-.4L5.9 73.91c-1.49-.76-1.17-2.97.47-3.28l41.69-18.65c1.19-.22 2.41-.09 3.52.38l59.49 28.36s9.45 6.47 10.12 9.17z"
    />
    <path
      fill="#616161"
      d="M105.53 88.98s6.26-2.45 11.18-2.23c4.92.22 6.63 3.67 6.63 3.67-.93-4.23-5.3-6.39-5.3-6.39l-65-32.73c-.45-.19-2.11-.58-4.66.47-2.06.85-8.79 4-8.79 4l65.94 33.21z"
    />
    <path
      fill="#424242"
      d="M123.62 91.22c-.47-1.87-1.63-3.87-3.77-4.84-2.82-1.27-6.84-.94-9.41.4l-4.91 2.18v3.46l6.21-2.76c6.04-2.69 8.72 1.34 8.95 2.29.96 3.87-.9 6.11-6.39 8.63l-8.92 4.02v3.48l10.26-4.57c4.54-1.82 9.72-5.24 7.98-12.29z"
    />
    <path
      fill="#01579b"
      d="M33.01 90.31 15.74 66.44l2.71-1.21 19.43 26.7zM55.16 101.31l-3.08-2.44 53.45-10.91v1.75l-7.49 2.84z"
    />
    <path
      fill="#9ccc65"
      d="M14.8 46.18 82.31 34.9l29.49 32.47c1.49 1.57.68 4.17-1.44 4.6l-69.7 14.3L14.8 46.18z"
    />
    <path
      fill="#689f38"
      d="M110.36 69.17 41.14 83.19l-.22 3.3 69.44-14.24c1.96-.41 2.78-2.65 1.71-4.23-.38.56-.96 1-1.71 1.15zM114.09 84.3c.73 1.16.07 2.69-1.27 2.96L49.1 100.18c-3.83.79-7.59-1.72-7.93-5.62-.29-3.3 1.94-6.29 5.19-6.97l61.28-13.76 6.45 10.47z"
    />
    <path
      fill="#616161"
      d="M55.59 80.1 30.21 43.78l-14.48 3.83c-3.35 3.33-2.1 8.8-2.1 8.8S35.8 91.99 39.3 96.54c3.5 4.55 8.61 3.84 8.61 3.84l8.63-1.74-.9-16.1-.05-2.44z"
    />
    <path
      fill="#424242"
      d="M55.59 80.34 43.4 82.86c-3.33.75-3.93 3.88-3.93 3.88L10.04 44.57s-4.19 5.07-1.41 9.38L39.3 96.54c3.35 4.77 8.61 3.88 8.61 3.88l8.63-1.74-.89-15.78-.06-2.56z"
    />
    <path
      fill="#b9e4ea"
      d="M110.25 83c.31.68-.09 1.47-.82 1.62L48.5 97.12c-3.83.79-6.54-1.75-6.4-5.21.18-4.37 2.63-6.22 5.87-6.89l61.23-12.51s-2.08 2.34-.49 6.72c.54 1.51 1.12 2.85 1.54 3.77z"
    />
    <path
      fill="none"
      stroke="#424242"
      strokeMiterlimit={10}
      strokeWidth={2.071}
      d="M45.21 83.7 19.1 46.76"
    />
    <path fill="#424242" d="M47.26 67.95 13.68 51.03l-1.36 2.68 38.8 19.77z" />
    <path
      fill="#689f38"
      d="m108.79 64.03-2.46-2.7L68.5 78.69 47.26 68.18l3.62 5.18 14.07 7.19 10.48-1.61z"
    />
    <path
      fill="#c62828"
      d="M118.02 57.35 72.29 78.03c-2.82 1.33-5.76 1.2-8.46-.36L6.09 48.35c-1.65-1.06-2.14-2.61-2.04-3.69s.35-2.25 3.25-3.09l2.71-1 59.32 29.11 48.17-19.93.52 7.6z"
    />
    <path
      fill="#f5f5f5"
      d="M71.73 74.72a7.951 7.951 0 0 1-7.26-.26L8.1 46.06c-.8-.44-1.04-1.45-.56-2.23 1.24-2.05 3.52-8.53-.24-13.91l62.24 31.66 2.19 13.14z"
    />
    <path
      fill="#94c6d6"
      d="M115.58 55.01 71.73 74.72c-1.45.63-4.34 1.75-7.67-.49 2.63.19 4.48-.9 5.43-2.67.93-1.72.65-4.54-.48-6.13-.69-.96-2.54-2.49-3.35-3.35l47.43-18.55c4.2-1.73 8.14.86 8.77 4.01.7 3.56-3.84 6.47-6.28 7.47z"
    />
    <path
      fill="#c62828"
      d="m117.78 41.99-45.27 20.2c-2.85 1.13-6.04.98-8.77-.4L5.89 32.41c-.6-.3-1.5-1.07-1.79-2.16-.43-1.62.13-3.75 2.26-4.59l53.01-11.23 58.41 27.56z"
    />
    <path
      fill="#f44336"
      d="m121.18 44.92-4.93-1.79-10.16.59-33.58 14.99c-2.85 1.13-6.04.98-8.77-.4L5.89 28.93c-1.49-.76-.96-2.77.47-3.28l41.7-18.64c1.19-.22 2.41-.09 3.52.38l59.49 28.36s9.44 6.46 10.11 9.17z"
    />
    <path
      fill="#616161"
      d="M105.53 44s5.21-1.83 10.13-1.61c4.92.22 7.69 3.05 7.69 3.05-1.01-4.52-5.3-6.39-5.3-6.39l-65-32.73c-.45-.19-2.11-.58-4.66.47-2.06.85-8.79 4-8.79 4L105.53 44z"
    />
    <path fill="#424242" d="M111.48 41.86 44.97 8.31l2.2-.99 67.64 33.9z" />
    <path
      fill="#424242"
      d="M123.61 46.25c-.47-1.87-1.26-3.68-3.49-4.62-2.85-1.2-5.45-1.45-9.69.18l-4.91 2.18v3.46l6.21-2.76c3.15-1.48 7.79-1.16 8.95 2.29 1.27 3.78-.9 6.11-6.39 8.63l-8.92 4.02v3.48l10.26-4.57c4.55-1.82 9.73-5.24 7.98-12.29z"
    />
  </svg>
);
