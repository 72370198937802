import React, { SVGProps } from "react";
export const TeddyBearIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#a65f3e"
      d="M113.95 98.69c-2.66-4.98-7.67-6.19-13.15-5.06-.1.02-.22.05-.34.09 1.57-2.83 2.78-5.6 2.97-10.71.45-12.58-4.74-18.76-10.12-23.33-11.15-9.47-43.38-6.35-43.88-6.33-4.14.17-9.91 2.7-13.04 5.46-6.9 6.01-12.22 14.48-12.61 23.82-.21 3.38.38 7.39 1.99 10.63-4.33-.54-8.91.95-11.77 6.05-2.32 4.13-1.66 9.19.86 13.85 3.41 6.32 11.16 12.29 18.83 10.52 7.78-1.79 14.53-9.5 21.4-13.57.49-.29 1.07-.39 1.64-.33 4.28.49 10.3.49 14.08.08.79-.08 1.57.18 2.13.73.71.7 1.42 1.36 1.68 1.6 4.31 3.97 9.24 8.26 14.72 10.45 9.28 3.7 18.49-.44 23.48-9.3 2.62-4.66 3.77-9.72 1.13-14.65z"
    />
    <path
      fill="#703b26"
      d="M84.86 105.17c-.44 0-.89-.04-1.34-.12-4.05-.75-6.46-4.78-6.75-8.38-.29-3.64 1.11-7.09 2.51-9.98.25-.52 2.85-5.29 3.55-8.53 1.05-4.89.17-9.35-.14-11.34-.31-2 1.21-1.66 1.87-.23 1.62 3.51 1.91 8.42 1.18 12.17-.67 3.43-3.54 8.71-3.79 9.23-1.23 2.54-2.46 5.52-2.23 8.45.19 2.41 1.77 5.22 4.33 5.69 2.77.51 4.99-.49 7.33-2.63l2.52.96c-1.85 1.7-5.16 4.71-9.04 4.71zM45.95 81.45c-.6-3.25-1.02-7.36 1.02-10.27l-1.49-2.87c-3.78 3.83-3.16 9.85-2.45 13.67.09.51 1.56 8.03.75 10.99-.93 3.4-3.95 6.13-7.39 6.83-1.86-2.97-4.65-5.1-7.73-6.34-3-1.21-7.31-.88-9.76.91 2.64-.73 5.72-.56 8.3.67 3.59 1.7 5.81 4.03 7.84 8.98.67 1.63 1.2 4.23 2.75 3.78.89-.25 1.59-1.15 1.25-2.58-.36-1.51-.9-2.54-.9-2.54s5.48-.94 8.51-8.93c1.55-4.1-.6-11.8-.7-12.3z"
    />
    <path
      fill="#703b26"
      d="M91.44 107.55c-.29.54-.7.96-1.29.95-.91-.01-1.49-1.08-1.5-2-.04-7.24 7.89-13.85 15.19-13.26-5.71 1.22-9.93 5.88-11.97 13.25-.1.35-.25.72-.43 1.06zM54.02 80.33l-2.2-2.39c3.6-3.43 8.51-8.15 9.36-12.41l2.91.58c-.95 4.78-5.02 10.55-8.67 14.25-.39.4-1.03.38-1.4-.03z"
    />
    <path
      fill="#ffca28"
      d="M71.39 65.14c6.05-1.32 10.47-5.03 10.75-9.66l-32.92-1.99c-.21 3.53 2.03 6.82 5.7 9.08-4.57.78-7.69 1.95-9.02 2.51-.73.31-1.21 1.02-1.21 1.82.01 3.79 4.06 11.53 6.97 12 2.88.47 9.37-7.89 11.76-11.49 0 5.6.84 15.5 2.52 16.28 3.41 1.57 14.21-4.27 14.53-7.32.25-2.39-4.54-7.07-9.08-11.23z"
    />
    <path
      fill="#a65f3e"
      d="M100.19 19.5c-.25-4.28-3.1-8.1-7.13-9.55-3.27-1.17-6.54-.76-9.17.72-1.2.67-2.64.78-3.88.16-1.92-.96-5.25-2.26-9.99-2.86-4.72-.6-8.37-.08-10.56.44-1.42.34-2.88-.16-3.91-1.2A10.994 10.994 0 0 0 47.73 4c-5.13 0-9.68 3.74-10.7 8.77-.64 3.13.11 6.17 1.79 8.53 1.46 2.07 1.75 4.78.81 7.14-.95 2.38-2.76 7.61-2.72 10.51.15 12.65 11.66 19.21 26.88 21.14s28.83-2.82 31.39-15.91c.58-2.96.42-6.83-.09-9.73-.42-2.39.37-4.86 2.06-6.61 2.02-2.1 3.24-5.03 3.04-8.34z"
    />
    <path
      fill="#874d36"
      d="M41.35 15.68c.41-3.19 3.18-5.46 6.21-5.08 1.15.15 4.6 1.5 3.42 3.3-.22.33-.55.56-.87.79a26.14 26.14 0 0 0-5.68 5.63c-.29.4-.73.85-1.16.66-1.52-.65-2.1-3.84-1.92-5.3zM95.29 22.53c.41-3.19-1.72-6.09-4.74-6.47-1.15-.15-4.83.3-4.13 2.34.13.38.39.68.64.99 1.69 2.04 3.08 4.37 4.09 6.88.18.46.49 1.01.96.93 1.63-.26 2.99-3.2 3.18-4.67z"
    />
    <path
      fill="#f29657"
      d="M61.95 33.17c-2.95 3.39-5.17 5.31-7.26 8.3-3.55 5.08-1.08 13.91 8.98 15.22 7.01.91 13.05-2.41 13.17-9.12.08-4.25-3.67-9.92-5.61-13.58-2.42-4.54-6.21-4.35-9.28-.82z"
      opacity={0.7}
    />
    <ellipse
      cx={53}
      cy={31.49}
      opacity={0.55}
      rx={4.78}
      ry={4.06}
      transform="rotate(-81.9 52.996 31.493)"
    />
    <ellipse
      cx={80.58}
      cy={35}
      opacity={0.55}
      rx={4.78}
      ry={4.06}
      transform="rotate(-81.9 80.576 34.999)"
    />
    <path
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.446}
      d="M57.59 47.22c1.9 5.61 11.26 6.83 14.61 1.87"
      opacity={0.55}
    />
    <path
      d="M67.78 47.83c-2.41.53-4.86.11-6.47-1.91-1.21-1.51-2.46-4.04-.74-5.83 2.44-2.5 8.51-1.75 10.71.73 1.25 2.11-1.22 6.53-3.5 7.01z"
      opacity={0.55}
    />
    <path
      fill="#f29657"
      d="M16.2 106.55c.03-1.83 1.41-4.49 3.13-5.13 4.1-1.55 8.56.32 11.03 5.93 2.04 4.64 2.24 9.51 1.37 10.82-1.29 1.93-3.17 1.62-4.47 1.39-2.37-.42-4.43-1.85-6.29-3.39-1.89-1.57-4.85-4.75-4.77-9.62zM111.76 106.47c-.06-1.83-1.48-4.46-3.2-5.09-4.12-1.49-8.56.44-10.94 6.09-1.97 4.67-1.75 8.96-1.48 9.87.84 2.81 3.47 2.54 4.76 2.29 2.36-.46 4.4-1.92 6.24-3.48 1.87-1.58 4.77-4.8 4.62-9.68z"
      opacity={0.7}
    />
  </svg>
);
