import React, { SVGProps, useId } from "react";

export const TvIcon = (props: SVGProps<SVGSVGElement>) => {
  const a = useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      viewBox="0 0 128 148"
      {...props}
    >
      <defs>
        <linearGradient
          id={a}
          x1={29.355}
          x2={28.31}
          y1={35.146}
          y2={73.055}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
      </defs>
      <path
        fill="#212121"
        d="M60.64 41.1 38.95 13.88c-.3-.38-.25-.93.11-1.25.38-.33.95-.3 1.28.08L63.41 38.7c.33.38.3.95-.08 1.28l-1.38 1.22c-.39.35-.99.3-1.31-.1zM66.21 40.95l-1.24-1.1c-.41-.37-.45-1-.08-1.41l22.94-25.86c.37-.41 1-.45 1.41-.08.4.35.45.96.12 1.38l-21.7 26.96c-.37.43-1.03.49-1.45.11z"
      />
      <path
        fill="#212121"
        d="M47.74 42.46c0-3.49 7.74-6.33 17.29-6.33s17.29 2.83 17.29 6.33H47.74z"
      />
      <path
        fill="#82aec0"
        d="M119.57 124H8.43C5.98 124 4 121.94 4 119.41V45.1c0-2.54 1.98-4.59 4.43-4.59h111.15c2.44 0 4.43 2.06 4.43 4.59v74.31c-.01 2.53-1.99 4.59-4.44 4.59z"
      />
      <path
        fill="#212121"
        d="M116.35 119.64H11.97a3.55 3.55 0 0 1-3.55-3.55V48.04a3.55 3.55 0 0 1 3.55-3.55h104.38a3.55 3.55 0 0 1 3.55 3.55v68.05a3.55 3.55 0 0 1-3.55 3.55z"
      />
      <path
        fill="#b9e4ea"
        d="m15.39 108.96-4.8 7.55c-.84 1.32.06 3.11 1.62 3.12h82.85c1.77-.01 2.92-1.87 2.15-3.46l-3-6.18-78.82-1.03z"
      />
      <path
        fill="#2f7889"
        stroke="#212121"
        strokeMiterlimit={10}
        strokeWidth={4}
        d="M95.45 105.47c-.77 3.47-3.34 6.15-6.64 6.88-5.42 1.21-15.52 2.72-32.16 2.72-17.47 0-29.19-1.66-35.5-2.89-3.49-.68-6.23-3.54-6.91-7.23-1.57-8.48-3.54-25.43.08-46.6.67-3.92 3.66-6.93 7.39-7.44 6.73-.93 18.75-2.17 34.94-2.17 15.36 0 25.65 1.12 31.48 2.02 3.59.56 6.46 3.47 7.19 7.24 4.33 22.3 1.94 39.29.13 47.47z"
      />
      <path
        fill="#455a64"
        d="M114.89 116.47H103.5a2.09 2.09 0 0 1-2.09-2.09V90.63c0-1.16.94-2.09 2.09-2.09h11.39c1.16 0 2.09.94 2.09 2.09v23.75c0 1.15-.93 2.09-2.09 2.09z"
      />
      <circle cx={109.2} cy={58.31} r={6.89} fill="#455a64" />
      <path
        fill="#82aec0"
        d="M109.2 51.98c-.53 0-.99.36-1.11.87l-.9 3.7a7.438 7.438 0 0 0 0 3.51l.9 3.7a1.144 1.144 0 0 0 2.22 0l.9-3.7c.28-1.15.28-2.35 0-3.51l-.9-3.7c-.13-.51-.59-.87-1.11-.87z"
      />
      <circle cx={109.2} cy={76.12} r={6.89} fill="#455a64" />
      <path
        fill="#82aec0"
        d="M104.33 72.08c-.34.41-.35.99-.04 1.41l2.27 3.05a7.42 7.42 0 0 0 2.7 2.24l3.42 1.66c.47.23 1.04.11 1.38-.3.34-.41.35-.99.04-1.41l-2.27-3.05a7.42 7.42 0 0 0-2.7-2.24l-3.42-1.66c-.47-.23-1.04-.1-1.38.3z"
      />
      <path
        fill={`url(#${a})`}
        d="M31.08 55.78c1.68-.2 4.86-.06 5.98 3.35s-1.34 4.33-2.66 5.34c-3.79 2.89-5.67 3.99-7.95 7.14-1.82 2.52-5 1.73-5.95.07-.76-1.34-1.32-5.64.29-8.48 3.47-6.08 8.61-7.22 10.29-7.42z"
      />
    </svg>
  );
};
