import React, { SVGProps, useId } from "react";
export const PlantIcon2 = (props: SVGProps<SVGSVGElement>) => {
  const a = useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      viewBox="0 0 128 128"
      {...props}
    >
      <radialGradient
        id={a}
        cx={63.917}
        cy={63.865}
        r={58.902}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.393} stopColor="#ffd655" />
        <stop offset={0.452} stopColor="#ffd450" />
        <stop offset={0.521} stopColor="#ffd042" />
        <stop offset={0.596} stopColor="#fec82b" />
        <stop offset={0.673} stopColor="#febe0a" />
        <stop offset={0.692} stopColor="#febb01" />
      </radialGradient>
      <path
        fill={`url(#${a})`}
        d="M88.49 37.39s8.02-8.17 8.31-15.63c.28-7.46-2.46-9.85-6.12-11.4S83 12.05 83 12.05s-.14-4.5-4.93-6.19-10.7 2.25-12.25 8.17-1.88 16.8-1.88 16.8S63 19.1 59.2 12.9s-7.46-7.46-12.53-5.91-4.79 7.74-4.79 7.74-4.79-4.93-10.42-2.53-3.87 11.19-3.03 13.94c.56 2.25 6.41 13.94 6.41 13.94s-8.92-6.03-16.12-7.39c-6.34-1.2-10.77 2.18-10.77 6.83s3.52 6.9 3.52 6.9-5.66 1.52-6.76 6.9c-.77 3.8.75 6.68 3.45 8.73 4.65 3.52 19.36 4.5 19.36 4.5s-10.7.7-16.19 4.08c-5.62 3.46-6.34 8.73-5.07 11.54s7.32 4.22 7.32 4.22-5.07 6.34-.99 11.4 10.14 4.93 13.94 3.1c3.8-1.83 12.81-9.71 12.81-9.71s-7.4 11.7-7.88 18.02c-.47 6.19 2.64 8.51 6.48 8.87 3.47.33 6.05-2.67 6.05-2.67s1.41 6.05 5.91 6.76 9.15-1.27 11.97-7.46c2.71-5.96 3.94-16.66 3.94-16.66s2.67 16.94 8.59 21.3c5.91 4.36 10.18 2.53 12.53.14 2.25-2.67 1.83-8.59 1.83-8.59s5.07 4.08 9.01 1.55c3.94-2.53 3.1-7.74 1.83-12.39S92 87.66 92 87.66s9.43 9.43 17.03 9.15c7.6-.28 9.44-3.48 9.85-7.32.42-3.94-2.96-7.18-2.96-7.18s6.62-.7 7.32-7.46c.7-6.76-6.34-9.01-9.29-9.85s-11.4-1.13-11.4-1.13 12.81-3.52 16.33-7.46c3.52-3.94 3.73-7.81 2.18-10.63-1.48-2.69-7.11-3.73-7.11-3.73s2.53-3.38.7-8.31c-1.83-4.93-9.43-5.07-13.37-3.8-3.92 1.25-12.79 7.45-12.79 7.45z"
      />
      <path
        fill="#f2a05b"
        d="M53.15 48.6s-3.1-6.19-5.35-13.84c-.84-2.86-3.45-11.35-2.82-11.87.73-.61 6.38 9.38 7.84 12.95 1.26 3.09 5.54 10.98 5.54 10.98l10.7-1.83s3.71-8.31 4.83-10.56c1.13-2.25 5.44-10.79 6.19-10.42.89.44-1.13 8.21-2.82 13-1.69 4.79-3.85 10.79-3.85 10.79l9.01 6.71s6.34-2.35 10-3.75c3.66-1.41 10.28-3.38 10.56-2.53.28.84-3.85 3.75-9.2 6.29s-10.14 5.3-10.14 5.3l.75 8.4s7.7 3.89 11.22 5.73c3.52 1.83 10.32 5.54 10.18 6.52-.14.99-9.85-1.92-12.39-2.63-2.53-.7-12.11-3.99-12.11-3.99l-5.35 6.9s3.43 7.93 4.55 10.46c1.13 2.53 5.16 13.19 4.46 13.61s-6.15-7.98-7.88-11.12c-1.74-3.14-7.04-11.83-7.04-11.83l-9.85 3.24s-3.24 7.18-5.21 11.26-6.48 11.31-7.18 10.89c-.7-.42 2.58-11.45 3.71-14.83s4.04-10.84 4.04-10.84L47.8 75.4s-8.17 3.38-12.95 4.93c-4.79 1.55-11.26 3.94-11.26 2.82 0-1.13 4.19-3.49 10.98-6.95 7.84-3.99 11.4-5.58 11.4-5.58l-1.41-9.57s-6.29-2.16-10.79-3.85-12.86-6.15-12.58-7.41c.28-1.27 7.6 1.41 12.25 2.82s13.66 4.08 13.66 4.08l6.05-8.09z"
      />
      <path
        fill="#ed6c31"
        d="M47.8 52.73s-5.21-5.07-6.62-6.48c-1.41-1.41-4.36-4.36-3.66-5.35s6.48 3.8 7.88 5.07c1.41 1.27 6.48 4.93 6.48 4.93l10.7-5.35s.14-4.79.28-7.18c.14-2.39 0-6.76 1.13-6.76s1.55 6.05 1.69 8.17c.14 2.11.56 6.19.56 6.19l10.84 3.52s3.52-4.08 4.65-5.35c1.13-1.27 3.66-5.07 4.79-4.22 1.13.84-3.24 6.76-4.08 8.02-.84 1.27-3.66 4.93-3.66 4.93l4.65 9.57s5.21-.14 8.17-.28 8.02 0 8.02.84-4.32 2.02-8.26 2.44-8.07.52-8.07.52l-1.64 9.57s1.92 2.53 4.69 5.02c2.47 2.22 4.46 4.88 3.89 5.73-.56.84-4.55-2.02-6.38-3.71s-5.21-4.65-5.21-4.65L67.8 84.11v6.48c0 1.41-.56 7.18-1.97 6.76s-1.27-5.77-1.27-7.46c0-2.92-.01-6.61.05-6.15l-12.29-3s-3.05 2.67-4.46 4.22c-1.41 1.55-5.26 5.35-6.24 4.22s2.25-4.5 3.66-6.05c1.41-1.55 4.79-4.93 4.79-4.93l-5.49-9.15s-5.35.14-7.04.14-7.74.56-7.74-1.13 5.35-1.69 7.88-1.83c2.53-.14 8.63-.52 8.63-.52l1.49-12.98z"
      />
      <path
        fill="#ed6c31"
        d="M44.99 54c-2.92 5.29-5.02 23.37 9.06 30.27 14.08 6.9 27.26-1.55 31.07-12.67s-2.67-22.52-13.37-26.89S49.49 45.83 44.99 54z"
      />
      <path
        fill="#f2a05b"
        d="M58.08 49.21C51.1 51.37 44.8 61.27 49.96 72.2c4.45 9.43 15.2 11.45 23.46 6.01 8.37-5.52 9.53-15.3 4.6-22.62-4.92-7.32-12.2-8.77-19.94-6.38z"
      />
      <path
        fill="#fccd87"
        d="M54.83 52.6c-1.89 2.01-2.36 4.73-1.04 6.23s4.9.8 6.5-1.41 1.6-4.63.38-5.83c-1.57-1.55-4.33-.59-5.84 1.01zM62.91 50.99c-.51 1.27-.94 4.41 1.6 5.26 2.4.8 3.43-.66 4.22-2.11.84-1.53.66-4.08-1.13-4.83-2.32-.99-4.12.28-4.69 1.68zM69.34 54.05c-.67.99-1.31 4.08 1.78 4.69 3.25.64 4.13-3.47 2.67-4.97-1.18-1.23-3.37-1.32-4.45.28zM74.74 57.99c-.62.55-1.31 2.39.19 3.47s2.77.23 3.24-.7c.27-.55.47-1.69-.38-2.53-.84-.85-2.21-.99-3.05-.24zM60.18 58.61c-.86 1.97-.43 4.44 1.67 5.08 2.43.74 4.4-.69 4.45-4 .02-1.25-.76-2.57-2.63-2.91-1.88-.34-3.05.81-3.49 1.83zM57.94 62.83c-1.61-.14-2.86 1.59-2.86 3.14 0 1.54 1.2 3.17 2.65 3.14 2.08-.05 3.12-1.29 3.07-3.34-.05-1.85-1.09-2.79-2.86-2.94zM52.26 59.16c-1.69-.05-3.38 1.36-3.33 4.18.05 2.82 1.27 4.18 2.82 4.08 1.55-.09 3.28-1.31 3.47-4.32.19-3-1.41-3.89-2.96-3.94zM51.26 68.72c-1.75 1.42-.59 3.9.39 5.24.98 1.33 3 2.62 4.58.74 1.57-1.88.39-4.2-.44-5.24-.84-1.04-2.47-2.42-4.53-.74z"
      />
      <path
        fill="#fccd87"
        d="M56.44 76.66c.35 1.71 1.5 2.39 3.1 2.35s2.49-1.74 2.44-2.91c-.05-1.17-1.03-2.55-2.72-2.67-1.98-.14-3.2 1.4-2.82 3.23z"
      />
    </svg>
  );
};
