import React, { SVGProps } from "react";
export const HelicopterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#feb804"
      d="M82.02 119.67c-.33-.05-5.51-.07-12.62-.07l-4.69-15.5-4.27 1.11 4.02 14.39c-8.29 0-18.04 0-25.81-.01l4.72-14.23H38.3l-4.64 14.22c-4.98-.01-8.42-.03-8.88-.07-1.9-.16-4.19-1.58-5.14-2.13-.95-.55-2.69-2.77-4.35-.95-1.66 1.82 1.2 4.05 3.16 5.3 2.85 1.82 6.09 1.98 7.99 1.98 1.9 0 55.03.08 55.74 0s1.66-1.19 1.66-1.98-.57-1.88-1.82-2.06z"
    />
    <path
      fill="#5e6367"
      d="M103.92 47.96c-.71-.08-42.77-.71-42.77-.71s-.97-1.51-3.6-2.23c2.13-.1 3.75-.26 3.84-.45.63-1.42-2.45-3.79-7.27-3.87-4.51-.07-7.75 2.29-7.35 4.03.06.26 2.02.38 4.49.41-2.55.79-3.7 2.28-3.7 2.28S6.28 49.07 5.09 49.15s-2.13.47-2.13 1.42.55 1.74 1.58 1.74 40-1.42 40-1.42-17.95 5.06-19.05 5.38c-1.11.32-2.53.71-2.37 1.66.16.95 1.58.87 2.45.63s25.77-6.4 25.77-6.4v6.87c-2.12-.01-3.86.05-4.11.24-.75.58-1.49 3.31-1.82 5.69-.4 2.85-.47 5.85-.47 5.85l10.91.63 4.27-12.18s-1.04-.05-2.53-.1v-6.54s26.64 6.8 27.35 6.96c.71.16 1.9.08 1.98-.63.08-.71-.08-1.34-1.03-1.74s-21.5-6.25-21.5-6.25 37.24 0 38.9-.08c1.66-.08 2.13-.55 2.13-1.5 0-.94-.79-1.34-1.5-1.42z"
    />
    <path
      fill="#ffa62d"
      d="m16.64 89.07-2.21 2.06-3 4.27s-1.5 3.95 3.32 6.96c4.82 3 10.91 5.3 31.94 4.82 21.03-.47 30.2-4.51 34.15-9.41 3.95-4.9 4.27-9.17 4.27-9.17l6.56-6.32 14.31.24s5.38 14.71 5.53 15.02c.16.32 3.87.32 3.87.32l2.77-7.83s-.4-8.78-.16-9.72c.24-.95 7.27-22.93 7.12-23.72s-.31-.83-.86-.86c-.55-.04-1.98.04-1.98.04l-14.4 15.33-1.14 2.61-2.87.47-20.98-1.74s0-1.8-.53-3.41c-.53-1.6-1.94-4.28-1.94-4.28l-29.35 1.91-2.05 3.17-9.82 5.08-22.55 14.16z"
    />
    <path
      fill="#ffd61c"
      d="M114.24 55.7c-.2.34-6.36 15.4-6.36 15.4l7.72 19.95s-.1.74-.93.92-2.56-1.07-2.56-1.07l2.69 6.94s6.6 0 6.66-.24-4.53-21-4.53-21.47 6.01-20.6 6.01-20.6-8.53-.13-8.7.17zM76.55 74.82s2.98 1.85 4.76 3.99c1.57 1.88 3.57 5.06 3.57 5.06l6.61-5.24 15.6.71s-.16-3.01-.3-3.69c-.18-.89-.6-2.02-.6-2.02l-23.34-1.61-6.3 2.8zM51.06 66.66s4.94.69 13.4 1.19c7.09.42 12.33.77 14.53-.36 1.52-.78 1.97-1.61 1.97-1.61s-2.2-4.7-3.1-5.24c-.89-.54-12.02-1.88-17.74-1.37-4.77.42-9.06 7.39-9.06 7.39zM81.69 83.76s-9.03-14.33-31.76-13.91c-22.72.43-26.78 17.49-26.78 17.49l8.1 2.49s.43 2.27-.75 2.56c-1.17.29-11.38.23-12.9.18-2.68-.1-2.82-1.96-2.82-1.96s-1.46 1.11-2.22 2.32c-.76 1.21-1.15 2.46-1.15 2.46s.74 2.19 4.63 3.85c3.89 1.67 14.97 3.9 30.66 3.8 15.69-.1 29.01-3.75 32.76-9.77 3.76-6 2.23-9.51 2.23-9.51zM47 92.38c-.82.65-8.23.92-9.4-.14-.94-.85.07-2.66.07-2.66l3.28-2.05s6.72.78 6.75 1.88c.02.94-.12 2.51-.7 2.97zm16.48.37c-1.29.41-6.69.53-7.86.41-1.17-.12-2.11-.09-2.7-1.15-.59-1.06-.23-2.84-.23-2.84l12.92-2.44s.01 2.08-.12 3.44c-.12 1.18-.94 2.24-2.01 2.58z"
    />
    <path
      fill="#5e6367"
      d="M41.55 75.6c-.91.77-4.45 13.73-3.88 13.98.56.25 9.49.31 10.03-.18s1.74-13.64 1.34-14.04c-.4-.39-6.75-.39-7.49.24zM53.84 75.88c-.46.34-1.33 13.48-1.14 14 .24.66 2.63.51 6.12.51s6.63-.69 6.86-2.86c.26-2.45.11-6.4 0-8.06s-1.83-3.03-4.29-3.49c-1.86-.33-6.94-.56-7.55-.1zM30 94.46c-.48-.56-6.98-.24-7.26 4.63-.17 2.97 3.49 4.74 4.29 4.69s1.37-1.89 2.17-4.46c.68-2.2 1.14-4.46.8-4.86z"
    />
    <path
      fill="#868686"
      d="M16.22 89.66c.23.91 14.35.75 15.03.34.57-.34 5.83-17.09 5.6-17.43s-5.49.4-13.2 6.86c-4.75 3.98-7.65 9.32-7.43 10.23z"
    />
    <path
      fill="#c9c7c4"
      d="M42 81.66c.99.29 1.89-1.71 2.8-2.17s2.33-1.14 2.29-2c-.06-1.03-2.92-1.83-4.06-.91S40.46 81.2 42 81.66zM55.44 77.66c-.8.86-1.03 4.46.17 4.69 1.2.23 1.77-1.37 2.8-2.12 1.03-.74 2.59-1.21 2.52-2.52-.06-1.02-4.06-1.6-5.49-.05z"
    />
    <path
      fill="#c8c8c8"
      d="M32.97 75.66c-.78-.71-5.08 1.89-8.12 4.74C22 83.09 19.2 86.65 20 87.72c1.31 1.77 4.12-2.86 7.26-5.66 1.51-1.35 7.03-5.2 5.71-6.4z"
    />
    <path
      fill="#999a9d"
      d="M107.58 63.24c-1.45.31 0 6.77.5 8.91.5 2.15 1.93 5.23 1.93 5.23l2.97-1.38s-.94-4.84-1.93-7.65-2.21-5.39-3.47-5.11zM110.71 79.92s-.04 2.31 1.15 5.79c1.15 3.4 2.68 6.35 4.02 5.93 1.24-.39.38-5.52-.23-7.71-.61-2.19-1.96-4.98-1.96-4.98l-2.98.97z"
    />
    <path
      fill="#5e6367"
      d="M110.93 75.38c-2.31.87-2.17 2.75-1.66 3.96.37.88 1.89 2.12 3.67 1.32 1.38-.62 2.24-1.84 1.55-3.73-.44-1.23-1.73-2.24-3.56-1.55z"
    />
  </svg>
);
