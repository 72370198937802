import React, { SVGProps } from "react";
export const AirplaneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#006ca8"
      d="M37.08 80.79S9.61 74.92 9.14 75.01c-.47.09-5.97 5.59-5.78 6.44C3.55 82.31 27.61 95 27.61 95l6.91-7.29 2.56-6.92zM33.29 100.21s13.35 23.11 13.92 23.11 6.72-4.74 6.72-5.21c0-.47-5.68-28.03-5.68-28.03l-9.76 1.14-5.2 8.99z"
    />
    <path
      fill="#9a9a9a"
      d="M22.79 25.78s-1.92 2.17-2.33 2.94-.48 2.09.4 2.56c.88.47 1.95.15 2.48-.44s3.14-3.8 3.14-3.8-3.46-1.44-3.69-1.26zM36.81 33.63s-1.55 1.84-2.18 2.68c-.61.81-.6 2.08.25 2.68 1.22.86 2.43-.1 3.09-.81.61-.66 2.33-2.68 2.33-2.68l-3.49-1.87zM50.08 40.77s-2.03 2.03-2.63 2.84c-.67.89-.82 2.19.1 2.89 1.01.76 2.13.41 3.04-.41.91-.81 2.94-3.04 2.94-3.04l-3.45-2.28zM85.11 75.05s-2.38 1.65-2.97 2.29c-.58.64-.97 1.92-.22 2.59.74.67 1.86.61 2.52.17.66-.44 3.96-2.93 3.96-2.93s-3.02-2.23-3.29-2.12zM93.13 87.61s-1.97 1.38-2.79 2.03c-.8.63-1.11 1.86-.44 2.66.95 1.14 2.38.53 3.19.01.76-.48 2.94-2 2.94-2l-2.9-2.7zM100.47 100.45s-2.45 1.48-3.24 2.12c-.86.71-1.32 1.93-.59 2.83.8.98 1.97.9 3.05.34s3.58-2.24 3.58-2.24l-2.8-3.05zM57.27 23.73s-.35-2.33 1.54-4.96c1.89-2.63 8.16-8.77 9.13-8.82s3.29 1.18 5.62 3.47c2.22 2.17 3.25 4.96 3.29 5.62.04.66-4.34 5-5.48 6.05-1.14 1.05-4.21 4.47-4.21 4.47l-9.89-5.83z"
    />
    <path
      fill="#c8c8c8"
      d="M60.13 24.08s7.68-9.39 9.74-7.98c2.08 1.42-6.23 9.48-6.23 9.48l-3.51-1.5z"
    />
    <path
      fill="#848484"
      d="M58.37 23.48s4.35-4.41 4.83-5.11c.48-.7.04-1.75-.35-2.33-.39-.57-.99-.79-.99-.79s-3.07 2.75-4.12 5.16c-.82 1.87-.68 2.82-.68 2.82l1.31.25zM65.86 25.75s1.65-1.71 2.48-2.39c.75-.61 1.86-.55 2.52-.16.85.5 1.05 1.36 1.05 1.36l-3.06 3.25-2.99-2.06z"
    />
    <path
      fill="#9a9a9a"
      d="M101.96 59.45s2.51-2.92 3.97-4.38c1.51-1.51 3.97-3.56 4.47-3.52.5.05 2.71 1.39 4.79 3.52 2.37 2.42 3.84 5.43 3.88 5.71.05.27-4.38 4.7-6.35 6.39-1.41 1.21-10.18 4.79-10.18 4.34.02-.46-.58-12.06-.58-12.06z"
    />
    <path
      fill="#c8c8c8"
      d="M103.41 65.1s7.26-7.96 8.92-6.47c1.66 1.49-8.31 10.45-8.31 10.45l-.61-3.98z"
    />
    <path
      fill="#848484"
      d="m102.87 63.1 2.65-2.92c.94-1.03.88-2.41.6-3.01-.36-.77-1.29-.95-1.29-.95l-3.45 3.67 1.49 3.21zM105.38 69.71s4.16-3.75 4.87-4.34c.71-.59 1.86-1.01 2.77-.38.68.47.98 1.04.98 1.04s-3.2 3.48-5.13 4.71c-1.21.77-4.07 1.21-4.07 1.21l.58-2.24z"
    />
    <path
      fill="#006ca8"
      d="M11.65 8.92c-.98.08-7.19 7.42-7.41 7.96s0 1.42 1.53 2.18c1.52.76 44.47 23.44 45.13 23.98.65.55 9.16 10.03 9.16 10.03s21.04-14.5 20.93-14.93c-.11-.44-.76-9.48-.76-9.48S13.07 8.81 11.65 8.92zM73.03 65.83s12.86 11.66 13.95 13.08c1.09 1.42 23.33 41.97 23.66 42.62.33.65 1.74 1.2 2.51.44s6.54-5.78 6.65-6.98c.11-1.2-21.37-70.42-21.37-70.42l-16.57 6-8.83 15.26z"
    />
    <path
      fill="#014eac"
      d="M66.81 43.26s-10.14-9.81-10.9-10.36c-.76-.54-48.29-20.16-48.29-20.16l-1.95 2.15s48.18 20.35 48.95 20.89c.77.54 10.2 10.2 10.2 10.2l1.99-2.72zM82.4 60.45s11.17 13.72 11.84 14.61 20.26 45.65 20.26 45.65l1.95-1.82S96.88 73.22 96.53 72.6c-.35-.62-13.36-16.7-13.36-16.7l-.77 4.55z"
    />
    <path
      fill="#9a9a9a"
      d="M24.22 98.4s-4.18 5.98-4.18 6.45c0 .48 2.8 3.54 3.12 3.54s6.4-4.34 6.4-4.34l-.85-4.34-4.49-1.31z"
    />
    <path
      fill="#c8c8c8"
      d="M24.22 98.4s8.28-13.93 17.15-26.38c9.11-12.79 16.5-20.16 27.86-32.52C83.48 24 92.97 15.22 101.75 9.31c7.28-4.9 17.68-8.22 21.39-4.91 4.07 3.64-.42 15.12-4.99 21.66-10.37 14.84-19.27 21.9-29.48 31.81-10.07 9.77-18.58 18.25-29.12 26.16-7.1 5.33-30 20.04-30 20.04l-5.33-5.67z"
    />
    <path
      fill="#3b5361"
      d="m103.92 11.05 2.77 2.92s4.64-4.47 8.11-1.14c3.56 3.41-1.35 8.25-1.35 8.25l2.99 3.34s8.11-9.18 1.49-15.15c-5.76-5.2-14.01 1.78-14.01 1.78z"
    />
    <path
      fill="#9a9a9a"
      d="M47.84 74c-1.29 1.24-16.53 19.28-17.38 20.35-1.09 1.38-1.59 3.04-.36 4.06 1.23 1.01 2.56.01 3.77-.8 1.52-1.01 19.18-16.59 20.35-17.67 1.88-1.74 2.2-4.88.22-6.66-2.18-1.96-4.64-1.16-6.6.72z"
    />
    <path
      fill="#dfdfdf"
      d="M47.29 69.91c-.87-.75-1.95-.25-3.09.98-.98 1.07-1.43 2.73-.45 3.4.98.67 2.28-.31 3.09-1.3.81-.98 1.43-2.23.45-3.08zM50.11 64.09c-.9 1.03-2.21 2.73-.98 3.94 1.21 1.19 3.04-.32 3.84-1.22.79-.9 9.84-11.52 21.29-22.96 11.18-11.18 18.7-18.25 19.86-19.49.98-1.05.89-2.72.03-3.25-1.02-.63-2.15-.23-3.55 1.17S79.7 32.37 70.74 41.43 51.23 62.8 50.11 64.09z"
    />
    <path
      fill="#3b5361"
      d="M105.47 41.61s-.55-3.72-2.86-2.26c-2.48 1.57-.63 5.83-.63 5.83s.93-.87 1.92-1.91c.82-.85 1.3-1.35 1.57-1.66zM96.6 50.36s-.39-4.77-3.01-2.81c-2.09 1.57-.67 6.26-.67 6.26s1.2-1.09 1.98-1.83c.79-.75 1.7-1.62 1.7-1.62zM88.14 58.38s-.3-3.96-2.65-2.65-.78 5.99-.78 5.99 1.04-.94 1.74-1.64 1.69-1.7 1.69-1.7zM78.56 67.64s-.18-3.94-2.49-2.89c-2.4 1.09-.96 6.14-.96 6.14s1.17-1.09 1.82-1.7c.66-.6 1.63-1.55 1.63-1.55z"
    />
  </svg>
);
