import React, { SVGProps } from "react";
export const ScissorsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#82aec0"
      d="M36.61 123.93 85.17 54.6l-7.99-15.61c-6.69 4.63-21.21 14.68-23.41 16.07-.59.37-1.04.9-1.3 1.54 0 0-16.48 42.97-19.16 49.93-2.91 7.57-2.96 10.64-2.21 12.9.65 1.99 5.51 4.5 5.51 4.5z"
    />
    <path
      fill="#de3e31"
      d="M112.73 5.68c-10.25-4.65-22.88 1.09-28.21 12.83-2.13 4.69-2.17 10.11-2.15 12.14.03 2.8-4.29 6.87-5.66 8.08-.24.21-.29.56-.13.83l8.4 14.64c.22.38.73.45 1.05.15l1.68-1.6c4.65-3.59 8.96-3.37 10.63-3.13 9.08 1.29 18.79-4.35 23.29-14.26 5.34-11.74 1.35-25.03-8.9-29.68zm-15.44 34c-6.15-2.79-8.54-10.76-5.35-17.8 3.2-7.04 10.77-10.49 16.92-7.7 6.15 2.79 8.54 10.76 5.35 17.8-3.19 7.05-10.77 10.5-16.92 7.7z"
    />
    <circle cx={64} cy={62.87} r={4.55} fill="#a8e3f0" />
    <path
      fill="#2f7889"
      d="m49.17 65.19 11.48 24.42 3.82-5.46L49.9 63.29zM77.82 65.09l2.61-3.72-1.89-5.18c-.68-2-.97-3.01-2.27-3.63l-11.53-4.98-1.24.86 14.32 16.65z"
    />
    <path
      fill="#82aec0"
      d="M91.39 123.93 42.83 54.6l7.72-15.8C57.11 43.34 72 53.65 74.23 55.05c.59.37 1.04.9 1.3 1.54 0 0 16.48 42.97 19.16 49.93 2.91 7.57 2.96 10.64 2.21 12.9-.65 2-5.51 4.51-5.51 4.51z"
    />
    <path
      fill="#b9e4ea"
      d="m60.05 76.15-3.02-1.29 34.36 49.06s3.7-3.95-1.02-11.23L69.41 83.96a21.214 21.214 0 0 0-9.36-7.81z"
    />
    <circle cx={64} cy={62.87} r={4.55} fill="#b9e4ea" />
    <path
      fill="#de3e31"
      d="M6.36 35.36c4.5 9.92 14.21 15.55 23.29 14.26 1.67-.24 5.97-.46 10.63 3.13l1.68 1.6c.32.3.83.23 1.05-.15l8.4-14.64c.16-.28.11-.62-.13-.83-1.36-1.21-5.69-5.28-5.66-8.08.02-2.03-.02-7.45-2.15-12.14C38.15 6.77 25.52 1.03 15.27 5.68S1.03 23.62 6.36 35.36zm7.43-3.37c-3.2-7.04-.8-15.01 5.35-17.8 6.15-2.79 13.72.65 16.92 7.7 3.2 7.04.8 15.01-5.35 17.8-6.15 2.79-13.73-.66-16.92-7.7z"
    />
    <path
      fill="#ff7555"
      d="M92.67 10.75c-.6.71-.95 1.76-.38 2.65.63.99 3.45 1.1 9.37-3.02 1.65-1.15 1.23-2.83-.8-3.1-2.93-.45-6.39 1.17-8.19 3.47zM17.71 11.7c-2.5 1.43-3.56 2.27-5.58 5.62-.63 1.04-2.06 3.73-3.49 2.51-.94-.8-.87-2.65-.28-4.33 1.21-3.41 3.29-5.91 6.21-7.39 1.05-.53 3.63-1.36 5.06-.8 2.8 1.09.28 3.14-1.92 4.39z"
    />
  </svg>
);
