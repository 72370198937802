import React, { SVGProps } from "react";
export const MusicIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#82aec0"
      d="M117.39 124H10.61A6.61 6.61 0 0 1 4 117.39V16h120v101.39a6.61 6.61 0 0 1-6.61 6.61z"
    />
    <path
      fill="#fff"
      d="M27.7 116.35H12.87a5.06 5.06 0 0 1-5.06-5.06v-85.8h24.95v85.8c0 2.8-2.27 5.06-5.06 5.06zM56.84 116.35H42.02a5.06 5.06 0 0 1-5.06-5.06v-85.8H61.9v85.8c0 2.8-2.26 5.06-5.06 5.06zM85.98 116.35H71.16a5.06 5.06 0 0 1-5.06-5.06v-85.8h24.95v85.8a5.068 5.068 0 0 1-5.07 5.06zM115.13 116.35H100.3a5.06 5.06 0 0 1-5.06-5.06v-85.8h24.95v85.8c0 2.8-2.27 5.06-5.06 5.06z"
    />
    <path d="M124 8.47C124 6 122 4 119.53 4H8.47C6 4 4 6 4 8.47V28h22.54v52.4c0 1.21 1.15 2.2 2.56 2.2h11.5c1.42 0 2.56-.98 2.56-2.2V28h12.51v52.4c0 1.21 1.15 2.2 2.56 2.2h11.52c1.42 0 2.56-.98 2.56-2.2V28h12.51v52.4c0 1.21 1.15 2.2 2.56 2.2h11.5c1.42 0 2.56-.98 2.56-2.2V28H124V8.47z" />
    <path
      fill="#757575"
      d="M4 4h120v6.65H4zM97.42 80.25h-8.55c-.84 0-1.52-.68-1.52-1.52v-7.21c0-.84.68-1.52 1.52-1.52h8.55c.84 0 1.52.68 1.52 1.52v7.21c-.01.84-.69 1.52-1.52 1.52zM68.27 80.25h-8.55c-.84 0-1.52-.68-1.52-1.52v-7.21c0-.84.68-1.52 1.52-1.52h8.55c.84 0 1.52.68 1.52 1.52v7.21c0 .84-.68 1.52-1.52 1.52zM39.13 80.25h-8.55c-.84 0-1.52-.68-1.52-1.52v-7.21c0-.84.68-1.52 1.52-1.52h8.55c.84 0 1.52.68 1.52 1.52v7.21c0 .84-.68 1.52-1.52 1.52z"
    />
  </svg>
);
