export const sayingClues = [
  "A dime a dozen",
  "A leopard can't change its spots",
  "A penny for your thoughts",
  "Actions speak louder than words",
  "Add insult to injury",
  "All bark and no bite",
  "All hat and no cattle",
  "An arm and a leg",
  "At the drop of a hat",
  "At the end of your rope",
  "Back to the drawing board",
  "Barking up the wrong tree",
  "Beat around the bush",
  "Beating around the bush",
  "Better late than never",
  "Bite off more than you can chew",
  "Bite the bullet",
  "Biting the hand that feeds you",
  "Break the ice",
  "Burning the midnight oil",
  "Call it a day",
  "Caught between a rock and a hard place",
  "Chip on your shoulder",
  "Cost an arm and a leg",
  "Cry over spilt milk",
  "Cut corners",
  "Cut the mustard",
  "Cut to the chase",
  "Don't count your chickens before they hatch",
  "Don't put all your eggs in one basket",
  "Down to the wire",
  "Easy does it",
  "Every cloud has a silver lining",
  "Find your feet",
  "Fit as a fiddle",
  "Get a taste of your own medicine",
  "Get out of hand",
  "Get something out of your system",
  "Get your act together",
  "Give the benefit of the doubt",
  "Go back to the drawing board",
  "Go on a wild goose chase",
  "Hit the books",
  "Hit the hay",
  "Hit the nail on the head",
  "Hit the sack",
  "In the heat of the moment",
  "It takes two to tango",
  "Jump on the bandwagon",
  "Keep your chin up",
  "Kick the bucket",
  "Kill two birds with one stone",
  "Last straw",
  "Let sleeping dogs lie",
  "Let the cat out of the bag",
  "Make a long story short",
  "Make a mountain out of a molehill",
  "Miss the boat",
  "Not a spark of decency",
  "Off the hook",
  "On the ball",
  "On the fence",
  "Once in a blue moon",
  "Out of the blue",
  "Piece of cake",
  "Pull someone's leg",
  "Pull yourself together",
  "Put the cart before the horse",
  "Rain on someone's parade",
  "Run out of steam",
  "See eye to eye",
  "Sit on the fence",
  "Speak of the devil",
  "Spill the beans",
  "Steal someone's thunder",
  "Take it easy",
  "Take with a grain of salt",
  "The ball is in your court",
  "The best of both worlds",
  "The bigger they are, the harder they fall",
  "The early bird gets the worm",
  "The elephant in the room",
  "The last straw",
  "The whole nine yards",
  "There's no such thing as a free lunch",
  "Throw in the towel",
  "Time flies when you're having fun",
  "To add fuel to the fire",
  "Under the weather",
  "Up in the air",
  "Use your loaf",
  "When it rains, it pours",
  "When pigs fly",
  "You can't have your cake and eat it too",
  "Your guess is as good as mine",
];
