import React, { SVGProps } from "react";

export const FabricIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={800}
    height={800}
    viewBox="0 0 512.003 512.003"
  >
    <path
      d="M300.139 414.897V97.103H17.657V512h379.586c-53.407 0-97.104-43.697-97.104-97.103"
      style={{
        fill: "#bc5949",
      }}
    />
    <path
      d="m101.647 245.914-9.084-15.139L313.254 98.36l9.084 15.139-220.691 132.415zM172.199 263.612l-8.951-15.228 150.069-88.276 8.951 15.228-150.069 88.276zM317.794 229.517l-79.448 44.138"
      style={{
        fill: "#9c3f33",
      }}
    />
    <path
      d="M397.243 512c-53.407 0-97.103-43.697-97.103-97.103V97.103C300.139 43.697 343.836 0 397.243 0s97.103 43.697 97.103 97.103v317.793c0 53.407-43.696 97.104-97.103 97.104"
      style={{
        fill: "#ef705d",
      }}
    />
    <path
      d="M494.346 414.897c0 53.628-43.476 97.103-97.103 97.103s-97.103-43.476-97.103-97.103 43.476-97.103 97.103-97.103 97.103 43.475 97.103 97.103"
      style={{
        fill: "#9c3f33",
      }}
    />
    <path
      d="M450.208 414.897c0 29.255-23.711 52.966-52.966 52.966s-52.966-23.711-52.966-52.966 23.711-52.966 52.966-52.966 52.966 23.711 52.966 52.966"
      style={{
        fill: "#bc5949",
      }}
    />
    <path
      d="m17.657 176.253 131.919-79.148h-34.322l-97.598 58.562v20.586zM17.657 210.46v10.231l4.467 7.609L245.929 97.105h-34.895L17.657 210.46z"
      style={{
        fill: "#9c3f33",
      }}
    />
    <path
      d="M17.657 211.595v18.194l280.346 70.082 2.136-8.563v-9.092L17.657 211.595zM17.657 284.1 227.1 343.942l4.838-16.984L17.657 265.73v18.37zM17.657 335.083l138.567 44.094 5.35-16.834-143.917-45.789v18.529z"
      style={{
        fill: "#9c3f33",
      }}
    />
    <path
      d="M295.597 283.742 17.66 450.504v20.595l282.483-169.49v-10.302l-4.546-7.565z"
      style={{
        fill: "#9c3f33",
      }}
    />
    <path
      d="m17.657 396.156 67.346 26.942 6.55-16.402-73.896-29.555v19.015zM300.139 340.721 17.657 506.079v5.923h24.832L300.14 361.183v-20.462zM300.139 402.208 108.007 511.996h35.575l156.937-89.671c-.194-2.463-.38-4.926-.38-7.433v-12.684z"
      style={{
        fill: "#9c3f33",
      }}
    />
  </svg>
);
