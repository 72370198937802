import React, { SVGProps } from "react";
export const PigIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#ffd3b0"
      d="M37.48 23.4s9.76-5.07 26.28-5.07 27.97 4.13 27.97 4.13 1.88-4.5 4.69-6.01 7.7-2.25 11.83-.56 14.45 12.67 15.02 13.61c.56.94.96 2.3.77 2.67-.19.38-10.07 16.12-10.07 16.12s1.5 3.64 2.96 8.73c.62 2.18 4.83 13.49 2.58 27.01s-11.97 27.96-29.28 32.66c-17.27 4.69-44.11 5.63-61-2.82C12.85 105.69 7.45 93.04 6.7 79.9s4.5-24.14 4.5-24.14l6.38-8.7S4.18 33.8 3.81 32.68c-.38-1.13 1.2-3.26 6.45-7.77s10.11-8.52 13.26-9.55c3.24-1.06 6.83-.59 9.08 1.48 2.25 2.06 4.88 6.56 4.88 6.56z"
    />
    <path
      fill="#ffa5a8"
      d="M93.51 25c-1.87 1.09.3 5.11 1.31 7.51 1.78 4.22 4.61 12.58 9.29 17.83 5.44 6.1 12.39 5.44 12.39 5.44s-1.41-4.79-1.97-5.91c.09-.84 4.04-7.04 5.16-9.2 1.13-2.16 3.47-5.82 3.94-7.7.47-1.88-.66-1.31-1.31-1.03-.66.28-13.89 8.17-13.89 8.17s-3.19 1.22-4.22.09-2.91-6.29-4.32-9.67c-.8-1.92-3-7.5-6.38-5.53z"
    />
    <path
      fill="#fd7e89"
      d="M104.12 40.69c.56 1.69 2.89 1.9 5.21 1.34 3.4-.82 5.52-3.12 7.67-4.72 2.96-2.18 5.91-2.82 6.62-3.59.7-.77.99-2.96-.63-2.53-1.62.42-3.66.63-6.97 2.82-2.85 1.88-5.22 3.91-7.25 4.93-1.84.91-5.21.08-4.65 1.75z"
    />
    <path
      fill="#ffa5a8"
      d="M25 39.5c.35-.42 3.94-9.01 4.72-10.7.77-1.69 2.96-6.34 5.42-4.29s-.63 10-3.8 15.56-6.69 10.7-9.08 12.88c-1.37 1.25-3.94 2.96-6.83 3.24-1.69.17-4.58.21-4.58.21s.77-2.25 1.69-4.15c.31-.65 1.06-2.18 1.34-2.82 0-.49-2.18-3.59-3.66-5.84-.82-1.25-5.92-9.58-5.99-10.57-.07-.99 1.2-.56 2.53-.28s10.21 4.65 10.21 4.65L25 39.5z"
    />
    <path
      fill="#fd7c88"
      d="M15.49 38.93c2.97 1.6 4.52 2.75 7.25 2.39 2.18-.28 3.03-1.41 2.67-2.25-.35-.84-1.76-.56-2.75-.77-2.28-.49-4.12-1.89-5.91-2.82-2.46-1.27-5.07-2.96-8.02-3.52-2.49-.47-5.49-.42-4.79 1.2.7 1.62 3 1.4 5.21 2.46 2.19 1.06 4.51 2.33 6.34 3.31z"
    />
    <path
      fill="#312f2c"
      d="M36.61 88.28c-1.61 1.73-.28 3.95 2.11 5.7 2.89 2.11 8.17 6.19 23.72 6.19 16.4 0 21.93-5.21 23.83-6.62 1.71-1.27 3.77-3.45 2.15-5.21-1.62-1.76-5.77 1.34-6.9 1.97-1.13.63-9.78 5.14-18.72 5-7.25-.11-14.78-2.53-17.03-3.45-4.16-1.68-7.26-5.62-9.16-3.58z"
    />
    <path
      fill="#fd7e84"
      d="M63.36 69.41c-7.81-.38-19.36 5.21-19.22 15.28.14 10.07 9.71 11.88 18.86 12.11 8.59.21 18.79-3.38 18.72-12.39-.05-8.18-6.89-14.43-18.36-15z"
    />
    <path
      fill="#2c2f31"
      d="M95.81 69.06c0 4.2-2.25 8.09-6.02 8.02-3.56-.07-6.09-3.4-6.09-7.6 0-4.2 2.88-7.6 6.09-7.6s6.02 2.98 6.02 7.18zM42.67 69.34c0 4.2-2.11 7.53-5.88 7.6-3.84.07-6.23-3.19-6.23-7.39 0-4.2 2.99-7.6 6.19-7.6s5.92 3.19 5.92 7.39zM73.85 83.69c0 4.2-1.01 8.04-3.52 8.09-2.96.07-3.52-3.69-3.52-7.88s.99-7.32 3.52-7.25c2.59.07 3.52 2.84 3.52 7.04zM59.69 83.34c0 4.2-1.01 8.04-3.52 8.09-2.96.07-3.52-3.69-3.52-7.88s.99-7.32 3.52-7.25c2.59.07 3.52 2.84 3.52 7.04z"
    />
  </svg>
);
