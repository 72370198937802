import React, { SVGProps, useId } from "react";
export const BurgerIcon = (props: SVGProps<SVGSVGElement>) => {
  const a = useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      viewBox="0 0 128 128"
      {...props}
    >
      <path
        fill="#fcb745"
        d="M13.91 89.87s-.96 6.28 1.27 11.82c1.86 4.64 6.68 10.75 15.9 15.41s19.81 6.3 35.44 6.62c15.63.33 31.69-3.32 39.4-10.09 7.72-6.78 10.42-12.48 10.2-18.67-.22-6.19-3.51-8.54-3.51-8.54l-98.7 3.45z"
      />
      <path
        fill="#e78b20"
        d="m18.38 83.56-4.56 4.56s-1.24 6.1 5.64 12.7c5.21 4.99 14.43 13.66 45.05 13.89 28.66.22 37.67-6.08 43.2-10.53 5.54-4.45 7.62-12.18 7.07-14.35-.54-2.17-2.24-3.42-2.24-3.42l-94.16-2.85z"
      />
      <path
        fill="#ffd290"
        d="M20.33 90.07s-1.29 2.19-.87 3.58c.33 1.09.98 5.54 13.89 11.18 6.83 2.99 18.45 5.43 33.54 4.99 17.9-.52 32.24-4.12 39.18-10.64 7.11-6.67 5.75-10.64 5.75-10.64l-91.49 1.53z"
      />
      <path
        fill="#6d544d"
        d="M16.64 74.87s-4.28 4.36-4.99 6.3c-.67 1.83-.58 4.49.6 6.64 1.59 2.88 3.57 3.18 6.02 4.65 2.65 1.58 6.84 6.51 17.15 8.68 10.31 2.17 23.88 3.15 31.15 3.15s16.93-.11 25.94-3.15 19.32-9.01 21.71-14.33c2.39-5.32.11-8.47.11-8.47l-5.21-3.47H16.64z"
      />
      <path
        fill="#9b7169"
        d="M14.14 69.34s-3.32-.15-4.12 4.88c-.58 3.66 2.06 5.97 2.06 5.97s-1.4 2.01.22 4.56c1.52 2.39 3.15 2.93 6.51 3.47 2.42.39 3.69-.33 6.84 1.41 3.95 2.18 5.64 6.08 11.07 7.27s11.51.11 11.72-.76-7.6-4.12-21.71-12.05c-4.76-2.67-9.12-6.3-10.31-8.14-1.19-1.84-2.28-6.61-2.28-6.61zM82.42 95.82c0 1.41 4.67.76 6.84-.43s5.64-4.23 8.79-5.54c3.15-1.3 4.34-.11 8.9-2.28 4.56-2.17 7.06-7.27 7.06-7.27s2.22-2.74 2.4-4.66c.28-2.93-.01-4.35-2.07-5-2.06-.65-6.55 6.1-7.2 6.86-.65.76-10.27 7.79-14.07 10.5s-10.65 7.06-10.65 7.82z"
      />
      <path
        fill="#ffe869"
        d="M18.38 65.65s-5.43.65-6.19 2.93c-.76 2.28 1.63 5.64 7.92 9.88 5.16 3.47 22.84 11.82 29.85 16.17 8.03 4.99 14.83 11.96 15.96 12.05 1.41.11 11.2-10.29 24.75-19.43 12.88-8.7 21.71-10.85 24.86-17.26 1.69-3.44-2.39-5.43-2.39-5.43L60.82 44.7 18.38 65.65z"
      />
      <path
        fill="#ffbe01"
        d="M66.57 100.82c1.62-.18 10.09-8.36 20.08-14.65s24.75-13.03 26.92-16.82-3.04-6.3-3.04-6.3l-60.79-10.2-35.6 14s-1.95.65-.65 2.28 3.36 4.34 7.92 7.16c5.54 3.43 19.76 9.12 28.76 14 9.02 4.88 14.45 10.74 16.4 10.53z"
      />
      <path
        fill="#f4482b"
        d="m19.04 68.47 1.89 5.06s13.6 13.71 40.65 14.25c26.92.54 42.01-12.27 42.01-12.27l3.44-8.64-87.99 1.6z"
      />
      <path
        fill="#a6b732"
        d="m54.5 76.83-2.13 3.41s4.55 2.73 5.86 3.2c1.88.67 3.73.43 6.5-.43C67.5 82.16 79 77.58 79 77.58s9.51.14 15.48.25c5.29.1 10.59-1.8 13.36-5s7.07-10.06 7.07-10.06 2.24-.53 3.73-2.24 1.6-3.2 1.6-3.2-.43-2.88-2.02-3.62c-1.6-.75-72.86-5.11-72.86-5.11l-28.55 2.13L12 60.64l15.87 13.53 22.37 1.28 4.26 1.38z"
      />
      <path
        fill="#c2dd1f"
        d="M13.49 51.91c-1.61-.73-3.3-.64-4.05 1.49s-.52 3.07-.52 3.07-2.19.46-2.46 3.21c-.32 3.3 3.3 4.47 3.3 4.47s.17 1.28 1.6 2.56c1.63 1.46 3.26 1.31 3.26 1.31s4.3 4.23 7.18 5.93 7.03 2.88 11.19 2.77 5.54-2.02 8.63-.96S52.9 81.13 56.2 81.2c5.22.11 16.51-7.99 19.07-7.88 2.56.11 14.17 1.92 20.35 1.92s8.42-1.81 9.8-3.62c1.38-1.81 3.73-6.39 2.98-6.5-.75-.11-5.65 3.94-9.16 3.62-3.52-.32-6.6-2.34-10.97-2.34-4.37 0-25.67 2.77-27.06 3.41s-7.35 5.22-8.52 5.01c-1.17-.21-5.22-3.62-6.6-4.37s-15.91-1.23-18-.85c-1.5.27-3.62.64-3.62.64s.21-3.3-1.6-5.22-7.78-5.22-7.78-5.22.74-6.83-1.6-7.89zM107.97 57.66s6.5 0 8.31-.11c1.81-.11 3.94-.21 3.94-.21s-.62-2.95-1.56-4.52c-1.92-3.22-6.54-4.11-6.54-4.11l-4.15 8.95z"
      />
      <path
        fill="#de8010"
        d="m12 47.97-.71 3.14s9.83 17.41 54.01 18.07c25.86.39 40.72-7.46 47.28-13.88 3.93-3.85 4.06-7.59 4.06-7.59L12 47.97z"
      />
      <radialGradient
        id={a}
        cx={63.467}
        cy={15.525}
        r={44.042}
        gradientTransform="matrix(.0112 .9999 -1.7423 .0196 89.803 -48.242)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.517} stopColor="#df8016" />
        <stop offset={0.643} stopColor="#e58c21" />
        <stop offset={1} stopColor="#f5ac3c" />
      </radialGradient>
      <path
        fill={`url(#${a})`}
        d="M9.76 39.31c-.56 8.7 1.56 11.82 1.56 11.82s14.62 13.24 51.96 13.85c38.27.62 50.71-12.07 52.89-15.77 1.39-2.35 2.5-6.96 1.62-13.68-1.46-11.1-14.24-32.16-54.69-32S10.58 26.68 9.76 39.31z"
      />
      <path
        fill="#fdeac8"
        d="M48.19 14.36c.31 1.7-7.36 7.36-8.47 1.93-1.04-5.11 8.18-3.5 8.47-1.93zM50.93 25.01c.4 1.05 8.26 2.56 7.42-1.87-.88-4.61-8.24-.29-7.42 1.87zM57.82 12.85c.2 1.51 9.36 3.27 9.17-.99-.23-5.26-9.4-.76-9.17.99zM42.71 28.51c.05.34-2.2 2.11-4.5 2.8-2.02.61-4.09.35-4.32-2.16-.22-2.42 1.5-3.3 3.56-3.09 2.37.23 5.16 1.8 5.26 2.45zM61.74 35.58c-.38-1.59-8.99-3.8-8.41 1.23.59 5.01 8.82.46 8.41-1.23zM69.8 24.13c-.42-.49-2.39-.4-4.79.23-1.73.46-3.01 2.41-1.81 4.26 1.4 2.16 3.75 1.25 5.25-.58 1.22-1.48 1.82-3.36 1.35-3.91zM79.02 12.44c-.27-.29-2.24-.49-3.85.29-1.62.78-3.02 2.64-1.52 4.38 1.52 1.75 3.78.74 4.85-1.11.85-1.49.93-3.12.52-3.56zM85.2 27.66c-.26-1.63-7.5-4.53-7.45-.09.05 4.55 7.68 1.56 7.45.09zM95.21 19.62c-.24-1.25-9.45-2.26-7.88 2.39 1.63 4.85 8.17-.87 7.88-2.39zM82.53 35.98c.06-1.4-6.95-4.61-7.47-.41s7.39 2.23 7.47.41z"
      />
    </svg>
  );
};
