import React, { SVGProps } from "react";
export const DiceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#cecece"
      d="M33.09 16.55s-11.5 4.75-14.2 6.7c-3.29 2.38-7.29 5.41-9.41 9.46-2.03 3.87-2.84 6.83-2.99 11.79-.15 4.96 5.15 46.15 5.15 46.15s28.72 27.06 33.25 29.11 11.21 4.56 19.23 4.41c8.02-.15 13.29-2.62 16.36-4.08 3.06-1.46 39.4-55.26 39.7-59.64s.59-12.73.41-16.26c-.19-3.62-.93-6.51-2.83-10.16s-5.02-7.21-6.91-8.82c-1.9-1.6-25.75-12.31-32.97-13.93-20.86-4.65-44.79 5.27-44.79 5.27z"
    />
    <path
      fill="#eaeaea"
      d="M15.44 29.1c.14 4.89 4.08 7.15 12.11 11.23S56 56.38 64.46 56.67c8.46.29 39.82-18.77 44.35-21.7 4.48-2.9 5.23-7.53 1.02-10.69-5.84-4.37-31.95-16.48-37.49-18.52-2.83-1.04-10.04-2.66-15.76-.44-4.52 1.75-33.19 15.02-35.96 16.77-2.77 1.75-5.25 4.28-5.18 7.01z"
    />
    <path
      fill="#fff"
      d="M34.34 19.12c-1.78-2.01-14.31 4.55-14.31 10.14 0 5.42 11.37 12.32 14.22 9.76 2.05-1.84-5.21-7.58-4.26-10.9.94-3.32 5.87-7.3 4.35-9zM46.74 48.08c-1.97 2.85 10.62 8.98 18.3 8.98 7.23 0 19.04-7.06 17.52-9.11-2.46-3.32-8.43-.2-17.72 0-5.12.11-16.05-2.84-18.1.13zM92.41 39.04c2.46 1.61 16.49-5.5 16.77-9.76s-8.72-9.38-11.28-7.58c-2.56 1.8.76 3.13-.09 6.25s-8.35 9.16-5.4 11.09z"
    />
    <path
      fill="#dfdfdf"
      d="M6.48 45.09c-.15 5.17.16 36.73.23 41.01.07 4.72 1.23 9.97 4.37 12.66s28.57 18.82 34.29 21.29c8.01 3.46 15.8-.34 16.36-8.96.56-8.63.22-34.96.22-38.54s-1.8-8.86-5.72-11.55c-3.92-2.69-34.92-20.47-38.1-22.3-4.48-2.57-11.42-1.34-11.65 6.39z"
    />
    <path
      fill="#b8b8b8"
      d="M70.68 61.34c-3.36 2.66-4.71 6.5-4.71 10.64s-.22 36.08-.11 39.33 4.71 11.88 11.88 9.75c7.41-2.2 16.81-8.85 23.53-12.89 6.72-4.03 11.96-7.45 14.3-9.37 3-2.45 5.03-8.38 5.14-13.42.11-4.93-.03-35.79-.12-38.21-.22-5.68-2.52-10.37-8.68-9.03S75.5 57.53 70.68 61.34z"
    />
    <ellipse cx={64.29} cy={29.27} fill="#fe4e27" rx={13.81} ry={9.93} />
    <path
      fill="#2f2f2f"
      d="M26.49 55.55c1.8 5.02 1.26 10.22-2.59 11.6s-8.46-2.05-10.23-6.96c-1.81-5.02-1.36-10.23 2.59-11.6 3.87-1.33 8.43 1.94 10.23 6.96zM51.82 99.6c2.14 4.88 2.02 10.66-1.72 12.3-3.74 1.64-8.81-1.77-10.92-6.55-2.15-4.88-1.83-10.23 1.91-11.87 3.75-1.64 8.59 1.24 10.73 6.12zM101.96 66.96c3.27 1.86 8.53-.45 11.24-5.19 2.71-4.75 2.25-10.1-1.01-11.97-3.27-1.86-8.44.44-11.15 5.19-2.71 4.74-2.35 10.11.92 11.97zM89.04 88.7c3.32 1.94 8.72-.37 11.54-5.2 2.82-4.82 2.42-10.31-.9-12.25s-8.83.21-11.65 5.03c-2.82 4.84-2.3 10.48 1.01 12.42zM76.21 110.92c3.21 1.96 8.54-.2 11.39-4.86s2.55-10.03-.66-11.99c-3.21-1.96-8.18-.58-11.46 4.61-2.92 4.61-2.48 10.28.73 12.24z"
    />
  </svg>
);
