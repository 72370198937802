import React, { SVGProps, useId } from "react";
export const GrandmaIcon = (props: SVGProps<SVGSVGElement>) => {
  const a = useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      viewBox="0 0 128 128"
      {...props}
    >
      <path
        fill="#e59600"
        d="M99.92 64.57H28.08C20.34 64.57 14 71.51 14 79.99s6.34 15.42 14.08 15.42h71.84c7.75 0 14.08-6.94 14.08-15.42s-6.34-15.42-14.08-15.42z"
      />
      <path
        fill="#ffca28"
        d="M64 12.54c-34.67 0-44.06 24.33-44.06 59.35 0 34.83 17.06 52.04 44.06 52.04s44.06-17.22 44.06-52.04c0-35.02-9.39-59.35-44.06-59.35z"
      />
      <path
        fill="#eda600"
        d="M42.98 57.37c-.6 0-1.16-.36-1.4-.95a1.5 1.5 0 0 1 .84-1.95c.37-.15 9.1-3.54 21.24-3.54 12.13 0 21.49 3.39 21.88 3.53.78.29 1.18 1.15.89 1.92a1.5 1.5 0 0 1-1.93.89c-.09-.03-9.28-3.35-20.84-3.35-11.58 0-20.05 3.3-20.14 3.33-.17.09-.36.12-.54.12zM78.97 48.96c-.15 0-.3-.02-.45-.07-.07-.02-6.86-2.14-14.52-2.14s-14.45 2.12-14.52 2.14a1.498 1.498 0 1 1-.9-2.86c.29-.09 7.28-2.28 15.42-2.28s15.13 2.19 15.42 2.28a1.498 1.498 0 0 1-.45 2.93zM42.91 90.5c-2.63 0-5.27-.85-7.28-2.54-.63-.53-.71-1.48-.18-2.11.53-.63 1.48-.71 2.11-.18 2.95 2.49 7.74 2.49 10.69 0 .63-.54 1.58-.46 2.11.18.53.63.46 1.58-.18 2.11-2 1.69-4.63 2.54-7.27 2.54zM85.09 90.5c-2.64 0-5.27-.85-7.28-2.54-.63-.53-.71-1.48-.18-2.11.53-.63 1.48-.71 2.11-.18 2.95 2.49 7.74 2.49 10.69 0 .63-.54 1.58-.46 2.11.18.54.63.46 1.58-.18 2.11-2 1.69-4.63 2.54-7.27 2.54zM40.63 105.31c-.38 0-.77-.15-1.06-.44a1.49 1.49 0 0 1 0-2.12l9.53-9.53c.59-.59 1.53-.59 2.12 0s.59 1.54 0 2.12l-9.53 9.53c-.29.29-.68.44-1.06.44zM87.37 105.31c-.38 0-.77-.15-1.06-.44l-9.53-9.53c-.59-.59-.59-1.54 0-2.12s1.54-.59 2.12 0l9.53 9.53c.59.59.59 1.54 0 2.12-.29.29-.68.44-1.06.44z"
      />
      <path
        fill="#9e9e9e"
        d="M63.83 4.1c-15.32 0-27.74 7.18-27.74 16.04s12.42 13.04 27.74 13.04S91.57 29 91.57 20.14C91.58 11.28 79.16 4.1 63.83 4.1z"
      />
      <g fill="#9e9e9e">
        <path d="M95.75 65.29c-1.23-1.63-4.09-4.01-9.62-4.01s-8.39 2.38-9.62 4.01c-.55.72-.41 1.56-.03 2.06.35.47 1.39.9 2.53.51s3.38-1.54 7.12-1.57c3.74.03 5.98 1.18 7.12 1.57s2.18-.05 2.53-.51c.37-.5.51-1.34-.03-2.06zM51.89 65.29c-1.23-1.63-4.09-4.01-9.62-4.01s-8.39 2.38-9.62 4.01c-.55.72-.41 1.56-.03 2.06.35.47 1.39.9 2.53.51s3.38-1.54 7.12-1.57c3.74.03 5.98 1.18 7.12 1.57s2.18-.05 2.53-.51c.37-.5.51-1.34-.03-2.06z" />
      </g>
      <radialGradient
        id={a}
        cx={63.478}
        cy={58.352}
        r={63.847}
        gradientTransform="matrix(1 .00679 -.00588 .8667 .345 9.345)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.44} stopColor="#9e9e9e" />
        <stop offset={1} stopColor="#e0e0e0" />
      </radialGradient>
      <path
        fill={`url(#${a})`}
        d="M100.62 27.22C91.75 17.49 69.01 11.65 64 16.82c-5.01-5.17-27.75.67-36.62 10.4-9.8 10.74-12.03 29.42-8.24 45.15.35 1.45 3.95 1.47 3.95-.02 0-10.35 8.17-25.83 19.66-29.08 5.54-1.57 17.69-1.98 21.25-9.08 3.56 7.11 15.71 7.52 21.25 9.08 11.49 3.25 19.66 18.48 19.66 29.08 0 1.49 3.6 1.47 3.95.02 3.79-15.73 1.56-34.41-8.24-45.15z"
      />
      <g fill="#404040">
        <ellipse cx={42.26} cy={77.34} rx={6.48} ry={6.71} />
        <ellipse cx={86.12} cy={77.34} rx={6.48} ry={6.71} />
      </g>
      <path
        fill="#e59600"
        d="M69.07 89.56a1.63 1.63 0 0 0-.42-.11h-9.3c-.14.02-.28.05-.42.11-.84.34-1.31 1.22-.91 2.14.4.93 2.25 3.54 5.98 3.54s5.58-2.61 5.98-3.54-.06-1.8-.91-2.14z"
      />
      <path
        fill="#795548"
        d="M75.01 99.97c-4.19 2.49-17.91 2.49-22.1 0-2.4-1.43-4.86.76-3.86 2.94.98 2.15 8.47 7.13 14.95 7.13 6.47 0 13.87-4.98 14.85-7.13.99-2.19-1.43-4.37-3.84-2.94z"
      />
    </svg>
  );
};
