import React, { SVGProps } from "react";

export const SculptureIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="128 0 256 512"
    {...props}
  >
    <path
      d="m219.019 15.702.299 1.023-16.449 23.121-23.674 2.877-4.149-14.148c-3.391-11.574 3.253-23.7 14.827-27.09l2.046-.598c11.572-3.391 23.709 3.241 27.1 14.815z"
      style={{
        fill: "#ccc",
      }}
    />
    <path
      d="M275.21 217.009c-32.32 23.585-62.065 28.964-82.488 28.964-17.171 0-27.757-3.804-27.757-3.804-1.414-15.102 1.034-30.343 7.344-44.123 7.367-16.079 16.539-34.733 27.343-48.79l.425-1.494a20.63 20.63 0 0 1-8.93-5.138l-34.894 6.862-2.195-35.664a29.014 29.014 0 0 1 12.758-25.86l9.93-6.701a30.197 30.197 0 0 0 12.241-17.022 30.098 30.098 0 0 0 1.092-7.413 21.792 21.792 0 0 0 16.505 1.736l2.057-.598c3.391-1 6.356-2.735 8.77-5a51.001 51.001 0 0 0 3.931 3.115 48.762 48.762 0 0 0 28.251 9.011h4.103c9.735 0 17.7 7.758 17.964 17.493-.001-.001 3.55 109.509 3.55 134.426z"
      style={{
        fill: "#e6e6e6",
      }}
    />
    <path
      d="M217.41 52.963a21.765 21.765 0 0 1-8.77 5l-2.057.598a21.792 21.792 0 0 1-16.505-1.736 21.737 21.737 0 0 1-10.585-13.08l-.299-1.023 40.124-25.998 4.138 14.148c2.401 8.184-.219 16.644-6.046 22.091z"
      style={{
        fill: "#f2f2f2",
      }}
    />
    <path
      d="M221.34 56.078a21.765 21.765 0 0 1-12.701 9.931l-2.057.598a21.792 21.792 0 0 1-17.597-2.368 30.098 30.098 0 0 0 1.092-7.413 21.792 21.792 0 0 0 16.505 1.736l2.057-.598c3.391-1 6.356-2.735 8.77-5a50.938 50.938 0 0 0 3.931 3.114z"
      style={{
        fill: "#ccc",
      }}
    />
    <path
      d="M357.998 466.176V512H175.539v-45.824h154.334z"
      style={{
        fill: "#999",
      }}
    />
    <path
      d="M247.074 466.176H182.78l32.147-26.515z"
      style={{
        fill: "#e6e6e6",
      }}
    />
    <path
      d="M275.21 217.009a529.476 529.476 0 0 0 17.792 195.975 38.957 38.957 0 0 0 6.39 12.827l30.481 40.365h-82.799l-32.147-26.515s-1.138-11.528-5.919-31.561a450.591 450.591 0 0 0-5.184-19.665c-5.494-19.079-13.597-42.687-25.447-69.443-10.183-23.01-10.574-50.801-13.413-76.822 0 0 10.585 3.804 27.757 3.804 20.425-.001 50.17-5.38 82.489-28.965z"
      style={{
        fill: "#ccc",
      }}
    />
    <path
      d="M205.582 151.957c-2.218 0-4.386-.295-6.443-.877a24.153 24.153 0 0 1-10.4-5.988c-4.708-4.582-7.303-10.721-7.303-17.283 0-6.45 2.514-12.516 7.078-17.08a3.449 3.449 0 0 1 4.877 4.877 17.148 17.148 0 0 0-5.058 12.205c0 4.684 1.854 9.068 5.22 12.344a17.252 17.252 0 0 0 7.443 4.285 16.82 16.82 0 0 0 4.588.623c9.472 0 17.186-7.673 17.252-17.13l-.155-2.09a24.201 24.201 0 0 1-.247-3.457 3.448 3.448 0 0 1 3.32-3.446c1.853-.052 3.429 1.341 3.566 3.191l.217 2.937c1.293 8.237 8.443 14.559 17.044 14.559 9.512 0 17.252-7.734 17.252-17.24a17.15 17.15 0 0 0-5.057-12.205 3.448 3.448 0 1 1 4.876-4.877c4.565 4.564 7.078 10.63 7.078 17.08 0 13.308-10.833 24.136-24.148 24.136-7.619 0-14.427-3.547-18.856-9.074-3.731 8.532-12.253 14.51-22.144 14.51z"
      style={{
        fill: "#f2f2f2",
      }}
    />
  </svg>
);
