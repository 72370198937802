import React, { SVGProps } from "react";
export const FoodIcon4 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#a6cfd6"
      d="M65.12 17.55c-17.6-.53-34.75 5.6-34.83 14.36-.04 5.2 1.37 18.6 3.62 48.68s2.25 33.58 3.5 34.95c1.25 1.37 10.02 8.8 25.75 8.8s25.93-6.43 26.93-8.05c.48-.78 1.83-17.89 3.5-37.07 1.81-20.84 3.91-43.9 3.99-45.06.24-3.5-3.38-15.73-32.46-16.61z"
    />
    <path
      fill="#dcedf6"
      d="M41.4 45.29c-.12.62 1.23 24.16 2.32 27.94 1.99 6.92 9.29 7.38 10.23 4.16.9-3.07-.38-29.29-.38-29.29s-3.66-.3-6.43-.84c-3.14-.63-5.74-1.97-5.74-1.97z"
    />
    <path
      fill="#6ca4ae"
      d="M33.74 32.61c-.26 8.83 20.02 12.28 30.19 12.22 13.56-.09 29.48-4.29 29.8-11.7S79.53 21.1 63.35 21.1c-13.75 0-29.39 4.09-29.61 11.51z"
    />
    <path
      fill="#dc0d27"
      d="M84.85 13.1c-.58.64-9.67 30.75-9.67 30.75s2.01-.33 4-.79c2.63-.61 3.76-1.06 3.76-1.06s7.19-22.19 7.64-23.09c.45-.9 21.61-7.61 22.31-7.93.7-.32 1.39-.4 1.46-.78.06-.38-2.34-6.73-3.11-6.73s-25.16 8.27-26.39 9.63z"
    />
    <path
      fill="#8a1f0f"
      d="M110.55 7.79c1.04 2.73 2.8 3.09 3.55 2.77.45-.19 1.25-1.84.01-4.47-.99-2.09-2.17-2.74-2.93-2.61-.76.12-1.49 2.05-.63 4.31zM91.94 18.34a.73.73 0 0 1-.58-.3l-3.99-5.77a.694.694 0 0 1 .18-.97c.32-.22.76-.14.97.18l3.99 5.77a.694.694 0 0 1-.57 1.09zM90.28 19.43c-.18 0-.35-.07-.49-.2l-5.26-5.12a.697.697 0 0 1-.01-.99c.27-.28.71-.28.99-.01l5.26 5.12c.28.27.28.71.01.99-.14.14-.32.21-.5.21zM89.35 21.22c-.12 0-.25-.03-.36-.1l-5.6-3.39a.7.7 0 0 1 .72-1.2l5.6 3.39a.7.7 0 0 1-.36 1.3z"
    />
  </svg>
);
