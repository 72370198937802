export const easyClues: string[] = [
  "Airplane",
  "Ant",
  "Apple",
  "Aquarium",
  "Baby",
  "Bakery",
  "Ball",
  "Balloon",
  "Banana",
  "Barbecue",
  "Barn",
  "Beach",
  "Bear",
  "Bed",
  "Bee",
  "Bell",
  "Bicycle",
  "Bird watching",
  "Bird",
  "Boat",
  "Book",
  "Boot",
  "Bottle",
  "Bowl",
  "Box",
  "Bread",
  "Bridge",
  "Bus",
  "Butterfly",
  "Café",
  "Cake",
  "Camera",
  "Candle",
  "Canyon",
  "Car",
  "Carrot",
  "Castle",
  "Cat",
  "Cave",
  "Chair",
  "Cheese",
  "Chess",
  "Chicken",
  "Church",
  "Cinema",
  "Clock",
  "Cloud",
  "Computer",
  "Cookies",
  "Coral",
  "Cow",
  "Crab",
  "Cup",
  "Cupcake",
  "Desert",
  "Diamond",
  "Dog",
  "Dolphin",
  "Door",
  "Duck",
  "Egg",
  "Elephant",
  "Eye",
  "Farm",
  "Fish",
  "Flag",
  "Flamingo",
  "Flower",
  "Football",
  "Forest",
  "Fork",
  "Fox",
  "Frog",
  "Fruit",
  "Giraffe",
  "Glass",
  "Grapes",
  "Guitar",
  "Hammer",
  "Hand",
  "Hat",
  "Heart",
  "Helicopter",
  "Horse",
  "Horseback riding",
  "Hospital",
  "House",
  "Ice cream",
  "Ice skating",
  "Island",
  "Jellyfish",
  "Kangaroo",
  "Key",
  "Kite",
  "Knife",
  "Ladder",
  "Ladybug",
  "Lamp",
  "Leaf",
  "Library",
  "Lighthouse",
  "Lion",
  "Lobster",
  "Lollipop",
  "Meadow",
  "Monkey",
  "Moon",
  "Mosque",
  "Mountain",
  "Mouse",
  "Museum",
  "Mushroom",
  "Octopus",
  "Orange",
  "Origami",
  "Owl",
  "Painting a picture",
  "Panda",
  "Park",
  "Parrot",
  "Pen",
  "Pencil",
  "Penguin",
  "Piano",
  "Picnic",
  "Pig",
  "Pillow",
  "Pineapple",
  "Pizza",
  "Plane",
  "Plate",
  "Playground",
  "Pyramid",
  "Rabbit",
  "Rainbow",
  "Restaurant",
  "Ring",
  "River",
  "Robot",
  "Rock climbing",
  "Rocket",
  "Rollerblading",
  "Rose",
  "Sailing",
  "Sandcastle",
  "Sandwich",
  "School",
  "Scissors",
  "Scuba diving",
  "Seahorse",
  "Seashell",
  "Shark",
  "Ship",
  "Shoe",
  "Skateboarding",
  "Skyscraper",
  "Smile",
  "Snail",
  "Snake",
  "Snowman",
  "Soccer",
  "Socks",
  "Spider",
  "Spoon",
  "Star",
  "Starfish",
  "Stargazing",
  "Sun",
  "Sunflower",
  "Sunglasses",
  "Sunrise",
  "Sunset",
  "Supermarket",
  "Surfing",
  "Telephone",
  "Temple",
  "Theater",
  "Train",
  "Tree",
  "Turtle",
  "Volcano",
  "Waterfall",
  "Whale",
  "Windmill",
  "Zebra",
  "Zoo",
];
