import React, { SVGProps } from "react";
export const PlantIcon4 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#5b9821"
      d="M32 24.66s2.43-7.3 8.16-11.88 58.85 15.04 58.85 15.04 9.28 3.31 14.77 12.08c3.69 5.9 5.75 14.62 1.84 25.15-9.32 25.08-33.5 22.18-39.8 20.75-2.96-.67-10.17-2.58-10.17-2.58s-16.47 13.6-36.29 3.17c-15.09-7.94-13.22-19.81-13.22-19.81S5.98 56.42 10.38 42.27C15.68 25.23 32 24.66 32 24.66z"
    />
    <path
      fill="#8bc02b"
      d="M66.37 55.01c13.75-2.29 18.04-8.16 21.77-13.6 4.11-6 3.01-11.03 5.87-11.17s5.14 5.02 2 14.03c-4.44 12.74-13.57 14.61-13.75 18.04-.14 2.72 9.72 2.91 18.62-1.43 12.89-6.3 12.89-21.05 12.89-21.05s-2.29-4.73-6.73-8.02-7.74-5.01-7.74-5.01S94 6.9 69.95 3.6 36.01 17.07 36.01 17.07s.86 5.44 5.01 11.74c3.31 5.03 6.73 4.87 6.44 7.73-.19 1.86-6.16 2.43-12.46-.29-5.74-2.48-7.16-5.87-8.73-5.87S11.68 37.63 17.11 53c4.3 12.17 18.51 14.45 21.05 14.89 7.45 1.29 11.74-3.58 6.44-7.45s-11.17-6.16-9.88-10.02c1.39-4.18 6.35.18 12.31 2.76 5.89 2.55 13.02 2.89 19.34 1.83z"
    />
    <path
      fill="#6d4b41"
      d="M39.03 70.97s3.3-.19 4.66-.58c1.36-.39 3.2-1.55 3.2-1.55S59.64 82.18 60.96 83.3c1.29 1.09 2.81-15.53 2.81-15.53s2.91.45 5.63.02c3.58-.57 6.02-1.71 6.02-1.71S73.68 76.31 73 80.19c-.43 2.49-.58 9.41-.58 9.41l-9.32 7.76-7.09-4.76s-.02-1.87-1.07-3.14c-3.26-3.94-15.91-18.49-15.91-18.49zM71.54 93.78l7.09-8.54s2.52.19 4.37.19c1.84 0 5.05-.39 5.05-.39s-5.82 7.86-8.93 11.74c-3.11 3.88-7.18 8.74-7.18 8.74l-3.88-11.26 3.48-.48z"
    />
    <path
      fill="#865b50"
      d="M66.5 86.98c-2.9.26-10.48 5.63-10.48 5.63s.39 9.12.1 14.27-2.32 13.56-.45 15.69c1.62 1.84 15.24 2.19 17.27.05 2.04-2.14.06-10.98.06-16.71s-.58-16.31-.58-16.31-2.62-2.91-5.92-2.62z"
    />
  </svg>
);
