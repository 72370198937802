import React, { SVGProps } from "react";
export const FoodIcon3 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#d4d4d4"
      d="M64.81 28.17C36.16 28.35 4.09 41 3.57 69.85c-.2 10.79 4.35 20.91 15.49 29.81 10.52 8.39 23.81 12.59 43.74 12.75 30.1.23 47.13-10.68 55.26-22.17 5.54-7.83 6.53-15.61 6.33-21.57-.79-22.95-29.57-40.7-59.58-40.5z"
    />
    <path
      fill="#f6f6f6"
      d="M64.76 28.85c-29.22-.5-57.86 10.2-58.25 37.86-.13 8.83 2.6 15.78 7.26 21.38 10.59 12.75 26.42 19.92 51 19.81 19.42-.08 35.28-5.73 45.11-15.3 7.34-7.15 12.28-15.73 11.57-26.28-1.58-23.54-22.37-36.88-56.69-37.47z"
    />
    <path
      fill="#af5711"
      d="m70.18 56 31.65-.44s5.32 4.24 4.99 14.02c-.3 8.79-7.48 27.15-44.29 26.5-37.03-.66-41.8-27.88-41.8-27.88s5.37-10.67 10.76-10.67S70.18 56 70.18 56z"
    />
    <path
      fill="#cf701d"
      d="m37.94 57.37-13.7-2.14s-4.71 4.95-4.95 12.75c-.22 7.14 3.6 18.18 24.09 25.31C53.49 96.8 66.21 96.25 68.09 96c2.53-.33 2.75-4.18-1.54-4.07-3.41.09-11.65.55-21.65-2.75-8.89-2.93-20.6-9.16-21.96-18.25-1.55-10.39 15-13.56 15-13.56z"
    />
    <path
      fill="#f5b03e"
      d="m39.08 27.42-9.98 3.16s-.89 2.44-1.6 5.96c-.39 1.95 2.13 16.82 2.13 16.82L75.67 86l4.17 1.37s9.59-2.21 16.52-9.26c6.04-6.15 6.29-12.34 6.46-14.32.24-2.99-.84-12.81-1.87-17.8-1.32-6.37-3.41-16.15-5.17-18.57s-31.32-5.06-31.32-5.06l-25.38 5.06z"
    />
    <path
      fill="#f7c876"
      d="M65.78 56.55c.33-.77 5.63-3.41 5.63-3.41s2.61-.23 4.04-.45c1.43-.22 3.65-.81 3.65-.81l1.62 35.28s-2.63.71-4.4.94c-1.33.17-4.19.47-5.29.47-1.1 0-4.59-10.03-4.59-10.03l-.66-21.99z"
    />
    <path
      fill="#fbdf96"
      d="M23.25 67.54c0 3.28 1.98 8.9 13.41 15.39 15.04 8.53 35.51 5.59 35.51 5.59s-.45-21.63-.45-24.6-.19-10.82-.19-10.82-20.15 1.78-34.22-5.47c-12.39-6.39-9.29-13.4-9.29-13.4s-2.44 9.12-3.34 17.15c-.82 7.37-1.43 12.42-1.43 16.16z"
    />
    <path
      fill="#e07f14"
      d="M63.14 15.78c-19.12-.33-34.09 7.15-34.4 17.25-.33 10.99 17.04 16.53 36.05 17.03C85.34 50.61 98.3 40 97.21 31.38 96 21.82 82.48 16.11 63.14 15.78z"
    />
    <path
      fill="#fff"
      d="M84.66 23.31c-2.2 2.28 1.98 2.87 1.52 7.98-.56 6.19-8.12 8.63-7.9 10.9.33 3.33 13.58-1.46 14.65-10.53.92-7.73-6.92-9.76-8.27-8.35z"
    />
    <path
      fill="#ffba02"
      d="M75.98 42.58c.89 1.58-4.24 3.99-14.24 3.93-13.84-.08-26.03-5.82-25.8-7.31s7.63 3.38 24.46 3.62c11.97.16 14.88-1.5 15.58-.24z"
    />
  </svg>
);
