import React, { SVGProps } from "react";
export const SwordsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#82aec0"
      d="M16.84 28.01C7.08 18.25 3.55 3.55 3.55 3.55l92.61 92.61-5.59 5.59c0-.01-63.97-63.98-73.73-73.74z"
    />
    <path
      fill="#c9e3e6"
      d="M28.01 16.84C18.25 7.08 3.55 3.55 3.55 3.55l92.61 92.61 5.59-5.59-73.74-73.73z"
    />
    <path
      fill="#874d36"
      d="m98.09 98.08 4.38-4.38 14.93 17.18-1.59 4.93-4.93 1.59-17.18-14.93z"
    />
    <path
      fill="#693928"
      d="m98.09 98.08 4.39-4.39 14.93 17.18s1.43 1.9-1.59 4.92-4.92 1.59-4.92 1.59l-17.2-14.91 4.39-4.39z"
    />
    <path
      fill="#a65f3e"
      d="M111.41 113.63c-.24.15-1.15.59-2.27-.37-.63-.54-.7-1.14.01-1.57 1.56-.95 3.56-3.44 4.42-5.17l2.09 2.41c-1.09 1.84-2.46 3.61-4.25 4.7zM107.06 108.4c-.24.15-1.35.73-2.19.23-1.19-.71-.98-1.61-.27-2.04 1.56-.95 3.79-3.28 4.77-4.92l2.05 2.36c-1.26 1.8-2.62 3.31-4.36 4.37zM101.66 104.59c-.24.15-1.19.4-2.15-.27-.62-.44-.83-1.43-.12-1.86 1.79-1.08 4.41-3.83 5.62-5.78l2.04 2.35c-1.5 2.18-3.71 4.53-5.39 5.56z"
    />
    <path
      fill="#9e740b"
      d="m110.48 120.5.4-3.1s1.55.01 4.04-2.48c2.49-2.49 2.48-4.04 2.48-4.04l3.1-.4c2.28-.4 4.24 1.64 3.92 3.91-.31 2.13-1.5 4.54-3.49 6.54-1.99 1.99-4.4 3.18-6.54 3.49-2.26.32-4.2-1.65-3.91-3.92zM78.37 112.24l10.5-12.84 5.27-5.27 5.27-5.27 12.84-10.5c.62-.52 1.53-.48 2.11.09.58.58.61 1.52.06 2.14-2.78 3.1-8.38 9.59-10.89 12.26-1.76 1.88-3.6 3.75-5.26 5.4-1.65 1.65-3.53 3.49-5.4 5.26-2.67 2.51-9.16 8.11-12.26 10.89-.62.55-1.55.52-2.14-.06-.59-.57-.62-1.48-.1-2.1z"
    />
    <path
      fill="#82aec0"
      d="M111.16 28.01c9.76-9.76 13.29-24.46 13.29-24.46L31.84 96.16l5.59 5.59c0-.01 63.97-63.98 73.73-73.74z"
    />
    <path
      fill="#c9e3e6"
      d="M99.99 16.84c9.76-9.76 24.46-13.29 24.46-13.29L31.84 96.16l-5.59-5.59 73.74-73.73z"
    />
    <path
      fill="#874d36"
      d="m29.91 98.08-4.38-4.38-14.93 17.18 1.59 4.93 4.93 1.59 17.18-14.93z"
    />
    <path
      fill="#693928"
      d="m29.91 98.08-4.39-4.39-14.92 17.19s-1.54 1.79 1.59 4.92 4.92 1.59 4.92 1.59l17.18-14.93-4.38-4.38z"
    />
    <path
      fill="#9e740b"
      d="m17.52 120.5-.4-3.1s-1.55.01-4.04-2.48-2.48-4.04-2.48-4.04l-3.1-.4c-2.27-.29-4.24 1.64-3.92 3.91.31 2.13 1.5 4.54 3.49 6.54 1.99 1.99 4.4 3.18 6.54 3.49 2.26.32 4.2-1.65 3.91-3.92zM49.63 112.24 39.13 99.4l-5.27-5.27-5.27-5.27-12.84-10.5c-.62-.52-1.53-.48-2.11.09-.58.58-.61 1.52-.06 2.14 2.78 3.1 8.38 9.59 10.89 12.26 1.76 1.88 3.6 3.75 5.26 5.4 1.65 1.65 3.53 3.49 5.4 5.26 2.67 2.51 9.16 8.11 12.26 10.89.62.55 1.55.52 2.14-.06.59-.57.62-1.48.1-2.1z"
    />
    <path
      fill="#e2a610"
      d="M5.1 113.86a8.09 8.09 0 0 0 2.86 4.36c2 1.58 3.16.38 2.26-1.44-.73-1.46-1.28-2.19-2.37-4.13-1.03-1.83-3.24-.75-2.75 1.21zM17.47 82.68c-.86-1.01.49-1.36 2.51.36C22 84.76 30.5 92.02 30.5 92.02c2.73 2.48 1.35 5.04 1.35 5.04-2.21-1.75-13.52-13.37-14.38-14.38z"
    />
    <path
      fill="#a65f3e"
      d="M16.55 113.63c.24.15 1.15.59 2.27-.37.63-.54.7-1.14-.01-1.57-1.56-.95-3.56-3.44-4.42-5.17l-2.09 2.41c1.1 1.84 2.46 3.61 4.25 4.7zM20.91 108.4c.24.15 1.35.73 2.19.23 1.19-.71.98-1.61.27-2.04-1.56-.95-3.79-3.28-4.77-4.92l-2.05 2.36c1.25 1.8 2.62 3.31 4.36 4.37zM26.3 104.59c.24.15 1.19.4 2.15-.27.62-.44.83-1.43.12-1.86-1.79-1.08-4.41-3.83-5.62-5.78l-2.04 2.35c1.5 2.18 3.71 4.53 5.39 5.56z"
    />
    <path fill="#2f7889" d="m64 75.17 5.57 5.58 9.36-12.99L75.17 64z" />
    <path
      fill="#e2a610"
      d="M102.26 87.83c-1.88 1.56 1.47 2.07 3.81-.29l4.7-4.67c1.17-1.19 1.17-3.23-1-1.53l-7.51 6.49zM118.75 114.94c-.67 1.04-.38 2.07.71 2.08.78.01 1.62-.57 2.01-.96.57-.57.98-1.03 1.26-2.2.37-1.57-1.33-3.15-2.33-1.64-.89 1.34-.98 1.69-1.65 2.72z"
    />
    <path
      fill="#82aec0"
      d="M157.2 21.54c-9.76-9.76-13.29-24.46-13.29-24.46l92.61 92.61-5.59 5.59c0-.01-63.97-63.98-73.73-73.74z"
    />
    <path
      fill="#c9e3e6"
      d="M168.37 10.37C158.61.61 143.9-2.92 143.9-2.92l92.61 92.61 5.59-5.59-73.73-73.73z"
    />
    <path
      fill="#874d36"
      d="m238.44 91.61 4.38-4.38 14.94 17.18-1.6 4.92-4.92 1.6L234.05 96z"
    />
    <path
      fill="#693928"
      d="m238.44 91.61 4.39-4.39 14.93 17.18s1.43 1.9-1.59 4.92-4.92 1.59-4.92 1.59L234.05 96l4.39-4.39z"
    />
    <path
      fill="#a65f3e"
      d="M251.76 107.16c-.24.15-1.15.59-2.27-.37-.63-.54-.7-1.14.01-1.57 1.56-.95 3.56-3.44 4.42-5.17l2.09 2.41c-1.09 1.83-2.45 3.6-4.25 4.7zM247.41 101.93c-.24.15-1.35.73-2.19.23-1.19-.71-.98-1.61-.27-2.04 1.56-.95 3.79-3.28 4.77-4.92l2.05 2.36c-1.26 1.8-2.62 3.31-4.36 4.37zM242.02 98.11c-.24.15-1.19.4-2.15-.27-.62-.44-.83-1.43-.12-1.86 1.79-1.08 4.41-3.83 5.62-5.78l2.04 2.35c-1.5 2.19-3.71 4.54-5.39 5.56z"
    />
    <path
      fill="#9e740b"
      d="m250.84 114.03.4-3.1s1.55.01 4.04-2.48c2.49-2.49 2.48-4.04 2.48-4.04l3.1-.4c2.27-.29 4.24 1.64 3.92 3.91-.31 2.13-1.5 4.54-3.49 6.54-1.99 1.99-4.4 3.18-6.54 3.49-2.27.32-4.2-1.65-3.91-3.92zM218.72 105.77l10.5-12.84 5.27-5.27 5.27-5.27 12.84-10.5c.62-.52 1.53-.48 2.11.09.58.58.61 1.52.06 2.14-2.78 3.1-8.38 9.59-10.89 12.26-1.76 1.88-3.6 3.75-5.26 5.4-1.65 1.65-3.53 3.49-5.4 5.26-2.67 2.51-9.16 8.11-12.26 10.89-.62.55-1.55.52-2.14-.06-.58-.57-.62-1.49-.1-2.1z"
    />
    <path
      fill="#82aec0"
      d="M251.51 21.54c9.76-9.76 13.29-24.46 13.29-24.46l-92.6 92.61 5.59 5.59c-.01-.01 63.96-63.98 73.72-73.74z"
    />
    <path
      fill="#c9e3e6"
      d="M240.34 10.37C250.1.61 264.8-2.92 264.8-2.92l-92.6 92.61-5.59-5.59 73.73-73.73z"
    />
    <path
      fill="#874d36"
      d="m170.27 91.61-4.39-4.38-14.93 17.18 1.6 4.92 4.92 1.6L174.65 96z"
    />
    <path
      fill="#693928"
      d="m170.27 91.61-4.39-4.39-14.93 17.18s-1.54 1.79 1.59 4.92 4.92 1.59 4.92 1.59L174.65 96l-4.38-4.39z"
    />
    <path
      fill="#9e740b"
      d="m157.87 114.03-.4-3.1s-1.55.01-4.04-2.48c-2.49-2.49-2.48-4.04-2.48-4.04l-3.1-.4c-2.27-.29-4.24 1.64-3.92 3.91.31 2.13 1.5 4.54 3.49 6.54 1.99 1.99 4.4 3.18 6.54 3.49 2.27.32 4.2-1.65 3.91-3.92zM189.99 105.77l-10.5-12.84-5.27-5.27-5.27-5.27-12.84-10.5c-.62-.52-1.53-.48-2.11.09-.58.58-.61 1.52-.06 2.14 2.78 3.1 8.38 9.59 10.89 12.26 1.76 1.88 3.6 3.75 5.26 5.4 1.65 1.65 3.53 3.49 5.4 5.26 2.67 2.51 9.16 8.11 12.26 10.89.62.55 1.55.52 2.14-.06.58-.57.62-1.49.1-2.1z"
    />
    <path
      fill="#e2a610"
      d="M144.96 107.28c.05.3.15.62.33.9.17.26.41.48.66.66 2.43 1.78 5.16-.07 3.12-2.72-1.62-2.1-4.46-1.17-4.11 1.16zM157.82 76.2c-.86-1.01.49-1.36 2.51.36 2.02 1.72 10.52 8.98 10.52 8.98 2.73 2.48 1.35 5.04 1.35 5.04-2.21-1.75-13.51-13.37-14.38-14.38z"
    />
    <path
      fill="#a65f3e"
      d="M156.91 107.16c.24.15 1.15.59 2.27-.37.63-.54.7-1.14-.01-1.57-1.56-.95-3.56-3.44-4.42-5.17l-2.09 2.41c1.09 1.83 2.45 3.6 4.25 4.7zM161.26 101.93c.24.15 1.35.73 2.19.23 1.19-.71.98-1.61.27-2.04-1.56-.95-3.79-3.28-4.77-4.92l-2.05 2.36c1.26 1.8 2.62 3.31 4.36 4.37zM166.65 98.11c.24.15 1.19.4 2.15-.27.62-.44.83-1.43.12-1.86-1.79-1.08-4.41-3.83-5.62-5.78l-2.04 2.35c1.5 2.19 3.71 4.54 5.39 5.56z"
    />
    <path fill="#2f7889" d="m204.35 68.7 5.58 5.57 9.36-12.98-3.76-3.76z" />
    <path
      fill="#e2a610"
      d="M242.61 81.35c-1.88 1.56 1.47 2.07 3.81-.29l4.7-4.67c1.17-1.19 1.17-3.23-1-1.53l-7.51 6.49z"
    />
  </svg>
);
