import React, { SVGProps, useId } from "react";

export const CityIcon = (props: SVGProps<SVGSVGElement>) => {
  const a = useId();
  const b = useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      viewBox="0 0 128 128"
      {...props}
    >
      <defs>
        <radialGradient
          id={a}
          cx={65.983}
          cy={98.45}
          r={98.33}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.283} stopColor="#afe4fe" />
          <stop offset={0.702} stopColor="#84d4f9" />
          <stop offset={0.965} stopColor="#67c9f6" />
        </radialGradient>
        <radialGradient
          id={b}
          cx={65.178}
          cy={11.353}
          r={124.87}
          fx={63.749}
          fy={11.39}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.558} stopColor="#488187" />
          <stop offset={0.675} stopColor="#477d83" />
          <stop offset={0.798} stopColor="#427078" />
          <stop offset={0.923} stopColor="#3b5b66" />
          <stop offset={0.953} stopColor="#395561" />
        </radialGradient>
      </defs>
      <path
        fill={`url(#${a})`}
        d="M116.62 124.26H11.32c-4.15 0-7.52-3.37-7.52-7.52V11.44c0-4.15 3.37-7.52 7.52-7.52h105.3c4.15 0 7.52 3.37 7.52 7.52v105.3c.01 4.15-3.36 7.52-7.52 7.52z"
      />
      <path
        fill="#88babc"
        d="M121.3 62.39h-19v21.86H60.19V18.84H28.65v65.41h-4.48V49.13H12.34v42.65h9.5v25.44h97.13v-11.11h2.33z"
      />
      <path
        fill="#3f737b"
        d="M32.41 25.13h2.37v7.59h-2.37zM37.46 25.13h2.47v7.59h-2.47zM45.27 25.13h-2.42v7.59l2.36-.02z"
      />
      <path
        fill="#abd7db"
        d="m50.44 25.13-2.6.01.07 7.58h2.51zM32.41 25.13h2.37v7.59h-2.37zM37.46 25.13h2.47v7.59h-2.47zM45.31 25.13h-2.46v7.59l2.43-.02zM50.44 37.13l-2.6.01.07 7.58h2.51zM32.41 37.13h2.37v7.59h-2.37zM37.46 37.13h2.47v7.59h-2.47zM45.31 37.13h-2.46v7.59l2.43-.02zM32.41 49.13h2.37v7.59h-2.37zM37.46 49.13h2.47v7.59h-2.47zM45.31 49.13h-2.46v7.59l2.43-.02zM53.36 25.13l-.01 7.59h2.56v-7.59zM105.55 81.2h2.88v3.94h-2.88zM111.3 81.2h2.88v3.94h-2.88zM105.55 73.83h2.88v3.94h-2.88zM111.3 73.83h2.88v3.94h-2.88zM105.55 66.83h2.88v3.94h-2.88zM111.3 66.83h2.88v3.94h-2.88z"
      />
      <path
        fill={`url(#${b})`}
        d="M115 49.4c-.36.73 0 42.7 0 42.7H99.13v-24h-5.11v-7.67h-4.53v-6.51h-8.16v6.84h-5.19v7.17h-5.36v34.95h-5.04v-39h2.35v-7.44h-2.35V41.03h-2.65l-3.61-11.16h-7.11L48.8 41.03h-2.79v15.41h-2.48v7.44h2.48v39h-7.18V67.83H17.19V28.47s-13.35-.09-13.39-.05v88.32c0 4.15 3.37 7.52 7.52 7.52h105.3c4.15 0 7.52-3.37 7.52-7.52V49.4H115z"
      />
      <path
        fill="#88babc"
        d="M5.71 116.21h29.97v2.35H5.71zM5.71 107.86h29.97v2.35H5.71z"
      />
      <path fill="#abd7db" d="M5.71 99.21h29.97v2.35H5.71z" />
      <path
        fill="#dcedf6"
        d="M5.71 90.86h29.97v2.35H5.71zM5.71 81.21h29.97v2.35H5.71zM5.71 72.86h29.97v2.35H5.71z"
      />
      <path
        fill="#88babc"
        d="M5.71 63.32h1.76v3.7H5.71zM11.71 63.32h1.76v3.7h-1.76z"
      />
      <path
        fill="#abd7db"
        d="M5.71 55.89h1.76v3.7H5.71zM11.71 55.89h1.76v3.7h-1.76z"
      />
      <path
        fill="#dcedf6"
        d="M5.71 48.46h1.76v3.7H5.71zM11.71 48.46h1.76v3.7h-1.76zM5.71 41.03h1.76v3.7H5.71zM11.71 41.03h1.76v3.7h-1.76zM5.71 33.59h1.76v3.7H5.71zM11.71 33.59h1.76v3.7h-1.76zM49.8 64.51h2.27v4.76H49.8zM55.18 64.51h2.27v4.76h-2.27zM60.36 64.51h2.27v4.76h-2.27zM49.8 73.51h2.27v4.76H49.8z"
      />
      <path
        fill="#88babc"
        d="M49.8 97.7h2.27v4.76H49.8zM55.18 97.7h2.27v4.76h-2.27zM60.36 97.7h2.27v4.76h-2.27z"
      />
      <path
        fill="#dcedf6"
        d="M55.18 73.51h2.27v4.76h-2.27zM60.36 73.51h2.27v4.76h-2.27z"
      />
      <path
        fill="#abd7db"
        d="M49.8 82.51h2.27v4.76H49.8zM55.18 82.51h2.27v4.76h-2.27zM60.36 82.51h2.27v4.76h-2.27z"
      />
      <path
        fill="#dcedf6"
        d="M54.01 53.96h4.66v-6.92s.17-2.6-2.35-2.66c-2.37-.06-2.4 2.6-2.4 2.6s.15 6.98.09 6.98zM79.85 64.16h2.27v9.69h-2.27zM88.36 64.16h2.27v9.69h-2.27zM84.15 64.16h2.27v9.69h-2.27zM76.37 77.58h2.27v3.09h-2.27zM84.19 77.58h2.27v3.09h-2.27zM92.01 77.58h2.27v3.09h-2.27zM76.37 87.58h2.27v3.09h-2.27zM84.19 87.58h2.27v3.09h-2.27zM92.01 87.58h2.27v3.09h-2.27z"
      />
      <path
        fill="#abd7db"
        d="M76.37 97.03h2.27v3.09h-2.27zM84.19 97.03h2.27v3.09h-2.27zM92.01 97.03h2.27v3.09h-2.27z"
      />
      <path
        fill="#88babc"
        d="M76.37 107.03h2.27v3.09h-2.27zM84.19 107.03h2.27v3.09h-2.27zM92.01 107.03h2.27v3.09h-2.27zM76.37 116.5h2.27v3.09h-2.27zM84.19 116.5h2.27v3.09h-2.27zM92.01 116.5h2.27v3.09h-2.27z"
      />
    </svg>
  );
};
