import React, { SVGProps } from "react";

export const CrownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#f19534"
      d="M94.52 21.81c2.44-1.18 4.13-3.67 4.13-6.56a7.28 7.28 0 0 0-14.56 0c0 2.93 1.73 5.44 4.22 6.6-2.88 15.6-7.3 27.21-23.75 29.69 0 0 4.43 22.15 25.15 22.15s22.82-21.93 22.82-21.93c-16.81.86-18.23-20.27-18.01-29.95z"
    />
    <path
      fill="#f19534"
      d="M34.74 21.81c-2.44-1.18-4.13-3.67-4.13-6.56a7.28 7.28 0 0 1 14.56 0c0 2.93-1.73 5.44-4.22 6.6 2.88 15.6 7.3 27.21 23.75 29.69 0 0-4.43 22.15-25.15 22.15S16.74 51.77 16.74 51.77c16.8.85 18.22-20.28 18-29.96z"
    />
    <path
      fill="#ffca28"
      d="M89.43 73.69c.09 0 .18.01.27.01 5.71 0 10-1.67 13.22-4.08l-13.49 4.07z"
    />
    <path
      fill="#ffca28"
      d="M119.24 16.86c-3.33-.45-6.51 2.72-7.09 7.06-.36 2.71.37 5.24 1.78 6.87l-2.4 9.95s-3.67 23.51-22.21 28.15C74.5 72.6 69.13 45.47 67.83 37.09c2.82-1.4 4.77-4.3 4.77-7.67 0-4.73-3.83-8.56-8.56-8.56s-8.56 3.83-8.56 8.56c0 3.39 1.98 6.32 4.85 7.7-1.03 8.27-5.57 34.5-21.57 31.76-16.24-2.79-23.33-30.14-24.97-37.58 1.95-1.6 3.04-4.42 2.64-7.45-.58-4.35-4.02-7.47-7.68-6.98-3.66.49-6.15 4.41-5.57 8.75.42 3.16 2.36 5.67 4.79 6.62l12.72 79.03s11.1 8.77 43.35 8.77 43.35-8.77 43.35-8.77l12.75-79.24c2.06-1.08 3.68-3.51 4.08-6.49.59-4.35-1.64-8.23-4.98-8.68z"
    />
    <ellipse cx={64.44} cy={88.3} fill="#26a69a" rx={9.74} ry={11.61} />
    <path
      fill="#69f0ae"
      d="M64.44 79.56c.38.42.72 1.19 0 2.69s-4.6 3.53-5.31 3.94c-.71.42-1.18.23-1.4.06-1.05-.84-.65-2.74.03-3.9 1.46-2.51 4.55-5.1 6.68-2.79z"
    />
    <path
      fill="#00796b"
      d="M63.72 92.63c-1.1.53-4.71 2.14-3.52 4.05.7 1.13 2.15 1.61 3.48 1.67 1.33.06 2.64-.36 3.82-.97 5.6-2.9 6.05-10.52 4.96-11.1-1.12-.6-1.88.95-2.46 1.61a20.266 20.266 0 0 1-6.28 4.74z"
    />
    <path
      fill="#26a69a"
      d="M118.09 78.8c1.56-8.63-4.24-10.79-4.24-10.79s-3.74-.68-5.5 9.03c-1.76 9.7 1.98 10.38 1.98 10.38s6.19.01 7.76-8.62z"
    />
    <path
      fill="#69f0ae"
      d="M115.51 70.96c1.36 1.82-.25 4.51-2.86 6.3-.77.53-1.79.33-1.94-.11-.42-1.26-.24-2.69.32-3.9 1.66-3.63 3.79-3.21 4.48-2.29z"
    />
    <path
      fill="#26a69a"
      d="M9.76 79.06C8.19 70.44 14 68.27 14 68.27s3.74-.68 5.5 9.03c1.76 9.7-1.98 10.38-1.98 10.38s-6.2.01-7.76-8.62z"
    />
    <path
      fill="#69f0ae"
      d="M15.78 71.2c1.34 1 .79 2.31-.22 3.22-1.15 1.05-2.03 2.2-3.01 3.39-.15.18-.32.38-.56.43-.46.1-.83-.37-.98-.82-.43-1.26-.35-2.74.29-3.9 1.82-3.31 3.96-2.71 4.48-2.32z"
    />
    <path
      fill="#f44336"
      d="M99.99 87.16c-.69 3.93-3.84 6.66-7.05 6.1-3.21-.56-3.65-3.91-2.96-7.84.69-3.93 2.24-6.94 5.44-6.38 3.21.56 5.26 4.2 4.57 8.12zM30.43 87.16c.69 3.93 3.84 6.66 7.05 6.1s3.65-3.91 2.96-7.84c-.69-3.93-2.24-6.94-5.44-6.38s-5.25 4.2-4.57 8.12z"
    />
    <path
      fill="#ffa8a4"
      d="M35.08 84.54c-.73.82-2.51 2.47-3.14 1.21-.86-1.72.33-4.32 1.69-5.18 1.36-.86 2.47-.18 2.66.59.23.98-.56 2.64-1.21 3.38zM91.98 87.05c-.99-.15-1.1-3.56 1.56-6.24 1.27-1.28 3.09.24 2.63 2.29-.44 1.95-2.38 4.23-4.19 3.95z"
    />
    <path
      fill="#ffca28"
      d="M109.15 98.21c-5.99 3-19.73 10.99-45.1 10.99s-39.11-7.99-45.1-10.99c0 0-2.15 1.15-2.15 2.35v9.21c0 1.23.65 2.36 1.71 2.99 4.68 2.76 18.94 9.28 45.55 9.28s40.87-6.52 45.55-9.28a3.475 3.475 0 0 0 1.71-2.99v-9.21c-.02-1.2-2.17-2.35-2.17-2.35z"
    />
    <path
      fill="#fff59d"
      d="M39.6 110.84c2.8.55 3.65.79 3.46 2.35-.39 3.07-6.76 2.34-10.53 1.35-7.79-2.05-9.37-4.21-9.37-6.14 0-1.77 1.36-1.98 3.46-1.24 2.51.89 6.39 2.39 12.98 3.68z"
    />
    <path
      fill="none"
      stroke="#f19534"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={4}
      d="M109.15 100.23s-16.57 9.38-45.1 9.38-45.1-9.38-45.1-9.38"
    />
    <path
      fill="#ffca28"
      d="M26.97 49.57c5.32-3.8 8.18-10.61 8.43-21.45.02-.98.3-1.27.83-1.33.85-.09.99.68.98 1.23-.24 11.7-1.73 19.01-7.63 23.13-.29.2-2.36 1.46-3.24.59-1.05-1.02.29-1.93.63-2.17zM31.84 15.54c-.17-1.81.25-5.07 5-6.55 1.39-.43 2.25.25 2.41.78.4 1.32-.76 1.84-1.29 2.01-3.65 1.18-3.83 3-4.58 4.16s-1.48.15-1.54-.4zM78.22 47.17c4.81-4.27 8-9.04 10.1-19.9.19-.96.47-1.22.99-1.2.85.02.89.81.8 1.35-1.78 11.58-3.47 14.88-9.4 21.45-.67.74-2.3 1.41-3.22.64-.83-.69.13-1.8.73-2.34zM85.3 15.63c-.17-1.81.25-5.07 5-6.55 1.39-.43 2.25.25 2.41.78.4 1.32-.76 1.84-1.29 2.01-3.65 1.18-3.83 3-4.58 4.16-.74 1.16-1.48.15-1.54-.4z"
    />
    <path
      fill="#fff59d"
      d="M31.59 71.62C19.97 66.35 16.55 52.6 14.73 46.63c-.24-.79-.12-1.54.67-1.78s1.26.27 1.51 1.06c1.32 4.33 6.45 18.79 17.04 22.9.77.3 1.97 1.03 1.32 2.28-.43.81-1.81 1.38-3.68.53zM12.68 24.63c-.56-1.16-.79-2.26-3.84-3.53-.77-.32-1.28-1.03-1.07-1.83s1.01-1.4 2.17-1.2c3.77.65 4.59 4.48 4.75 5.81.15 1.28-1.44 1.91-2.01.75zM96.87 71.62c11.62-5.27 15.04-19.02 16.86-24.99.24-.79.12-1.54-.67-1.78s-1.26.27-1.51 1.06c-1.32 4.33-6.45 18.79-17.04 22.9-.77.3-1.97 1.03-1.32 2.28.43.81 1.81 1.38 3.68.53zM115.78 24.63c.56-1.16.79-2.26 3.84-3.53.77-.32 1.28-1.03 1.07-1.83s-1.01-1.4-2.17-1.2c-3.77.65-4.59 4.48-4.75 5.81-.15 1.28 1.45 1.91 2.01.75zM59.38 29.55c.61-1.25 1.68-2.96 5.17-3.68 1.34-.28 1.73-.86 1.61-1.74-.24-1.83-2.52-1.7-3.75-1.41-4.1.96-5.01 4.6-5.18 6.04-.17 1.37 1.55 2.04 2.15.79z"
    />
  </svg>
);
