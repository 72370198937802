import React, { SVGProps, useId } from "react";
export const StoneIcon = (props: SVGProps<SVGSVGElement>) => {
  const a = useId();
  const b = useId();
  const c = useId();
  const d = useId();
  const e = useId();
  const f = useId();
  const g = useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      viewBox="0 0 128 128"
      {...props}
    >
      <linearGradient
        id={a}
        x1={87.616}
        x2={41.548}
        y1={21.04}
        y2={138.084}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#9e958e" />
        <stop offset={0.46} stopColor="#8e8884" />
        <stop offset={1} stopColor="#757575" />
      </linearGradient>
      <path
        fill={`url(#${a})`}
        d="M8.93 90.74c-.53-1.25-1.04-2.5-1.54-3.75-3.77-9.51.96-18.09 5.18-26.49l6.12-12.16c1.23-2.45 3.12-4.51 5.44-5.95l17.6-10.92 17.39-17.98a8.147 8.147 0 0 1 3.97-2.27l11.84-2.86c5.73-1.37 8.19-1.15 10.04 1.48 6.41 9.1 10.7 16.59 16.41 25.66 3.11 4.94 10.29 16.29 10.74 17.04 1.38 2.32.82 4.26.77 6.92-.12 6.13.96 12.29 3.2 18 .67 1.7 1.44 3.38 1.79 5.17.32 1.61-.11 11.84-.44 15.3-.27 2.81-6.91 13.3-9.29 14.57-2.62 1.4-22.04 8.54-26.33 9.76s-22.89-3.03-32.55-4.51c-5.97-.91-11.95-1.83-17.92-2.74-5.18-.79-10.33-.19-13.23-5.42-3.41-6.12-6.47-12.41-9.19-18.85z"
      />
      <linearGradient
        id={b}
        x1={8.859}
        x2={47.171}
        y1={120.137}
        y2={77.536}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#37474f" />
        <stop offset={0.612} stopColor="#42565f" stopOpacity={0.192} />
        <stop offset={0.757} stopColor="#455a64" stopOpacity={0} />
      </linearGradient>
      <path
        fill={`url(#${b})`}
        d="M31.34 115c5.97.91 11.95 1.83 17.92 2.74 3.11.48 7.15 1.24 11.34 2.02-2.82-3.34-7.03-7.3-9.85-10.64-.61-.72-1.23-1.47-1.45-2.39-.23-.98.03-1.99.3-2.96.86-3.18 1.08-6.63 1.94-9.81-5.33 1.24-17.61 3.6-18.62 3.31-1.12-.32-2.03-1.13-2.9-1.92-6.33-5.78-11.28-11.61-16.12-18.66-.57-.83-1.15-1.69-1.36-2.68-.2-.93-.05-1.89.1-2.82.36-2.32 1.16-8.75 1.8-14.42l-1.88 3.73C8.35 68.9 3.62 77.48 7.4 86.99c.5 1.26 1.01 2.51 1.54 3.75a192.48 192.48 0 0 0 9.17 18.83c2.89 5.24 8.05 4.64 13.23 5.43z"
      />
      <linearGradient
        id={c}
        x1={99.553}
        x2={74.778}
        y1={66.702}
        y2={11.745}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#9e958e" />
        <stop offset={0.442} stopColor="#aea298" />
        <stop offset={1} stopColor="#c9b8a9" />
      </linearGradient>
      <path
        fill={`url(#${c})`}
        d="M85.05 9.95c-1.23-1.45-3.63-1.17-6.15 1.13l-9.06 9.06c-.56.56-.86 1.33-.81 2.12l.97 18.5c.03.62.26 1.2.66 1.67 3.22 3.76 14.6 21.83 17.9 21.83 3.29 0 9.04-12.82 11.97-12.75.5.01 3.11.17 5.66.95 2.38.72 3.31-2.55 2.82-4.87-2.46-3.9-5.77-9.13-7.63-12.09-5.69-9.03-9.97-16.5-16.33-25.55z"
      />
      <linearGradient
        id={d}
        x1={37.502}
        x2={47.502}
        y1={104.545}
        y2={50.295}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4c5c64" />
        <stop offset={1} stopColor="#455a64" stopOpacity={0} />
      </linearGradient>
      <path
        fill={`url(#${d})`}
        d="M42.89 46.37c3.33-.5 14.44-2.39 16.53-3.21C63.5 41.55 67.1 34.69 69.48 31c0 0 .82 12.03 0 16.73C68.91 51 66.76 68.1 64.6 72.2L51.54 93.96 32.35 74.77c-1.63-1.63-2.67-2.49-3.26-4.8-1.35-5.28-3.8-13.07-3.81-17.66s12.36-5.16 17.61-5.94z"
      />
      <linearGradient
        id={e}
        x1={41.732}
        x2={70.782}
        y1={20.416}
        y2={20.416}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4c5c64" />
        <stop offset={1} stopColor="#455a64" stopOpacity={0} />
      </linearGradient>
      <path
        fill={`url(#${e})`}
        d="M67.16 20.74c-1.28-1.53-1.64-3.7-1.2-5.64.37-1.62 3.73-4.75 4.82-5.74l-7.69 1.86a8.226 8.226 0 0 0-3.97 2.27L41.73 31.47s16.84-7.02 25.43-10.73z"
      />
      <linearGradient
        id={f}
        x1={87.261}
        x2={121.616}
        y1={76.607}
        y2={90.767}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#9e958e" />
        <stop offset={0.25} stopColor="#aea298" />
        <stop offset={0.566} stopColor="#c9b8a9" />
      </linearGradient>
      <path
        fill={`url(#${f})`}
        d="M116.08 77.46a46.38 46.38 0 0 1-3.06-13.39c-5.74 3.93-8.95 9.03-18.19 10.28-2.45.33-6.24.79-8.63 1.42-2.96.78-5.7 2.97-10.14 3.38-3.16.3-11.13.07-9.93 1.85 1.2 1.78 8.35 5.37 10.13 6.82 2.53 2.03 4.73 4.42 6.84 6.88 1.1 1.29 2.75 4.42 4.67 5.27.49.22 1.01.28 1.53.12.91-.27 1.64-.93 2.35-1.56a94.403 94.403 0 0 1 22.97-15.02c.89-.41 1.85-.87 2.29-1.75.19-.39.27-.92.28-1.49-.35-.94-.74-1.87-1.11-2.81z"
      />
      <linearGradient
        id={g}
        x1={104.646}
        x2={97.606}
        y1={123.357}
        y2={95.587}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#37474f" />
        <stop offset={0.808} stopColor="#42565f" stopOpacity={0.192} />
        <stop offset={1} stopColor="#455a64" stopOpacity={0} />
      </linearGradient>
      <path
        fill={`url(#${g})`}
        d="M117.87 82.63c-.13-.65-.32-1.29-.54-1.92-.17.59-.54 1.24-1.34 1.85-2.3 1.76-11.17 4.47-20.93 13.02-6.37 5.58-6.38 6.33-7.75 12.48-2.24 10.07-5.8 13.73-6.48 14.35.37-.04.7-.09.97-.17 4.3-1.21 23.71-8.36 26.33-9.76 2.38-1.27 9.02-11.76 9.29-14.57.34-3.44.77-13.67.45-15.28z"
      />
    </svg>
  );
};
