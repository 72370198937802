import React, { SVGProps } from "react";

export const ToolIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#82aec0"
      d="M12.75 118.55c6.65 6.65 14.73 4.34 19.4-.98 4.77-5.44 7.31-8.6 7.31-8.6 5.95-8.76 30.53-38.04 51.51-59.11 2.92 1.2 6.02 1.83 9.13 1.83 5.54 0 11.09-1.91 15.53-5.74a23.83 23.83 0 0 0 8.3-18.36c-.01-.49-.27-.95-.7-1.19-.43-.24-.95-.24-1.38 0l-14.45 8.34c-6.98-2.41-11.11-9.59-9.69-16.77l14.4-8.31c.43-.25.69-.7.69-1.2s-.26-.95-.69-1.2c-9.35-5.47-21.23-3.93-28.89 3.73a23.915 23.915 0 0 0-6.97 17.61 23.66 23.66 0 0 0 2.92 10.72C55.99 62.43 27.71 87.42 20.35 93c0 0-4.43 3.21-8.66 7.56-4.17 4.29-5.72 11.21 1.06 17.99zm4.87-10.23c0-2.94 2.38-5.33 5.33-5.33s5.33 2.38 5.33 5.33c0 2.94-2.38 5.33-5.33 5.33s-5.33-2.39-5.33-5.33z"
    />
    <path
      fill="#2f7889"
      d="M76 42.47c1.04-1.03 2.1-2.07 3.18-3.15a23.66 23.66 0 0 1-2.92-10.72c-.08-2.6.27-5.17 1.01-7.62.66-1.08 1.7-2.24 2.36-1.54-.27 8.51 2.2 17.38 8.12 23.5 2.94 3.04 6.76 5.23 10.86 6.26 2.02.51 4.12.74 6.21.62 1.23-.07 4.67-1.34 5.36-.4v.04a23.902 23.902 0 0 1-10.08 2.22c-3.11 0-6.22-.63-9.13-1.83-6.79 6.82-12.41 12.96-17.13 18.45 2.36-4.46 9.31-12.68 11.17-15.82.42-.71 1-2.53-.32-4.17-1.87-2.3-5.7-4.44-8.69-5.84z"
    />
    <path
      fill="#b9e4ea"
      d="M47.68 77.75c.54-.56.03-1.5-.73-1.33-1.42.31-3.47 1.12-5.91 3.1-4.78 3.88-17.4 14.36-18.96 16.43s4.7-.03 6.9-.42c1.81-.33 14.42-13.33 18.7-17.78zM91.43 10.93c-2.67 1.91-5.07 4.18-7.57 6.31-.64.55-1.59 1.1-2.25.58-.72-.57-.27-1.72.27-2.46 4.73-6.5 13.56-11.15 22.1-9.3-4.5 1.45-8.55 2.01-12.55 4.87z"
    />
    <path
      fill="#2f7889"
      d="M112.66 33.24c-1.05.63-2.51 1.47-3.34 1.71-1.01.28-3.87-.83-4.7-1.49 3.42-2.23 7.23-4.72 10.65-6.95.39-.25.78-.51 1.23-.65.53-.17 1.11-.15 1.67-.13.78.04 4.59-.09 5.04.58.45.68-1.13 1.34-1.65 1.65-2.97 1.77-5.94 3.52-8.9 5.28zM71.8 70.17l-11.19-12.9c-4.05 3.81-8.06 7.52-11.89 11.03l3.26-.71c1.43-.3 2.91.14 3.95 1.17l5.22 5.85c1.1 1.1 1.28 3.01.86 4.51l-.58 2.77c3.3-3.81 6.79-7.76 10.37-11.72z"
    />
    <path
      fill="#a06841"
      d="m121.39 116.71-6.7 5.79c-2.58 2.23-6.5 1.93-8.71-.65L26.57 26l10.21-7.68 85.12 89.77a6.01 6.01 0 0 1-.51 8.62z"
    />
    <path
      fill="#7d5133"
      d="m33.83 34.76 18.9 22.82c2.95-4.44 4.45-9.76 5.66-16.48L40.14 21.85a16.546 16.546 0 0 0-3.73 4.31c-1.55 2.61-2.36 5.57-2.58 8.6z"
    />
    <path
      fill="#82aec0"
      d="M71.29 4.94c-17.34-.2-23.76 1.34-33.42 9.69-2.9 2.5-5.79 5-8.69 7.51-3.15 2.72-7.34 5.1-6.68 9.8.24 1.72.77 3.46.45 5.16-.31 1.61-2.18 2.41-3.51 1.49-1.25-.86-2.63-1.92-4.17-2.1-1.44-.16-2.96.29-4.05 1.26L4.5 43.72s-.96 3.91 6.56 12.42 12.36 7.9 12.36 7.9l6.32-5.56c1.06-.93 1.61-2.3 1.58-3.71-.03-1.65-.99-2.93-1.57-4.41-.11-.28-.74-1.28.36-2.19.98-.85 3-.56 4.15-.25 1.15.31 2.25.8 3.41 1.1 2.26.59 3.32-.46 4.89-1.81 1.39-1.2 9.76-8.43 12.55-10.85 5.57-4.82-2.92-13.26-2.92-13.26-4-4.53 20.27-15.92 20.27-15.92 1.78-.62 1.24-2.22-1.17-2.24z"
    />
    <path
      fill="#2f7889"
      d="M37.68 49.03c.47.12.88.16 1.26.15v-.19c-.1-1.08-.69-2.06-1.29-2.97A64.622 64.622 0 0 0 23.9 30.96c-.44-.35-.9-.7-1.38-1-.1.61-.12 1.27-.02 1.98.24 1.72.77 3.46.45 5.16-.34 1.76-2.18 2.25-3.59 1.59 3.67 2.37 6.81 5.53 9.1 9.25.31.5.62 1.03.98 1.51.05-.49.27-.96.68-1.27.98-.85 3-.56 4.15-.25 1.16.3 2.25.79 3.41 1.1zM17 51.15c5.27 5.51 8.23 11.22 6.61 12.77-1.61 1.54-7.19-1.67-12.46-7.17S2.89 45.27 4.5 43.72c1.61-1.54 7.23 1.92 12.5 7.43z"
    />
    <path
      fill="#b9e4ea"
      d="M37.51 22.68c4.19-1.78 7.92-5.6 12.8-9.81 1.39-1.2 3.16-2.34 4.97-3.2.68-.32.43-1.34-.32-1.33-2.51.04-4.75.8-6.95 1.76-3.08 1.34-5.8 3.37-8.42 5.47-1.8 1.44-6.02 5-8.68 7.25-.5.42-.11 1.08.54 1.08 1.87.02 2.92.12 6.06-1.22zM11.12 40.16c-1.77 1.99.49 2.53 4.46 5.81 2.8 2.32 5.78.17 5.81-2 .02-1.95-.47-3-3.3-4.78s-5.37-.83-6.97.97z"
    />
  </svg>
);
