export const mediaClues: string[] = [
  "1984",
  "A Clockwork Orange",
  "A Game of Thrones",
  "A Tale of Two Cities",
  "Alice (Alice in Wonderland)",
  "Alien",
  "Animal Farm",
  "Arrested Development",
  "Avatar",
  "Back to the Future",
  "Batman",
  "Black Mirror",
  "Blade Runner",
  "BoJack Horseman",
  "Braveheart",
  "Breaking Bad",
  "Buffy the Vampire Slayer",
  "Buzz Lightyear (Toy Story)",
  "Captain America",
  "Casablanca",
  "Catch-22",
  "Chun-Li (Street Fighter)",
  "Cinderella",
  "City of God",
  "Cloud Strife (Final Fantasy)",
  "Crime and Punishment",
  "Crouching Tiger, Hidden Dragon",
  "Dante (Devil May Cry)",
  "Darth Vader",
  "Dexter",
  "Die Hard",
  "Doctor Who",
  "Don Quixote",
  "Donkey Kong",
  "Dory (Finding Nemo)",
  "Downton Abbey",
  "Dracula",
  "E.T. the Extra-Terrestrial",
  "Ellie (The Last of Us)",
  "Elsa (Frozen)",
  "Fahrenheit 451",
  "Fight Club",
  "Forrest Gump",
  "Frankenstein",
  "Friends",
  "Frodo Baggins",
  "Frozen",
  "Game of Thrones",
  "Gandalf",
  "Geralt of Rivia (The Witcher)",
  "Ghostbusters",
  "Gladiator",
  "Gone with the Wind",
  "Goodfellas",
  "Gordon Freeman (Half-Life)",
  "Great Expectations",
  "Grey's Anatomy",
  "Hannibal Lecter",
  "Harry Potter",
  "Homeland",
  "House of Cards",
  "Inception",
  "Indiana Jones",
  "Iron Man",
  "Jack Sparrow",
  "James Bond",
  "Jane Eyre",
  "Jaws",
  "Jill Valentine (Resident Evil)",
  "Jurassic Park",
  "Katniss Everdeen",
  "King Kong",
  "Kratos (God of War)",
  "Lara Croft (Tomb Raider)",
  "Les Misérables",
  "Link (The Legend of Zelda)",
  "Lolita",
  "Lost",
  "Luke Skywalker",
  "Mad Men",
  "Mario (Super Mario)",
  "Master Chief (Halo)",
  "Mega Man",
  "Mickey Mouse",
  "Moby-Dick",
  "Narcos",
  "Nathan Drake (Uncharted)",
  "Nemo (Finding Nemo)",
  "Pac-Man",
  "Peter Pan",
  "Pikachu (Pokémon)",
  "Pinocchio",
  "Pride and Prejudice",
  "Psycho",
  "Pulp Fiction",
  "Rebecca",
  "Rick and Morty",
  "Rocky",
  "Samus Aran (Metroid)",
  "Saving Private Ryan",
  "Schindler's List",
  "Seinfeld",
  "Sherlock Holmes",
  "Sherlock",
  "Simba (The Lion King)",
  "Singin' in the Rain",
  "Slaughterhouse-Five",
  "Solid Snake (Metal Gear Solid)",
  "Sonic the Hedgehog",
  "Spider-Man",
  "Star Trek",
  "Star Wars",
  "Stranger Things",
  "Superman",
  "The Alchemist",
  "The Avengers",
  "The Big Bang Theory",
  "The Catcher in the Rye",
  "The Chronicles of Narnia",
  "The Color Purple",
  "The Crown",
  "The Da Vinci Code",
  "The Dark Knight",
  "The Diary of Anne Frank",
  "The Girl with the Dragon Tattoo",
  "The Godfather Part II",
  "The Godfather",
  "The Good Place",
  "The Graduate",
  "The Grapes of Wrath",
  "The Great Escape",
  "The Great Gatsby",
  "The Handmaid's Tale",
  "The Hitchhiker's Guide to the Galaxy",
  "The Hobbit",
  "The Hulk",
  "The Hunger Games",
  "The Joker",
  "The Kite Runner",
  "The Lion King",
  "The Little Prince",
  "The Lord of the Rings",
  "The Mandalorian",
  "The Marvelous Mrs. Maisel",
  "The Matrix",
  "The Office",
  "The Picture of Dorian Gray",
  "The Shawshank Redemption",
  "The Shining",
  "The Silence of the Lambs",
  "The Simpsons",
  "The Sopranos",
  "The Terminator",
  "The Twilight Zone",
  "The Walking Dead",
  "The West Wing",
  "The Wire",
  "The Wizard of Oz",
  "The X-Files",
  "Thor",
  "Tinker Bell",
  "Titanic",
  "To Kill a Mockingbird",
  "Toy Story",
  "True Detective",
  "Twin Peaks",
  "War and Peace",
  "Westworld",
  "Winnie the Pooh",
  "Wonder Woman",
  "Woody (Toy Story)",
  "Wuthering Heights",
  "Yoda",
];
