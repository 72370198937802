import React, { SVGProps } from "react";
export const PlantIcon1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#2e7e31"
      d="M49.02 111.67s-4.87 5.89-9.35 7.86c-4.48 1.96-7.54 1.96-8.41 1.26-.86-.71-.86-3.46-.86-3.46s-1.65 3.69-11.16 4.48-10.61-1.26-10.92-2.51c-.31-1.26 1.49-2.67 2.75-4.4 1.26-1.73 4.17-6.52 4.17-6.52s-3.14.47-3.46-1.02c-.31-1.49 1.49-3.46 2.67-5.34 1.18-1.89 2.59-4.09 4.87-5.58s22.11-20.01 22.11-20.01l11.7 7.55-4.11 27.69z"
    />
    <path
      fill="#5b9922"
      d="M25.01 107.71c1.21 1.17 3.77-.71 6.83-2.73s5.19-3.5 4.32-5.03c-.87-1.53-4.37-.93-7.43 1.86-2.64 2.39-5.09 4.59-3.72 5.9z"
    />
    <path
      fill="#2e7e31"
      d="M84.23 109.21s4.41 3.1 8.73 4.88c4.32 1.78 8.34 3.46 9.86 2.41 1.8-1.25 1.12-4.28 1.12-4.28s2.82 2.91 8.17 4.13 10.89 1.69 11.17 1.13c.28-.56-.66-6.95-2.25-10.61s-3.94-6.38-3.94-6.38 3.47.09 3.94-1.6-3.92-8.72-9.33-13.32c-4.47-3.8-17.81-7.06-17.81-7.06l-13.24 5.07 3.58 25.63z"
    />
    <path
      fill="#5a9821"
      d="M100.78 95.69c-.93 1.06.37 2.48 4.1 6.09 3.73 3.6 5.53 5.96 6.58 4.91 1.06-1.06-.06-4.04-4.1-8.07s-5.45-4.21-6.58-2.93z"
    />
    <path
      fill="#ea4b8d"
      d="M94.9 42.63c-.84-.93 12.29-8.72 10.85-16.59-1.43-7.87-5.64-7.3-8.27-10.1s-1.57-5.81-7.8-9.17c-5.56-3-9.01.46-11.14.17-4.78-.65-9.01-3.91-15 .7s-4.08 15.38-3.91 16.94c.17 1.56.44 5.53-.19 5.81-.63.28-3.35-14.18-7.01-16.36C43.97 8.99 42 14.2 42 14.2s-10.36-9.72-19.78-2.88c-8.1 5.88-3.1 16.36-3.24 17.53s-4.53 2.99-3.78 9.44c1 8.58 13.73 10.76 13.67 12.59-.05 1.53-14.99-5.07-20.78 2.84-3.77 5.14-1.02 9.06-1.12 10.94-.11 1.88-5.6 6.21-2.33 15.32 2.62 7.28 7.92 6.83 9.16 8.28s1.69 6.19 7.52 7.95c5.83 1.76 11.26-1.57 14.03-3.97s4.99-4.45 6.04-4.33c1.1.13-7.19 11.46 1.08 21.16 4.49 5.26 7.91 4.14 9.29 4.54s2.23 5.12 12.75 6.25 11.26-5.05 13.95-5.8c2.69-.75 10.06-1.11 12.5-11.54 2.03-8.63-4.85-16.4-3.93-17.12.91-.72 7.56 9.21 19.88 4.88 6.46-2.27 6.8-8.74 6.8-8.74s10.37-4.19 11.18-15.6c.64-8.91-7.26-13.22-7.72-14.34s-.58-5.9-5.65-9.39c-6.46-4.41-15.79 1.35-16.62.42z"
    />
    <path
      fill="#c10375"
      d="M67.54 42.8s7.14-11.08 11.9-8.22c3.58 2.15-4.65 12.33-4.65 12.33s8.79-6.27 10.49-3.59c2.29 3.61-4.64 9.1-4.64 9.1s10.88-3.98 12.7.25c2.09 4.87-13.41 7.16-13.41 7.16s15.39-.39 14.74 5.03c-.54 4.51-15.31 1.59-15.31 1.59s9.53 8.37 6.3 11.44c-3.89 3.7-14.67-6.22-14.67-6.22s-.76 14.26-5.39 14.83c-4.81.59-7.85-14.31-7.85-14.31s-5.95 11.78-9.91 9.05c-3.44-2.38 1.13-10.53 1.13-10.53s-10.58 8.56-13.33 5.02c-2.75-3.54 8.16-11.84 8.16-11.84s-11.61 1.8-12.18-4.04c-.57-5.88 12.04-7.03 12.04-7.03s-5.21-1.32-4.59-4.63c.62-3.31 11.48.49 11.48.49s-5.39-6.84-2.97-9.37c3.89-4.08 13.53 7.1 13.53 7.1s1.35-12.97 5.3-12.05c3.13.72 1.13 8.44 1.13 8.44z"
    />
    <path
      fill="#fdc11e"
      d="M49.75 28.12s4.38 2.8 9.85 10.45c7.56 10.58 9.33 17.54 9.33 17.54s-.52 4.45-4.62 5.31c-4.92 1.03-6.74-2.67-6.74-2.67s-.81-7.8-4.93-15.05c-3.54-6.22-8.59-10.75-8.59-10.75s-2.68 1.82-5.56.72-3.48-4.14-3.48-4.14-4.04.37-5.43-3 .61-6.12.61-6.12-4.9-4.96-.23-8.36c3.71-2.69 7.18 1.85 7.18 1.85s2.86-1.21 5.42.28 2.05 5 2.05 5 3.64.74 4.51 3.37c.92 2.77.63 5.57.63 5.57z"
    />
    <path
      fill="#fee165"
      d="M38.28 24.96c-1.32 1.95-.65 4.44 1.71 5.14s3.53-.57 4.15-2.06-.33-3.4-1.62-4.07-3.12-.67-4.24.99zM35.13 21.59c-1.45-1.02-3.11-.98-4.1.62-.99 1.6-.12 3.64 1.05 4.3 1.17.67 3.11.79 3.97-.56 1.06-1.67.49-3.37-.92-4.36zM31.19 13.43c-2.23 1-1.96 4.08-.35 4.95s4.07.2 4.28-2.12c.17-2-1.44-3.94-3.93-2.83zM38.32 16.05c-1.92 1-2.01 3.65-.92 4.93 1.33 1.55 5.04 1.42 5.2-1.65s-2.23-4.34-4.28-3.28z"
    />
  </svg>
);
