import React, { SVGProps } from "react";

export const MonkeyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#845a54"
      d="M71.45 84.59 62.06 67.6l-23.18 8.87s-1.13 1.45-.89 3.99c.1 1.13.61 4.32.61 4.32l-4.32.66s-6.19 5.16-4.22 13.23c1.5 6.13 3.1 9.39 3.1 9.39l-.84 6.01s13.8 6 27.22 5.63c17.18-.47 23.65-9.57 23.65-9.57L78.5 88.92l-7.05-4.33z"
    />
    <path
      fill="#b5885e"
      d="M31.66 86.57c-1.99 1.87-2.32 4.83-2.16 5.87.38 2.44 4.13 9.15 8.26 8.87 4.13-.28 2.16-8.54.84-11.54s-3.75-6.21-6.94-3.2zM20.49 118.94c-.14 2.05 2.16 1.97 6.48 1.97s14.73-.75 14.73-.75l-.84-5.73s-2.61.18-6.19-1.78c-1.45-.8-1.92-1.83-1.92-1.83s-11.98 4-12.26 8.12z"
    />
    <path
      fill="#ffcd88"
      d="M51.61 80.46s-4.6-.18-8.03 3.81c-2.18 2.53-6.66 14.69-3.38 16.38 3.28 1.69 5.53-4.27 6.76-7.04 1.13-2.53 3.19-5.44 4.97-6.38 1.78-.94 3.28-1.97 3.28-1.97l-3.6-4.8zM43.96 113.31s-1.6 0-3.19.56c-1.6.56-10.51 4.88-10.42 7.23.09 2.35 5.54 2.35 12.29 2.25 6.76-.09 8.54-1.41 9.1-1.97.56-.56 2.72-3.47 1.97-3.85-.74-.37-9.75-4.22-9.75-4.22z"
    />
    <path
      fill="#b5885d"
      d="M69.01 70.23s5.26-4.6 7.32-9.01c2.06-4.41 1.97-8.63 1.97-8.63s9.97-2.63 7.46-14.96c-2.12-10.44-10.56-8.22-10.56-8.22s-5.73-11.92-21.12-15.2-28.87 4.32-34.54 12.01c-6.57 8.92-5.44 20.37-5.44 20.37s-5.07-.47-7.41 4.04C4.26 55.3 5.09 62.92 9.6 66.4c4.5 3.47 11.54 2.53 11.54 2.53s4.88 5.26 10.51 7.6c5.78 2.41 11.26 2.72 17.93 2.06s12.01-4.13 12.01-4.13-.28 1.88.94 4.32c.76 1.51 3.38 4.41 3.38 4.41s-4.27-2.25-7.7-2.86c-3.72-.66-6.1-.42-7.51.42-1.07.64.31 5.73 1.88 6.01 1.6.28 4.22-.47 8.82 1.31 4.6 1.78 10.51 6.01 12.01 6.95 1.5.94 4.55 2.53 4.79 4.6.18 1.59-2.39 3.66-6.9-.94-4.32-4.41-8.49-8.63-13.19-9.2-4.69-.56-7.6.66-9.76 5.35-2.77 6.03-5.07 13.33-5.54 15.2-.47 1.88-1.78 2.91-.75 4.69.72 1.24 8.67 5.87 10.51 5.73 2.35-.19 4.22-4.97 4.79-5.82.56-.84 1.78-2.63 1.78-2.63s4.13 9.76 12.86 8.92 9.95-6.29 9.95-6.29 6.79.34 13.98-1.71c9.64-2.75 16.23-11.56 18.3-25.69 1.5-10.23-.38-25.15-5.16-37.64-1.76-4.58-3.83-10.01-5.16-13.8-3.41-9.66-4.6-16.64.94-18.68 2.82-1.04 4.63.19 5.45 1.8 1.12 2.21 1.31 4.95 4.87 6.36 2.54 1 5.73.19 7.13-2.16 1.76-2.94 1.14-7.24-2.63-12.11-6.48-8.35-17.27-7.56-23.18-3.1-6.32 4.76-9.01 11.1-5.91 22.99 2.91 11.17 9.31 25.39 10.7 32.29 1.88 9.29 3.58 32.13-7.98 36.79-5.35 2.16-9.29 1.31-9.29 1.31s-.94-10.68-4.32-18.4c-3.85-8.81-10.68-12.65-10.68-12.65z"
    />
    <path
      fill="#ffcf8c"
      d="M71.36 35.28c-.16.2.91 3.13 1.31 6.19.45 3.39.59 6.67.88 6.77.56.19 8.88-1.89 7.85-9.59s-9.66-3.84-10.04-3.37zM14.58 51.28c-.74-.56-5.55.79-4.97 6.29.75 7.13 7.79 7.13 8.45 6.66.34-.24-1-3.49-1.88-6.66-.85-3.05-1.23-6.02-1.6-6.29zM41.23 36.35c-4.5 1.5-10.79-5.73-16.24 0-6.67 7.01 1.26 13.31 1.88 18.21.38 3-1.31 5.26.38 10.32 1.41 4.22 9.24 10.5 23.48 7.61 13.33-2.71 16.46-11.15 16.11-16.46-.39-5.92-3.17-6.26-3.73-9.26s2.59-14.82-6.57-17.27c-9.49-2.53-10.46 5.24-15.31 6.85z"
    />
    <path
      fill="#000504"
      d="M56.88 43.58c.56 3.38.21 5.63-2.18 6.19-2.86.67-4.72-1.27-5.28-4.72-.69-4.21.16-6.19 2.35-6.6 2.7-.5 4.41.88 5.11 5.13zM37.88 46.89c.64 3.22.75 6.1-1.83 6.76-2.47.63-4.52-.95-5.42-4.43-1.06-4.08-.71-6.44 1.62-7.11 2.4-.7 4.64-.22 5.63 4.78zM41.26 52.53c.06 1.62 2.81 3.58 4.42 3.33 1.74-.28 3.38-3.72 2.81-4.74-.74-1.32-7.28-.07-7.23 1.41z"
    />
    <path
      fill="#ff6b19"
      d="M34.68 62.87c.28 2.25 5.07 5.65 13.8 3.94 9.01-1.76 10.35-7.25 10.21-8.59-.14-1.34-4.72-2.04-12.53-.7-6.13 1.05-11.71 3.55-11.48 5.35z"
    />
  </svg>
);
