import React, { SVGProps, useId } from "react";

export const WomanIcon = (props: SVGProps<SVGSVGElement>) => {
  const a = useId();
  const b = useId();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      viewBox="0 0 128 128"
      {...props}
    >
      {/* hair back */}
      <path
        fill="#543930"
        d="M64.74 4.12c-53.26 0-52.84 49.25-52.84 50.03 0 21.44 1.11 48.82 5.71 69.85h92.79c4.6-21.04 5.7-48.41 5.7-69.84-.01-.79 1.9-50.04-51.36-50.04z"
      />
      {/* face */}
      <path
        fill="#ffca28"
        d="M64 19.6c-22.34 0-43.03 16.61-43.03 51.84 0 28.29 17.52 45.03 32.77 50.43 3.64 1.29 7.15 1.94 10.26 1.94 3.09 0 6.58-.64 10.2-1.91 15.27-5.37 32.83-22.12 32.83-50.45 0-35.24-20.69-51.85-43.03-51.85z"
      />
      {/* hair front */}
      <path
        fill="#543930"
        d="M18.95 120.91c.6 1.23 1.25 2.24 1.91 3.09h7.12c-.71-9.76-2.38-33.66-2.62-46.37-.07-3.79-1.22-17.83 6.7-19.6C61.1 51.5 77.53 36.1 77.53 36.1c4.27 9.33 17.63 18.43 22.43 21.89 3.94 2.84 3.39 14.54 3.31 19.52L99.64 124h7.64c.68-.85 1.35-1.86 1.96-3.09 3.85-7.82 3.97-66.75 3.97-66.75s1.32-46.2-49.11-46.2-49.31 46.2-49.31 46.2.43 59.07 4.16 66.75z"
      />
      <radialGradient
        id={a}
        cx={64.434}
        cy={46.481}
        r={64.666}
        gradientTransform="matrix(1 0 0 -1.2135 0 137.928)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.794} stopColor="#6d4c41" stopOpacity={0} />
        <stop offset={1} stopColor="#6d4c41" />
      </radialGradient>
      <path
        fill={`url(#${a})`}
        d="M64.74 4.12c-53.26 0-52.84 49.25-52.84 50.03 0 10.08.24 21.46.98 32.89l8.73-.02c-.07-3.79 2.58-27.23 10.5-29.01 29.04-6.53 45.47-21.93 45.47-21.93 4.27 9.33 17.63 18.43 22.43 21.89 3.94 2.84 5.55 24.09 5.47 29.07h9.61l.03-.2c.72-11.36.97-22.68.97-32.69 0-.78 1.91-50.03-51.35-50.03z"
      />
      <path
        fill="#e59600"
        d="M68.95 87.16c-.14-.06-.27-.09-.41-.11h-9.08c-.14.02-.28.05-.41.11-.82.33-1.28 1.19-.89 2.09.39.91 2.2 3.46 5.84 3.46s5.45-2.55 5.84-3.46-.07-1.76-.89-2.09z"
      />
      <g fill="#404040">
        <ellipse cx={42.9} cy={75.23} rx={6.32} ry={6.55} />
        <ellipse cx={85.1} cy={75.23} rx={6.32} ry={6.55} />
      </g>
      {/* eyebrows */}
      <g fill="#6d4c41">
        <path d="M52.29 63.47c-1.2-1.59-3.99-3.91-9.39-3.91s-8.19 2.32-9.39 3.91c-.53.71-.4 1.52-.03 2.01.34.46 1.35.88 2.47.5s3.3-1.51 6.95-1.53c3.65.03 5.84 1.15 6.95 1.53 1.12.38 2.13-.04 2.47-.5.37-.49.5-1.3-.03-2.01zM94.5 63.47c-1.2-1.59-3.99-3.91-9.39-3.91s-8.19 2.32-9.39 3.91c-.53.71-.4 1.52-.03 2.01.34.46 1.35.88 2.47.5s3.3-1.51 6.95-1.53c3.65.03 5.84 1.15 6.95 1.53 1.12.38 2.13-.04 2.47-.5.36-.49.5-1.3-.03-2.01z" />
      </g>
      {/* mouth */}
      <path
        fill="#795548"
        d="M74.8 97.66c-4.09 2.43-17.49 2.43-21.58 0-2.35-1.4-4.75.74-3.77 2.87.96 2.1 8.27 6.96 14.59 6.96s13.54-4.86 14.5-6.96c.98-2.14-1.39-4.27-3.74-2.87z"
      />
      <radialGradient
        id={b}
        cx={64.001}
        cy={51.437}
        r={61.833}
        gradientTransform="matrix(1 0 0 -1.1901 0 137.775)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.794} stopColor="#6d4c41" stopOpacity={0} />
        <stop offset={1} stopColor="#6d4c41" />
      </radialGradient>
      {/* hair glow */}
      <path
        fill={`url(#${b})`}
        d="M110.55 123.91c4.53-21.01 5.62-48.2 5.62-69.52 0-.78 1.92-50.11-51.43-50.11S11.82 53.61 11.82 54.39c0 21.32 1.09 48.51 5.62 69.52h93.11z"
      />
    </svg>
  );
};
