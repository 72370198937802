import React, { SVGProps } from "react";

export const WaterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#4ec1f5"
      d="M63.38 4.07c-4.22.09-5.44 4.69-6.95 8.45s-10.5 24.21-16.32 34.53-13.7 23.84-12.58 43.17c1.13 19.33 16.14 33.76 37.17 33.6 19.01-.14 35.64-11.1 36.32-34.91.56-19.71-10.28-36.74-15.63-47.16-3.18-6.2-13.59-26.95-14.69-29.52-1.97-4.6-2.81-8.26-7.32-8.16z"
    />
    <path
      fill="#b3e2fb"
      d="M47.38 64.42c-2.96.13-10 9.29-10 20.84s5.45 20.08 11.4 18.86c6.9-1.41 5.77-8.45 4.36-14.08-1.21-4.84-3.1-10.56-3.52-15.77-.26-3.37.86-9.99-2.24-9.85z"
    />
  </svg>
);
