import React, { SVGProps } from "react";

export const RainIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#e2ebee"
      d="M107.62 46.29s1.72-32.32-23.89-38.72C54.12.17 48.29 28.49 48.29 28.49s-11.55-2.19-18.74 5.62c-6.03 6.55-5.46 12.96-5.46 12.96s-7.96-1.72-14.21 3.75-5.31 10.61-5.31 10.61l18.58 7.18s93.83.47 94.3.16c.47-.31 6.25-9.37 6.25-9.37s-.94-7.81-6.4-10.93c-5.47-3.12-9.68-2.18-9.68-2.18z"
    />
    <path
      fill="#b9ced2"
      d="M14.25 73.45c4.68.16 35.29.78 53.86.62s46.81.14 50.73-1.71c3.07-1.45 4.9-3.85 5.19-7.21.28-3.21-.34-5.75-.34-5.75s-4.37 3.16-12.65 3.28c-5.15.07-15.61-.78-13.58-2.65.57-.53 8.66-6.66 8.27-12.33-.09-1.34-7.34 5.31-14.21 8.12-6.87 2.81-13.39 4.98-22.47 4.4-7.65-.5-10.97-3.61-10.97-3.61s-3.69 4.33-14.78 5.26c-11.09.94-13.48-3.23-13.48-3.23s-5.37 4.06-14.39 3.94c-9-.14-10.77-3.38-10.77-3.38s-.85 5.77 1.03 9.14c1.92 3.43 5.41 5.01 8.56 5.11z"
    />
    <path
      fill="#4fc4f8"
      d="M69.99 81.57c-1.71-.6-7.81 4.23-10.56 9-2.76 4.79-.96 8.29 1.99 9.73 3.32 1.62 8.1.62 9.46-4.1 1.34-4.67.95-13.98-.89-14.63zM37.66 79.19c-1.55-.55-6.58 4.12-8.84 6.64-3.03 3.39-5.37 9.71.38 12.27 5.79 2.57 9.55-1.64 9.84-7.46.28-5.42-.1-10.99-1.38-11.45zM49.87 101.65c-1.65-.89-8.58 5.26-10.79 8.93-2.2 3.67-2.06 8.38 2.44 10.31s8.47-.54 9.3-6.97c.58-4.56.64-11.41-.95-12.27zM83.38 102.18c-1.19-.67-9.43 5.69-11.28 10.32-3.67 9.19 8.87 13.29 11.72 4.65 1.66-5.04 1.03-14.14-.44-14.97zM103.87 82.37c-1.63-1-8.48 4.64-10.76 8.88-2.88 5.36-.64 9.4 2.5 10.68 3.26 1.32 7.45.05 8.87-4.78 2.05-6.96.97-13.8-.61-14.78z"
    />
    <path
      fill="#aee6fd"
      d="M96.4 91.09c-1.08.13-2.42 1.67-2.63 4.3s1.45 3.96 3.13 3.29c1.58-.63 1.84-2.88 1.84-4.3s-.67-3.5-2.34-3.29zM63 90.26c-1.14-.32-3.26 1.44-3.63 4.29-.29 2.2.56 3.53 2.27 3.66 2.38.18 3.13-2.75 3.07-4.54-.04-1.02-.22-2.99-1.71-3.41zM42.45 109.79c-1.06-.08-2.84 1.7-2.84 4.47s1.44 3.97 3.14 3.6c1.7-.38 2.04-3.63 1.93-4.92-.11-1.29-.66-3.03-2.23-3.15zM30.91 88.44c-1.43-.41-3.26 1.4-3.48 3.44-.23 2.04.72 3.52 2.23 3.75 1.51.23 2.8-1.59 2.95-3.71.16-2.12-.37-3.11-1.7-3.48zM76.4 110.97c-1.32-.11-3.21 1.84-3.16 4.79s1.58 3.45 2.59 3.51c1.45.09 2.46-1.62 2.59-4.3.11-2.25-.4-3.87-2.02-4z"
    />
  </svg>
);
