export const hardClues: string[] = [
  "Artificial Intelligence",
  "Baking a pie",
  "Black Hole",
  "Climate Change",
  "Cryptocurrency",
  "Cubism",
  "Dark Matter",
  "Decorating for a party",
  "Doing a handstand",
  "Doing a karaoke night",
  "Doing a workout",
  "Existentialism",
  "Going on a nature walk",
  "Going to a carnival",
  "Going to a farm",
  "Going to a puppet show",
  "Going to an amusement park",
  "Having a pillow fight",
  "Impressionism",
  "Inflation",
  "Jumping in a puddle",
  "Machu Picchu",
  "Making a fort with blankets",
  "Making a gingerbread house",
  "Making a paper airplane",
  "Making friendship bracelets",
  "Photosynthesis",
  "Picking strawberries",
  "Playing marbles",
  "Playing mini-golf",
  "Playing with a hula hoop",
  "Playing with a yo-yo",
  "Playing with toy cars",
  "Prohibition",
  "Quantum Physics",
  "Riding a Ferris wheel",
  "Schrödinger's Cat",
  "Sistine Chapel Ceiling",
  "Stonehenge",
  "String Theory",
  "Supply and Demand",
  "Surrealism",
  "The Age of Discovery",
  "The Apollo Program",
  "The Aztec Civilization",
  "The Baby Boomers",
  "The Battle of Thermopylae",
  "The Beat Generation",
  "The Big Bang",
  "The Black Death",
  "The Boston Tea Party",
  "The Bronze Age",
  "The Cold War",
  "The Colossus of Rhodes",
  "The Crusades",
  "The Cultural Revolution",
  "The Dead Sea Scrolls",
  "The Declaration of Independence",
  "The Digital Age",
  "The Enlightenment",
  "The Fall of Constantinople",
  "The French and Indian War",
  "The French Revolution",
  "The Gen Z",
  "The Generation X",
  "The Gilded Age",
  "The Gold Rush",
  "The Great Depression",
  "The Great Migration",
  "The Great Wall of China",
  "The Green Revolution",
  "The Hanging Gardens of Babylon",
  "The Harlem Renaissance",
  "The Human Genome",
  "The Hundred Years' War",
  "The Ice Age",
  "The Industrial Revolution",
  "The Internet",
  "The Iron Age",
  "The Jazz Age",
  "The Last Supper",
  "The Lewis and Clark Expedition",
  "The Lighthouse of Alexandria",
  "The Lost Generation",
  "The Magna Carta",
  "The Manhattan Project",
  "The Marshall Plan",
  "The Mausoleum at Halicarnassus",
  "The Middle Ages",
  "The Millennials",
  "The Mona Lisa",
  "The Moon Landing",
  "The New Deal",
  "The Oregon Trail",
  "The Ottoman Empire",
  "The Progressive Era",
  "The Pyramids of Giza",
  "The Renaissance",
  "The Roaring Twenties",
  "The Roman Empire",
  "The Rosetta Stone",
  "The Scientific Revolution",
  "The Silk Road",
  "The Space Race",
  "The Spanish Inquisition",
  "The Statue of Zeus at Olympia",
  "The Stone Age",
  "The Taj Mahal",
  "The Temple of Artemis at Ephesus",
  "The Theory of Relativity",
  "The Trail of Tears",
  "The Trojan War",
  "The Underground Railroad",
  "The Victorian Era",
  "The War of the Roses",
  "Visiting an aquarium",
  "Watching a magic show",
];
