import React, { SVGProps } from "react";
export const TurtleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      fill="#97C83D"
      d="M424.361 289.34c-9.879-3.635-19.323-5.957-27.687-6.891-27.498-26.114-64.645-42.16-105.469-42.16-31.938 0-61.622 9.825-86.199 26.603-36.39-2.702-66.945-30.037-73.157-66.515l-.06-.004c-4.879-26.703-25.674-46.928-50.915-47.312-29.22-.444-74.632 25.704-78.399 58.389-5.409 46.923 36.443 47.594 73.318 47.591 16.777 31.311 44.031 55.88 76.666 69.431-9.313 19.77-14.531 41.833-14.531 65.094 0 20.153 16.332 36.494 36.494 36.494s36.495-16.341 36.495-36.494c0-21.301 8.355-40.675 21.939-55.062 22.584-3.501 33.741-12.18 37.102-22.35a80.061 80.061 0 0 1 21.247-2.876c44.263 0 80.288 36.016 80.288 80.288 0 20.153 16.332 36.494 36.495 36.494s36.495-16.341 36.495-36.494c0-17.453-2.94-34.231-8.338-49.874 33.229-5.325 72.46-21.089 73.57-34.816 1.441-17.829-27.845 1.627-85.354-19.536z"
    />
    <path
      fill="#2C3C47"
      d="M78.805 171.334c7.765 0 14.06 6.295 14.06 14.06v17.284c0 7.765-6.295 14.06-14.06 14.06-7.765 0-14.06-6.295-14.06-14.06v-17.284c-.001-7.765 6.294-14.06 14.06-14.06z"
    />
    <path
      fill="#009D82"
      d="M404.971 241.19c-.216-.447-.419-.882-.644-1.335-23.996-48.506-61.321-71.516-69.135-65.501-6.302 4.852 6.088 6.03 18.682 54.14-12.455-2.016-25.532-3.451-39.071-4.226-6.713-27.849-16.561-50.853-23.599-50.853s-16.886 23.004-23.599 50.853c-13.539.775-26.616 2.21-39.071 4.226 12.594-48.11 24.985-49.288 18.682-54.14-7.814-6.015-45.139 16.996-69.135 65.501-.224.453-.427.889-.644 1.335-42.925 15.101-70.495 38.552-70.495 64.886 0 45.552 82.497 82.48 184.261 82.48s184.261-36.928 184.261-82.48c.002-26.334-27.568-49.785-70.493-64.886z"
    />
    <path
      fill="#047F67"
      d="M292.544 135.026v-.181c-.07 0-.135.018-.205.018-.07 0-.134-.018-.204-.018v.036c-.306 0-.624.056-.93.059-.306-.002-.625-.059-.931-.059v-.036c-.07 0-.134.018-.204.018-.07 0-.205.163-.205.163-70.275 1.536-158.841 65.657-158.841 150.739 0 11.522 14.407 71.957 158.843 72.734v.072c.072 0 .132-.005.204-.005s.131.005.203.005v-.011c.329 0 .605-.025.932-.025.327 0 .602.025.931.025v.011c.072 0 .131-.005.203-.005.072 0 .132.005.204.005v-.072c144.435-.777 158.843-61.212 158.843-72.734-.002-85.082-88.568-149.203-158.843-150.739zm-72.849 136.862c-9.418 59.56-36.174 29.1-43.988 23.085-7.814-6.015-14.88-20.238 2.376-55.119 23.996-48.506 61.321-71.516 69.135-65.501s-13.107 6.364-27.523 97.535zm71.51 48.003c-11.673 0-31.083 4.246-31.083-39.475 0-43.721 19.41-107.001 31.083-107.001 11.673 0 31.083 63.281 31.083 107.001 0 43.721-19.41 39.475-31.083 39.475zm115.498-24.918c-7.814 6.015-34.57 36.475-43.988-23.085-14.416-91.171-35.336-91.52-27.522-97.535s45.139 16.996 69.135 65.501c17.255 34.882 10.189 49.104 2.375 55.119z"
    />
  </svg>
);
