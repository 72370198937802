import React, { SVGProps } from "react";
export const ShipIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#356477"
      d="m83.1 44.23.54-22.63c.02-.8.47-1.49 1.14-1.71 1.38-.45 4.04-1.05 8-.78 2.94.2 4.73.77 5.71 1.21.62.28 1.01.99.99 1.76l-.54 22.66-15.84-.51z"
    />
    <path
      fill="#f44336"
      d="M98.49 20.32c-.98-.44-2.77-1-5.71-1.21-3.96-.27-6.62.33-8 .78-.66.22-1.12.91-1.14 1.71l-.14 5.85c.09-.04.17-.1.26-.13 1.51-.45 4.42-1.04 8.77-.75 3.23.21 5.19.78 6.27 1.23.2.08.37.21.53.36l.14-6.09c.02-.76-.36-1.48-.98-1.75z"
    />
    <path
      fill="#356477"
      d="m51.53 38.53.62-26.57c.02-.76.55-1.41 1.32-1.62 1.59-.43 4.68-1 9.27-.74 3.41.19 5.48.73 6.63 1.15.72.26 1.17.94 1.15 1.67l-.63 26.6-18.36-.49z"
    />
    <path
      fill="#f44336"
      d="M69.37 10.75c-1.14-.42-3.21-.95-6.63-1.15-4.6-.26-7.68.31-9.27.74-.77.21-1.3.86-1.32 1.62l-.16 5.55c.1-.04.19-.1.31-.12 1.75-.43 5.13-.99 10.17-.71 3.75.2 6.02.74 7.27 1.16.23.08.43.2.61.34l.17-5.77c.02-.72-.43-1.4-1.15-1.66z"
    />
    <path
      fill="#ff7555"
      d="M86.01 26.82c1.35-.22 3.11-.37 5.26-.3v-7.47c-2.26-.04-4.01.21-5.26.5v7.27zM55.03 16.9c1.39-.18 3.15-.3 5.26-.28V9.54c-2.2.01-3.96.21-5.26.45v6.91z"
    />
    <path
      fill="#eee"
      d="m54.33 73.61-.42 8.4-34.65-1.57.26-6.83s7.78-11.44 7.78-34.96l32.56-1.94s1.55 3.32.39 13.19c-1.94 16.73-5.92 23.71-5.92 23.71z"
    />
    <path
      fill="#82aec0"
      d="m59.97 44.76-32.9.8c-.1 1.39-.22 2.73-.36 4.01L59.79 49l.18-4.24zM59.5 57.05l-33.99.1c-.3 1.43-.62 2.77-.95 4.01h34.09l.85-4.11zM54.96 68.97l-33.04-.07c-.82 1.92-1.53 3.25-1.96 3.99l32.72.07c.01 0 1.78-2.67 2.28-3.99z"
    />
    <path
      fill="#82aec0"
      d="m52.56 73.66 65.73 7.65 1.12-12.58-11.14-1.4 2.02-22.19-50.3-8.2c.38 24.56-5.65 33.81-7.43 36.03v.69z"
    />
    <path
      fill="#365666"
      d="m109.68 51.75-49.8-7.02s-.2 3.2-.31 4.3l49.75 6.72.36-4zM108.26 67.33l.36-3.95-50.07-6.32c-.27 1.4-.54 2.87-.85 4.08l50.56 6.19zM54.96 68.97c-.91 1.74-1.95 3.32-2.42 4l-.01.15 65.84 7.22.35-3.99-63.76-7.38z"
    />
    <path
      fill="#eee"
      d="m105.82 55.27.46-4-1.98-.28-.46 4.01-4.3-.58.47-4.04-1.98-.28-.47 4.05-4.3-.58.47-4.07-1.98-.28-.47 4.08-4.3-.58.47-4.11-1.98-.28-.48 4.12-4.3-.58.48-4.14-1.98-.28-.48 4.16-4.3-.58.48-4.18-1.98-.28-.48 4.19-4.3-.58.49-4.22-1.98-.28-.49 4.23-6.65-.9s-.08.88-.1 1.42c-.02.54-.1 1.58-.1 1.58l49.75 6.68.27-2.96-3.5-.46zM110.08 47.36l-50.24-7.64.02-3.02 50.5 7.64zM104.79 66.9l.42-3.94-1.98-.25-.42 3.94-4.3-.53.42-3.95-1.98-.25-.42 3.95-4.3-.53.42-3.96-1.98-.25-.42 3.96-4.3-.53.42-3.97-1.98-.25-.42 3.97-4.3-.53.42-3.97-1.98-.25-.42 3.98-4.3-.53.42-3.98-1.98-.25-.42 3.98-4.3-.53.42-3.99-2.02-.24-.43 3.99-7.37-.85s-.2.7-.81 2.85l62.24 7.74.28-3.02-14.63-1.81zM114.05 79.8l.42-3.94-1.98-.23-.43 3.95-4.63-.54.42-3.95-1.98-.23-.43 3.95-4.63-.53.43-3.95-1.99-.23-.43 3.95-4.63-.53.43-3.96-1.99-.23-.43 3.96-4.63-.53.43-3.97-1.99-.23-.43 3.97-4.63-.53.43-3.97-1.99-.23-.43 3.97-4.63-.54.43-3.97-1.99-.23-.43 3.98-4.63-.54.43-3.98-1.99-.23-.43 3.98-4.63-.53.43-3.98-1.99-.24-.43 3.99-6.58-.52-.65 9.79 65.72 6.72.79-9.16z"
    />
    <path
      fill="#356477"
      d="M6.15 78.96s-2.12-.1-1.93 1.99c0 0-2.49 16.6 4.41 28C15.26 119.89 24.43 124 28.52 124h80.46c5.02 0 10.23-3.78 12.03-9.39 1.41-4.41 2.66-20.35 2.92-25.21.04-.78-.55-1.45-1.33-1.51 0 0-72.87-9.04-116.45-8.93z"
    />
    <path
      fill="#f44336"
      d="M123.48 95.94S47.46 90.75 3.86 91.11l-.02-5.64c43.82-.37 119.06 7.83 119.83 7.89l-.19 2.58z"
    />
    <path
      fill="#82aec0"
      d="m19.38 78.33-.07.83c36.27 1.05 83.26 6.37 98.47 8.16l.17-2c-11.02-1.29-38.67-4.35-66.8-6.36-10.82-.77-31.77-.63-31.77-.63z"
    />
  </svg>
);
