import React, { SVGProps, useId } from "react";

export const NightSkyIcon = (props: SVGProps<SVGSVGElement>) => {
  const a = useId();
  const b = useId();
  const c = useId();
  const d = useId();
  const e = useId();
  const f = useId();
  const g = useId();
  const h = useId();
  const i = useId();
  const j = useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      viewBox="0 0 128 128"
      {...props}
    >
      <radialGradient
        id={d}
        cx={132.617}
        cy={28.442}
        r={29.462}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#273e9b" />
        <stop offset={0.861} stopColor="#273e9b" stopOpacity={0} />
      </radialGradient>
      <path
        fill={`url(#${d})`}
        d="M103.84 43.07c1.96-1.59 20.31-7.4 20.31-7.4V16.21s-12.23 9-13.09 9c0 0-8.84 0-9.62 7.35s2.4 10.51 2.4 10.51z"
      />
      <radialGradient
        id={e}
        cx={-2.662}
        cy={116.522}
        r={30.417}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.2} stopColor="#273e9b" />
        <stop offset={1} stopColor="#273e9b" stopOpacity={0} />
      </radialGradient>
      <path
        fill={`url(#${e})`}
        d="m8.85 87.51-5.04 5.07v13.41s5.16-2.57 8.22-4.65 7.96-5.75 8.94-6l9.33-.54s-4.35-10.24-7.73-11.86c-9.42-4.51-13.72 4.57-13.72 4.57z"
      />
      <path
        fill="#0a67a8"
        d="M116.5 31.14c.48 1.18 7.64.06 7.64.06v-9.75s-2.41 1.61-4.47 3.91c-1.95 2.17-3.88 4.04-3.17 5.78z"
      />
      <radialGradient
        id={f}
        cx={79.512}
        cy={30.798}
        r={28.335}
        gradientTransform="matrix(.5482 .8364 -1.4474 .9486 80.503 -64.92)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.208} stopColor="#6d66d7" />
        <stop offset={0.35} stopColor="#6066d1" />
        <stop offset={0.616} stopColor="#3d66c0" />
        <stop offset={0.943} stopColor="#0a67a8" />
      </radialGradient>
      <path
        fill={`url(#${f})`}
        opacity={0.2}
        d="M110.4 33.28c.24.67-6.9 3.96-9.11 4.91-2.22.95-3.96 1.74-4.76 2.54-.79.79-2.22 3.57-3.25 7.21s-1.51 6.5-2.3 7.37c-.79.87-4.68 1.98-5.31 2.38-.63.4-4.12 6.18-5.47 8.08-1.35 1.9-7.45 6.82-9.75 8.4s-10.22 7.29-11.97 4.99c-1.74-2.3 2.77-8.8 3.41-9.59.63-.79 4.6-3.09 4.6-3.09s2.46-12.84 3.33-14.35 3.57-1.98 4.91-2.54c1.35-.55 2.38-.79 2.38-.79s2.77-2.62 4.12-3.41 3.57-.63 3.57-.63 2.06-10.3 3.25-11.89 7.61-1.9 10.38-2.62c2.78-.69 10.46-1.25 11.97 3.03z"
      />
      <radialGradient
        id={g}
        cx={17.341}
        cy={53.929}
        r={39.099}
        gradientTransform="matrix(.5857 .8105 -1.0881 .7863 65.865 -2.53)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.461} stopColor="#9079f1" />
        <stop offset={0.517} stopColor="#8a78ee" />
        <stop offset={0.59} stopColor="#7a76e5" />
        <stop offset={0.67} stopColor="#6073d7" />
        <stop offset={0.758} stopColor="#3b6ec3" />
        <stop offset={0.849} stopColor="#0d67aa" />
        <stop offset={0.854} stopColor="#0a67a8" />
      </radialGradient>
      <path
        opacity={0.3}
        fill={`url(#${g})`}
        d="M13.39 91.38c.98.88 2.38-.24 8.8-1.35 6.42-1.11 17.36-.71 18.62-1.19 1.27-.48 9.91-2.38 11.33-3.96 1.43-1.59 1.27-11.57 1.74-14.74.48-3.17 1.9-12.13 1.59-13.87-.32-1.74-2.46-1.27-3.09.4-.63 1.66-3.8 9.83-6.18 10.86-2.38 1.03-3.88 1.19-4.52 1.74-.63.55-6.42 6.74-7.05 6.82-.63.08-6.58-1.59-7.85-.95-1.27.63-5.86 4.6-6.9 5.79-1.02 1.17-7.99 9.1-6.49 10.45z"
      />
      <radialGradient
        id={i}
        cx={72.689}
        cy={67.142}
        r={19.437}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.189} stopColor="#0a67a8" />
        <stop offset={1} stopColor="#0a67a8" stopOpacity={0.02} />
      </radialGradient>
      <path
        opacity={0.4}
        fill={`url(#${i})`}
        d="M93.28 47.95c-1.03 3.65-1.51 6.5-2.3 7.37-.79.87-4.68 1.98-5.31 2.38-.63.4-4.12 6.18-5.47 8.08-1.35 1.9-7.45 6.82-9.75 8.4s-10.22 7.29-11.97 4.99c-1.74-2.3 2.77-8.8 3.41-9.59.63-.79 4.6-3.09 4.6-3.09s2.46-12.84 3.33-14.35 3.57-1.98 4.91-2.54c1.35-.55 2.38-.79 2.38-.79s2.77-2.62 4.12-3.41 3.57-.63 3.57-.63 9.51-.47 8.48 3.18z"
      />
      <radialGradient
        id={j}
        cx={56.008}
        cy={51.809}
        r={17.383}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.011} stopColor="#0a67a8" />
        <stop offset={1} stopColor="#0a67a8" stopOpacity={0.02} />
      </radialGradient>
      <path
        opacity={0.5}
        fill={`url(#${j})`}
        d="M40.81 88.84c1.27-.48 9.91-2.38 11.33-3.96 1.43-1.59 1.27-11.57 1.74-14.74.48-3.17 1.9-12.13 1.59-13.87-.32-1.74-2.46-1.27-3.09.4-.63 1.66-3.8 9.83-6.18 10.86-2.38 1.03-3.88 1.19-4.52 1.74-.63.55-6.42 6.74-7.05 6.82-.63.08-6.58-1.59-7.85-.95-1.26.62 12.77 14.18 14.03 13.7z"
      />
      <path
        fill="#fff"
        d="M55.19 50.22c-.37.68-.31 1.66.43 2.22.79.6 1.88.35 2.31-.33.41-.65.46-1.7-.53-2.38-.82-.56-1.84-.19-2.21.49zM62.83 53.07c-.3.55-.14 1.15.45 1.45.59.29 1.15-.04 1.34-.42.23-.46-.04-1.26-.53-1.42-.37-.12-.99-.1-1.26.39zM62.97 57.42c-.61-.04-1.2.41-1.24 1.16-.04.75.32 1.34 1.14 1.34.85 0 1.18-.55 1.24-1.04.06-.48-.19-1.4-1.14-1.46zM73.46 61.43c-.25.89.28 1.93 1.47 1.99 1.2.06 1.72-.71 1.72-1.75 0-.83-.67-1.5-1.69-1.47-.83.03-1.32.58-1.5 1.23zM74.8 46.26c.6.51.44 1.28.05 1.65-.39.37-1.1.41-1.48.05-.51-.49-.46-1.21-.12-1.58.43-.47 1.09-.51 1.55-.12zM71.4 77.99c-.52.68-.34 1.65.4 2.08.63.37 1.46.36 1.95-.4s.11-1.76-.4-2.07c-.51-.29-1.42-.31-1.95.39zM59.7 80.99c-.02.49.23.89.77.91.54.02.77-.33.8-.77a.793.793 0 0 0-.8-.84c-.42 0-.75.38-.77.7zM54.79 74.62c-.06.79.4 1.39 1.19 1.36.79-.02 1.29-.27 1.31-1.19.02-.92-.67-1.24-1.24-1.26-.57-.03-1.21.39-1.26 1.09zM56.96 42.16c-.08.87.67 1.29 1.23 1.32.67.03 1.26-.28 1.29-1.21.03-1.01-.62-1.32-1.23-1.32-.48.01-1.21.31-1.29 1.21z"
      />
      <path
        fill="#ffd717"
        d="M62.34 44.65s-1.38-4.11-.85-4.49c.5-.36 4.15 1.81 4.15 1.81s2.95-2.1 3.53-1.69c.54.38-.5 3.84-.5 3.84s2.75 2.05 2.69 2.57c-.08.61-3.57 1.34-3.57 1.34s-1.04 3.57-1.73 3.64c-.5.06-2.26-3.34-2.26-3.34s-4.01.52-4.17.02c-.27-.84 2.71-3.7 2.71-3.7z"
      />
      <path
        fill="#fee262"
        d="M70.71 67.66s.64-2.91 1.16-3.03c.52-.12 2.07 2.47 2.07 2.47s3.47-.44 3.75.16c.24.52-1.95 2.91-1.95 2.91s1.36 3.39.96 3.83c-.34.38-3.34-.92-3.34-.92s-3.09 2.19-3.23 1.92c-.26-.49.29-3.63.29-3.63s-3.14-1.68-2.98-2.24c.15-.56 3.27-1.47 3.27-1.47z"
      />
      <path
        fill="#fff7b3"
        d="M80.74 52.1s-3.14 1.37-3.18 2.06c-.04.69 3.84 1.47 3.84 1.47s.48 3.6 1.25 3.72c.77.12 2.22-3.24 2.22-3.24s3.69.24 3.89-.37c.18-.57-2.45-2.95-2.45-2.95s1.6-3.19 1.11-3.65c-.5-.46-3.59 1.47-3.59 1.47s-2.55-2.78-3.21-2.43c-.52.26.12 3.92.12 3.92zM40.32 52.39s-2.45-3.13-2.87-2.98c-.42.15-.54 4.01-.54 4.01s-3.67 1.18-3.67 1.61c0 .57 3.17 1.87 3.17 1.87s.11 3.86.65 4.09c.51.22 2.83-2.6 2.83-2.6s4.01.8 4.32.5c.31-.31-1.45-3.9-1.45-3.9s1.8-3.13 1.45-3.63c-.34-.5-3.89 1.03-3.89 1.03zM55.1 86.6s.03-4.85.73-5.18 3.18 4.01 3.18 4.01 4.7-1.73 5.03-1.23-2.43 4.79-2.43 4.79 3.14 4.29 2.64 4.74c-.5.46-5.23-.98-5.23-.98s-3.06 3.99-3.81 3.51c-.79-.5-.31-5.24-.31-5.24s-4.99-1.44-4.87-2.14c.12-.7 5.07-2.28 5.07-2.28z"
      />
      <path
        fill="#ffc6b2"
        d="M44.42 34.07s-1.92-4.4-1.44-5.05c.48-.65 4.98 2.14 4.98 2.14s3.23-3.47 3.95-3.06c.79.46-.29 5.03-.29 5.03s4.19 2.69 4.11 3.46c-.07.77-4.96 1.71-4.96 1.71s-1.42 4.96-2 5.03c-.6.08-3.06-4.69-3.06-4.69s-4.76.12-5-.55 3.71-4.02 3.71-4.02zM38.35 78.42c-.05 2.08 1.14 3.85 3.57 3.92 2.68.08 3.62-2.08 3.6-3.9-.02-1.81-1.46-3.55-3.6-3.42s-3.53 1.53-3.57 3.4zM86.74 40c-1.23.02-2.31.73-2.25 2.47.05 1.56 1.36 2.13 2.39 2.1 1.05-.04 2.06-.59 2.11-2.11.06-1.47-.88-2.48-2.25-2.46z"
      />
      <path
        fill="#fff7b3"
        d="M105.31 27.68c-1.87-.12-2.84 1.32-2.88 3.06-.03 1.74 1.07 3.05 3.03 3.03 1.92-.02 2.7-1.71 2.65-3.06-.07-2.05-1.24-2.92-2.8-3.03z"
      />
      <path
        fill="#fee262"
        d="M11.62 62.77s-3.74-2.58-4.12-2.22c-.4.37.29 4.75.29 4.75s-3.09 2.96-2.93 3.47c.13.42 3.95.7 3.95.7s1.78 3.82 2.45 3.89c.67.06 2.17-3.66 2.17-3.66s4.24.22 4.46-.38c.22-.61-2.51-3.67-2.51-3.67s1.08-4.4.54-4.72c-.54-.32-4.3 1.84-4.3 1.84zM26.23 58.54c-.06.86.76 1.56 1.66 1.43.89-.13 1.15-.73 1.18-1.56.03-.83-.83-1.43-1.5-1.34-.67.1-1.27.55-1.34 1.47zM102.74 58.73c-.93-.01-1.74.72-1.66 1.83.08 1.06.89 1.58 1.91 1.54 1.02-.04 1.6-.91 1.6-1.64s-.48-1.71-1.85-1.73zM105.49 51.34c-.06.64.46 1.07 1.14 1.09.81.02 1.14-.47 1.11-1.14-.04-.68-.56-.97-1.09-1.03s-1.08.29-1.16 1.08zM120.77 49.27c0 .48.33 1.07 1.11 1.05.79-.01 1.05-.51 1.1-1.02.05-.51-.29-1.11-1.14-1.13s-1.07.62-1.07 1.1zM104.37 16.84c-.9.01-1.98.59-2 2.12-.02 1.53 1.2 2.18 2.2 2.16s2.12-.67 2.12-2.14c-.01-1.4-1.03-2.16-2.32-2.14zM95.24 10.17c-.88.03-1.49.71-1.49 1.53s.61 1.48 1.56 1.44c1.02-.04 1.39-.8 1.42-1.44.04-.77-.59-1.56-1.49-1.53z"
      />
      <path
        fill="#fff7b3"
        d="m36.43 85.82-2.2 2.54 2.24 2.83 2.62-2.74zM46.03 98.52c.47.53.41 1.45-.26 1.92-.46.32-1.19.29-1.66-.21-.43-.45-.47-1.19.02-1.73.5-.53 1.39-.56 1.9.02zM49.07 96.91c-.42.4-.34.98-.08 1.35.23.33 1.02.53 1.45.06.21-.24.37-1.02-.02-1.39a1 1 0 0 0-1.35-.02zM26.79 67.13c-.76-.02-.98.62-.96 1.03.02.41.35.87.91.87s.93-.5.91-1c-.02-.49-.28-.88-.86-.9zM8.02 76.73c-1.12.08-1.43.75-1.4 1.65.03.84.57 1.4 1.61 1.43.86.03 1.47-.64 1.47-1.61.01-.97-.67-1.54-1.68-1.47zM27.53 38.15c-.8-.02-1.24.62-1.28 1.21-.05.75.55 1.19 1.21 1.21.78.02 1.09-.62 1.12-1.12s-.21-1.27-1.05-1.3zM54.83 16.09c0 .66.36 1.04.98 1.06.54.02.96-.44.96-1s-.42-.98-.96-.98-.98.34-.98.92z"
      />
      <path
        fill="#fee066"
        d="M45.47 21.11c-.61-.04-1.1.39-1.12 1.05-.02.66.37 1.05 1.05 1.05s1.08-.32 1.1-.88c.02-.71-.35-1.17-1.03-1.22zM48 15.38c-.73 0-1.06.41-1.11 1.04-.05.63.46 1.15 1.01 1.17.67.03 1.19-.41 1.14-1.17-.05-.67-.47-1.04-1.04-1.04z"
      />
      <path
        fill="#fff"
        d="M17.85 74.61c-.2.71.22 1.54 1.17 1.59.95.05 1.37-.56 1.37-1.39 0-.66-.54-1.2-1.34-1.17-.66.02-1.05.46-1.2.97zM30.17 84.43c-.19.65.2 1.42 1.08 1.46.88.04 1.26-.52 1.26-1.28 0-.61-.49-1.1-1.24-1.08-.61.02-.97.43-1.1.9zM41.7 91.33c-.15.51.16 1.11.84 1.14s.98-.4.98-1c0-.47-.39-.86-.97-.84-.47.01-.75.33-.85.7zM8.31 94.83c-.19.66.2 1.43 1.09 1.47.88.05 1.27-.52 1.27-1.29 0-.61-.5-1.11-1.25-1.09-.61.02-.97.43-1.11.91zM81.79 32.91c.48.41.35 1.02.04 1.31s-.88.33-1.18.04c-.41-.39-.37-.96-.09-1.26.34-.37.86-.41 1.23-.09zM97.28 25.67c.77.65.57 1.64.06 2.11-.51.48-1.42.53-1.9.06-.65-.62-.6-1.55-.15-2.02.56-.6 1.4-.66 1.99-.15zM91.03 16.88c.68.58.5 1.44.05 1.86s-1.25.46-1.68.05c-.58-.55-.52-1.36-.13-1.78.5-.53 1.24-.58 1.76-.13zM117.3 17.31c.6.51.44 1.28.05 1.65-.4.37-1.11.41-1.49.05-.51-.49-.47-1.21-.12-1.58.44-.47 1.1-.51 1.56-.12zM121 14.02c.39.33.29.83.03 1.08-.26.24-.72.27-.97.03-.33-.32-.3-.79-.08-1.03.29-.31.72-.34 1.02-.08zM112.96 45.06c.64.55.47 1.37.05 1.76s-1.18.44-1.59.05c-.55-.52-.5-1.29-.12-1.69.47-.49 1.17-.54 1.66-.12zM95.49 52.17c.46.39.34.97.04 1.26-.3.28-.84.31-1.13.04-.39-.37-.35-.92-.09-1.2.33-.37.83-.4 1.18-.1z"
      />

      {/* Moon */}
      <g transform="translate(58, 68) scale(0.35) rotate(-15)">
        <path
          fill="#ffb803"
          d="M99.32 28.84 93.51 9.5l-1.98-2.66s-.15-.18-.17-.24c-.06-.25-.13-.66.11-.97.18-.23.49-.29.85-.26.14.01.48.14 1.02.44 5.31 3 28.21 21.75 30.14 52.02 1.09 17.1-5.15 35.4-18.27 48.16-12.46 12.11-31.28 19.65-51.98 16.7-30.94-4.41-44.22-23.21-47.69-29.42-.61-1.1-.91-1.8-.97-1.97-.82-2.15-.6-3.24.17-3.49.2-.06.65.02.9.3 1 1.12 2.82 1.85 2.82 1.85l12.88 10.43 63.89 4.84 22.65-49.38-8.56-27.01z"
        />
        <path
          fill="#ffca29"
          d="M73.65 87.67c15.97-9.9 23.77-26.72 24.39-42.28.9-22.79-6.68-38.8-6.68-38.8s14.01 11.5 21.68 28.08 9 43.87-7.78 63.4c-18.45 21.45-43.18 22.72-58.73 18.7-32.27-8.35-41.09-28.83-41.09-28.83s17.78 9.95 37.77 8.9c14.88-.78 22.54-4.27 30.44-9.17z"
        />
      </g>
    </svg>
  );
};
