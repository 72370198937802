import React, { SVGProps } from "react";

export const RoadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#a1d1da"
      d="m32.17 68.27 16.3-2.68s-6.18-6.77-14.44-8.56-11.83-1.49-11.83-1.49-6.1-3.87-10.27-5.14-8.05-.77-8.05-.77l-.04 17.4 28.33 1.24zM94.56 69.98l-14.6-4.78s9.33-6.72 14.42-8.08c5.37-1.43 11.29-.87 11.29-.87s4.43-3.89 9.55-5.89 8.97-2.07 8.97-2.07l-.01 17.54s-5.07 5.91-5.17 5.77c-.08-.15-24.45-1.62-24.45-1.62z"
    />
    <path
      fill="#518e2c"
      d="M99.16 65s2.6-3.99 5.61-4.23c3.97-.31 5.35.7 5.35.7s1.9-1.39 3.66-1.34c2.53.07 4.01 2.53 4.01 2.53s1.2-3.49 2.6-3.45c2.39.07 2.74 6.06 2.74 6.06L115 68.38 99.16 65zM4.75 65.86s2.14-6.42 3.62-6.35c1.48.07 2.6 2.89 2.6 2.89s1.42-7.31 3.6-7.31c2.04 0 3.36 10.2 3.36 10.2l2.96 1.27 3.44-.85s1.62-4.36 2.75-4.29c1.06.07 1.76 2.25 1.76 2.25s.77-4.15 2.39-4.15c1.83 0 2.96 5.98 2.96 5.98l-15.83 5.4-13.61-5.04z"
    />
    <path
      fill="#7db240"
      d="m124.2 64.68.01 51.99c0 4.15-3.37 7.52-7.52 7.52H11.38c-4.15 0-7.52-3.37-7.52-7.52l-.03-51.28 120.37-.71z"
    />
    <path
      fill="#5e6367"
      d="M48.49 65.14s6.33-.14 16.83-.15c10.19-.01 15.67-.05 15.67-.05l33.78 59.26H12.35l36.14-59.06z"
    />
    <path
      fill="#fff"
      d="m18.41 124.2 32.05-59.1 1.05-.03h1.23l-30.52 59.14-1.8.03zM79.49 64.94l-1.32-.02-1.48.03 29.45 59.25h3.16zM45.63 120.07l4.34.1 2.94-13.57-4.04-.05zM75.07 106.9l3.92-.07 2.95 13.29h-4.99zM74.11 101.3l3.89-.1-2.45-10.83H72.3zM56.26 90.13h-3.35l-2.59 11.23h3.59zM55.91 77.76l2.89.05-1.54 7.58-3.35.05zM57.86 70.07c-.1.25-1.15 4.49-1.15 4.49h2.84l.75-4.49h-2.44zM58.5 67.83h2.4l.48-2.83s-.88-.01-1.19-.01c-.31 0-1.02.02-1.02.02l-.67 2.82zM68.83 67.82h2.65l-.58-2.85-1.22-.02-1.18.03zM69.47 70.33l2.33-.05.93 4.33-2.74.05zM70.59 77.82l2.7-.09 1.53 7.9-3.35.05z"
    />
    <path
      fill="#9a9a9a"
      d="M17.47 20.21v60.02h5.71V19.12zM104.49 82.68l.4-62.85 5.73.4-.3 62.45z"
    />
    <path
      fill="#c8c8c8"
      d="M113.13 21.36H13.98c-1.1 0-2-.9-2-2s.9-2 2-2h99.14c1.1 0 2 .9 2 2s-.89 2-1.99 2zM14.1 40.92a2 2 0 1 1 0-4l99.38-.12a2 2 0 1 1 0 4l-99.38.12z"
    />
    <path
      fill="#c8c8c8"
      d="m87.579 36.263 18.144-18.144 2.475 2.474-18.144 18.145zM20.286 21.157l2.475-2.475 18.11 18.109-2.476 2.474z"
    />
    <path
      fill="#dfe1e0"
      d="M90.79 48.61h-53.9a3.64 3.64 0 0 1-3.64-3.64V11.84a3.64 3.64 0 0 1 3.64-3.64h53.9a3.64 3.64 0 0 1 3.64 3.64v33.13a3.64 3.64 0 0 1-3.64 3.64z"
    />
    <path fill="#1b9372" d="m36.39 45.46.29-34.59 54.51.19v34.31z" />
    <path
      fill="#85d59e"
      d="m69.81 25.49.16-8.6h14.87v8.68s-14.95-.16-15.03-.08zM45.11 20.98c0 3.54 2.81 5.79 5.95 5.79 3.13 0 5.71-2.25 5.71-5.87s-3.29-5.87-5.71-5.87c-3.14 0-5.95 2.17-5.95 5.95z"
    />
    <path
      fill="#fefffe"
      d="M48.62 29.18h5.06v5.22s5.95-2.17 6.43-1.61-7.8 9.24-8.76 9.24-9.81-8.52-9.48-9.16c.32-.64 6.75 1.21 6.75 1.21v-4.9zM75.15 29.5h5.06v5.22s5.95-2.17 6.43-1.61c.48.56-7.8 9.24-8.76 9.24s-9.81-8.52-9.48-9.16c.32-.64 6.75 1.21 6.75 1.21v-4.9z"
    />
  </svg>
);
