import React, { SVGProps } from "react";

export const PlantIcon3 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="#855b51"
      d="M56.39 103.2s-.28 5-1.06 8.02c-.77 3.03-3.38 5.07-2.53 8.59.56 2.32 3.59 4.01 9.64 4.01 6.05 0 10.16-1.62 10.28-4.72.14-3.59-2.04-4.86-2.39-8.8-.35-3.94-.21-7.6-.21-7.6l-13.73.5z"
    />
    <path
      fill="#027f1c"
      d="M21.15 87.5s-4.6.94-6.29 1.5-3.19.94-3.57 2.53-.75 5.63 5.35 8.35c6.1 2.72 15.86 1.78 15.86 1.78s-1.03 4.04 2.35 5.91c3.38 1.88 9.95 1.41 14.45-.09s5.91-2.44 5.91-2.44 2.25 3.94 9.95 3.85c7.7-.09 9.85-2.91 9.85-2.91s7.98 4.6 14.73 3.19 4.69-7.79 4.69-7.79 8.45 1.69 15.49-1.22c7.04-2.91 7.6-6.57 7.41-7.7-.19-1.13-1.64-3.24-3.99-3.05s-7.74-1.74-9.9-3.05c-2.16-1.31-6.95-3.66-7.79-4.04-.84-.38-2.49-1.6-2.21-2.63.28-1.03.7-1.31 2.58-1.13 1.88.19 7.13.19 9.57-1.69 2.44-1.88 3.94-4.5 3.66-6.01-.28-1.5-3-2.72-3-2.72s-14.08-6.48-15.2-6.95c-1.13-.47-3.84-1.88-3.75-3.1.09-1.31 8.82-.38 9.67-5.73s-1.6-5.35-3.57-6.48-7.7-2.82-7.7-2.82-4.69-3-5.54-3.57c-.84-.56-2.25-1.5-2.16-2.25.09-.75 4.86.73 9.01-.56 3-.94 3.38-2.96 2.77-4.74-.3-.87-5.68-3.33-5.68-3.33L55.31 24.9s-19.05 6.38-19.71 6.66c-.66.28-1.03 1.5 0 2.82 1.03 1.31 2.72 3.28 6.38 3.19 3.66-.09 6.01-1.69 6.66-.66.66 1.03-.96 2.64-2.06 3.28l-6.1 3.57s-10.61 4.5-11.17 7.23.56 4.07 2.25 5.26c1.41.99 4.08 1.36 5.82 1.31 1.22-.03 1.99-.37 2.3.75.42 1.55-2.21 2.91-4.65 3.75-2.44.84-7.23 3-7.23 3s-7.88 1.88-8.35 2.72c-.47.84-1.31 4.97 1.97 7.88 3.28 2.91 9.29 3.66 9.29 3.66s-.75 2.35-2.25 3.66a25.43 25.43 0 0 1-7.31 4.52z"
    />
    <path
      fill="#02ab47"
      d="M62.44 4.35c2.35 0 2.53 4.04 7.13 9.67 4.98 6.1 10.63 11.39 12.93 13.23 2.51 2.02 6.03 3.45 6.19 3.87.16.42-3.64 1.85-8.99 2.14-5.35.28-10.42-1.69-10.42-1.69s3.85 8.54 1.78 10.51c-1.67 1.59-7.51 1.41-11.73-4.79s-4.32-8.17-4.32-8.17-6.66 4.04-12.58 4.13-6.85-1.2-6.85-1.69 6.66-3.47 9.29-5.44S53.32 17.7 57 12.42c2.82-4.03 3.57-8.07 5.44-8.07z"
    />
    <path
      fill="#02ab47"
      d="M57 39.08s-2.44 2.53-6.29 2.82-5.91-.66-5.91-.66-4.74 1.92-9.01 4.41c-3.34 1.95-6.41 4.46-6.34 4.88.07.42.99 1.97 7.18 1.97s6.76-1.58 9.85-1.5c3.94.09 6.19 7.02 12.95 7.13 5.63.09 4.79-4.04 7.88-4.41 3.1-.38 4.51 2.09 8.87 2.21 12.01.33 21.49-5.07 20.13-8.31-.45-1.08-3.55-2.59-8.07-4.18-4.27-1.5-5.87-2.39-5.87-2.39s-.7 1.5-2.86 2.16c-2.02.61-5.54-.75-5.54-.75s0 2.91-3.28 3.47c-4.41.75-8.35-1.22-10.14-2.82S57 39.08 57 39.08zM75.58 78.68c-3.07 1.21-6.29 8.92-12.86 7.23-6.57-1.69-7.41-12.58-7.41-12.58s-15.17 13.2-19.71 9.01c-3.71-3.43 3.1-8.78 3.1-8.78s-7.04 2.21-13.14-.42-6.22-4.72-6.1-5.35c.12-.63 4.47-2.15 8.07-3.38 2.96-1.01 6.41-1.97 6.41-1.97s3.54 1.41 2.7 3.19c-.84 1.78-4.22 3-2.91 4.32 1.31 1.31 4.88-1.13 6.1-1.97s4.69-2.62 6.95-2.72c4.97-.23 6.95 3.57 11.64 2.72s8.14-4.6 13.12-5.26c4.97-.66 8.07 1.69 12.95 1.13 4.88-.56 6.59-2.63 6.59-2.63s4.97 1.88 9.2 3.66c4.22 1.78 7.79 4.32 7.79 4.32s-3 3-8.35 3.66c-5.35.66-8.17-2.06-9.2-2.63s-4.22-3.28-5.07-2.25c-.84 1.03 3.47 3.47 5.35 4.88s5.59 4.43.09 7.41c-2.77 1.5-6.29 1.08-8.17.66-5.17-1.15-5.06-3.06-7.14-2.25z"
    />
    <path
      fill="#02ab47"
      d="M75.87 83.94s-5.54 6.76-9.57 8.82-7.79 2.11-7.79 2.11-2.11 2.16-1.69 5.26c.3 2.22 2.91 4.36 8.63 3.99s7.13-1.69 7.32-3.38c.19-1.69-.99-3.28-1.36-4.04-.38-.75-1.92-2.53-.89-3.19 1.03-.66 2.06.38 3.38 2.35 1.31 1.97 7.41 12.48 15.39 9.1 7.89-3.34-1.78-10.89.19-12.11 1.97-1.22 6.95 3.1 14.64 2.63 8.36-.51 10.23-4.71 10.23-5.96 0-.47-3.43-.47-6.29-1.13-2.17-.5-5.3-2.44-5.3-2.44s-6.9 2.86-13.37 2.02-13.52-4.03-13.52-4.03zM50.81 81.4s.38 6.38 1.6 8.63c1.22 2.25 3.94 4.5 3.94 4.5s-.61 2.96-3.66 5.26c-3.99 3.01-11.17 5.16-15.39 4.04-4.22-1.13-3.28-4.04-2.91-5.16.38-1.13 4.5-5.73 5.54-7.23 1.03-1.5 2.35-3.47 1.13-3.75-1.22-.28-3.19 2.72-4.6 4.41-1.41 1.69-5.26 5.35-12.48 4.32S11.2 91.3 11.86 90.46c.66-.84 5.73-2.58 8.54-3.43 2.82-.84 8.07-4.04 8.07-4.04s-.47 2.72 1.97 3.66c3.06 1.18 7.32.84 12.76-1.31 4.12-1.63 7.61-3.94 7.61-3.94z"
    />
    <path
      fill="#028b2b"
      d="M24.08 88.84c-1.73 1.49-4.22 2.11-3.17 3.66 1.06 1.55 3.1-.21 4.79-1.55s3.59-2.67 2.6-3.87c-.98-1.2-2.67.42-4.22 1.76zM43.44 95.04c1.84 1.45 3.1-2.6 3.73-4.15.63-1.55 2.08-3.81.84-4.58-1.69-1.06-2.39 1.06-3.38 3.52s-2.53 4.15-1.19 5.21zM49.14 67.44c-1.01-.76-1.76-.07-3.17 1.97s-2.25 3.52-1.13 4.29c1.13.77 3.03-1.97 3.73-3.03s1.42-2.59.57-3.23zM63.17 69.23c-1.81.42-.86 2.22-.49 3.94.42 1.97 1.06 5.07 1.34 5.91.35 1.06.77 2.6 2.11 2.25 1.34-.35.86-2.29.7-3.17-.35-2.04-1.13-5.21-1.34-6.19-.21-.98-.49-3.17-2.32-2.74zM77.79 88.7c-1.12 1.06.28 2.53 1.9 4.36s2.67 3.8 4.01 2.96c1.34-.84-.14-2.67-1.48-4.15-1.33-1.48-3.09-4.44-4.43-3.17zM75.84 67.35c-1.2 1.06.35 2.53 1.27 3.94s2.82 3.52 3.38 4.08 1.43 1.71 2.39 1.13c1.22-.74.07-2.25-.42-2.82s-2.11-2.82-3.03-3.8-2.09-3.86-3.59-2.53zM76.1 45.62c-.98 1.38 1.06 2.82 3.03 3.94 1.97 1.13 4.04 2.36 4.79 1.13.84-1.41-1.2-2.53-2.89-3.52-1.69-.99-3.87-3.03-4.93-1.55zM59.63 47.73c-2.11-.14-1.83 1.69-1.76 3.66.07 1.97.42 3.1 1.76 3.1s1.34-2.04 1.34-3.1c0-1.05 0-3.57-1.34-3.66zM48.6 47.94c.94 1.52 3.1-.63 4.01-1.41.92-.77 2.25-1.9 1.34-3.03-.92-1.13-2.82.49-3.73 1.34-.91.85-2.53 1.63-1.62 3.1zM62.06 26.9c.57 3.96 1.36 5.63 2.87 5.13 1.35-.44.42-2.89.1-5.16-.31-2.28-.36-4.26-2.03-4.01-1.87.28-1.28 1.66-.94 4.04zM70.05 25.5c1.26 1.93 2.55 3.01 3.87 2.17 1.01-.64-.39-2.49-1.24-3.61-.58-.76-2.24-3.93-3.79-2.57-1.05.92-.15 2 1.16 4.01zM56.6 20.99c.73.68 0 2.04-1.06 3.17s-2.53 3.17-3.66 2.11.84-2.82 1.55-3.66c.71-.85 2.05-2.68 3.17-1.62z"
    />
  </svg>
);
